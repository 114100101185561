import React from "react";
import { motion } from "framer-motion";
import { Calendar } from "lucide-react";
import { constants } from "../../constants";

const BlogList = ({ blogs, onBlogClick }) => {
  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.1,
      },
    },
  };

  const itemVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.5,
      },
    },
  };

  return (
    <motion.div
      variants={containerVariants}
      initial="hidden"
      animate="visible"
      className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6"
    >
      {blogs.map((blog) => (
        <motion.article
          key={blog.id}
          variants={itemVariants}
          whileHover={{ scale: 1.02 }}
          className="bg-white rounded-lg shadow-md overflow-hidden cursor-pointer"
          onClick={() => onBlogClick(blog)}
        >
          <img
            src={`${constants.serverURL}${blog.imgUrl}`}
            alt={blog.title}
            className="w-full h-48 object-cover"
          />
          <div className="p-4">
            <h2 className="text-xl font-bold text-gray-800 mb-2">
              {blog.title}
            </h2>
            <div />
            <p
              className="text-gray-600 mb-4 line-clamp-3"
              dangerouslySetInnerHTML={{ __html: blog.content }}
            ></p>
            {blog.actionURL && (
              <button
                onClick={(e) => {
                  e.stopPropagation();
                  window.open(blog.actionURL, "_blank");
                }}
                className="w-full my-2 mb-4 bg-primary-brown text-white px-6 py-3 rounded-lg font-medium hover:bg-primary-accent transition-colors focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2"
              >
                {blog.actionName || "Read more"}
              </button>
            )}
            <div className="flex items-center text-sm text-gray-500">
              <Calendar size={16} className="mr-1" />
              <span>{new Date(blog.createdAt).toLocaleDateString()}</span>
            </div>
          </div>
        </motion.article>
      ))}
    </motion.div>
  );
};

export default BlogList;
