import React from "react";

const Modal = ({ onClose, children }) => {
  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50 animate-fade-in">
      <div className="absolute inset-0 bg-transparent" onClick={onClose}></div>
      <div className="relative bg-white p-5 rounded-lg max-w-md w-full text-center transform animate-slide-up">
        <button
          className="absolute top-2.5 right-2.5 text-xl text-red-500 hover:text-orange-400 transition-colors"
          onClick={onClose}
        >
          X
        </button>
        {children}
      </div>
    </div>
  );
};

export default Modal;
