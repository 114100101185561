import React, { useState } from "react";
import { ChevronDown, ChevronUp } from "lucide-react";

const FaqSection = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const faqs = [
    {
      question: "What is the cost of study?",
      answer:
        "The cost varies depending on the program and institution. On average, study costs range from $15,000 to $26,500 per year.",
    },
    {
      question: "What is the minimum IELTS score required?",
      answer: "The minimum IELTS score required is 6.0 for most programs.",
    },
    {
      question: "Can I work while studying?",
      answer:
        "International students in Canada can work up to 20 hours per week during academic sessions and full-time during scheduled breaks.",
    },
    {
      question: "How to apply for a study permit?",
      answer:
        "Apply online or by paper with an acceptance letter, proof of financial support, and required documents from a designated learning institution.",
    },
  ];

  return (
    <div className="max-w-2xl mx-auto p-6">
      <h2 className="text-3xl font-bold text-center mb-8">
        Frequently Asked Questions
      </h2>
      <div className="space-y-4">
        {faqs.map((faq, index) => (
          <div key={index} className="border rounded-lg overflow-hidden">
            <div
              onClick={() =>
                setActiveIndex(activeIndex === index ? null : index)
              }
              className="flex justify-between items-center p-4 cursor-pointer hover:bg-gray-50 transition-colors"
            >
              <h3 className="font-semibold">{faq.question}</h3>
              {activeIndex === index ? <ChevronUp /> : <ChevronDown />}
            </div>
            {activeIndex === index && (
              <div className="p-4 bg-gray-50 text-gray-700">{faq.answer}</div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default FaqSection;
