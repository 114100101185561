import React, { useState, useRef } from "react";
import { motion } from "framer-motion";
import { X, Upload, Trash2 } from "lucide-react";
import axios from "axios";
import { constants } from "../../constants";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "./Editor.css";

const AddBlogForm = ({ addBlog, cancel }) => {
  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");
  const [actionName, setActionName] = useState("");
  const [actionURL, setActionURL] = useState("");
  const [image, setImage] = useState(null);
  const [previewImage, setPreviewImage] = useState(null);
  const [error, setError] = useState(null);
  const fileInputRef = useRef(null);
  const modules = {
    toolbar: [
      [{ font: [] }, { size: [] }], // Font and size
      [{ header: [1, 2, 3, 4, 5, 6, false] }], // Headers
      ["bold", "italic", "underline", "strike"], // Text formatting
      [{ color: [] }, { background: [] }], // Text color & background
      [{ script: "sub" }, { script: "super" }], // Subscript & Superscript
      [{ list: "ordered" }, { list: "bullet" }], // Lists
      [{ indent: "-1" }, { indent: "+1" }], // Indent
      [{ align: [] }], // Text align
      ["blockquote", "code-block"], // Blockquotes & Code blocks
      // ["link"], // Media
      ["clean"], // Remove formatting
    ],
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setImage(file);
      setPreviewImage(URL.createObjectURL(file));
    }
  };

  const handleRemoveImage = () => {
    setImage(null);
    setPreviewImage(null);
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null);

    // Validate image is uploaded
    if (!image) {
      setError("Please upload a cover image");
      return;
    }

    // Create FormData for multipart upload
    const formData = new FormData();
    formData.append("title", title);
    formData.append("content", content);
    formData.append("image", image);

    // Only append these if they have values
    if (actionName) formData.append("actionName", actionName);
    if (actionURL) formData.append("actionURL", actionURL);

    try {
      const token = localStorage.getItem("adminToken");
      const response = await axios.post(
        `${constants.serverURL}/api/blogs/addBlog`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );

      // Call the addBlog function passed from parent
      addBlog(response.data);
    } catch (err) {
      setError(err.response?.data?.message || "Failed to add blog");
      console.error("Add blog error:", err);
    }
  };

  return (
    <motion.div
      initial={{ opacity: 0, y: 50 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: 50 }}
      className="bg-white rounded-lg shadow-md"
    >
      <div className="p-6">
        <div className="flex justify-between items-center mb-6">
          <h1 className="text-2xl font-bold text-gray-800">
            Create New Blog Update
          </h1>
          <motion.button
            whileTap={{ scale: 0.95 }}
            onClick={cancel}
            className="text-gray-600 hover:text-gray-900"
          >
            <X size={24} />
          </motion.button>
        </div>

        <form onSubmit={handleSubmit} className="space-y-6">
          {/* Image Upload Section */}
          <div className="mb-6">
            <label className="block text-gray-700 text-sm font-bold mb-2">
              Upload Cover Image
            </label>
            <div className="flex items-center space-x-4">
              <input
                type="file"
                ref={fileInputRef}
                onChange={handleImageChange}
                accept="image/*"
                className="hidden"
                id="image-upload"
              />
              <label
                htmlFor="image-upload"
                className="flex items-center px-4 py-2 bg-blue-500 text-white rounded-md cursor-pointer hover:bg-blue-600 transition"
              >
                <Upload size={20} className="mr-2" />
                Choose Image
              </label>
              {previewImage && (
                <div className="relative">
                  <img
                    src={previewImage}
                    alt="Preview"
                    className="w-32 h-32 object-cover rounded-md"
                  />
                  <button
                    type="button"
                    onClick={handleRemoveImage}
                    className="absolute -top-2 -right-2 bg-red-500 text-white rounded-full p-1"
                  >
                    <Trash2 size={16} />
                  </button>
                </div>
              )}
            </div>
          </div>

          {/* Title Input */}
          <div>
            <label
              htmlFor="title"
              className="block text-gray-700 text-sm font-bold mb-2"
            >
              Title
            </label>
            <input
              type="text"
              id="title"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              required
              className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
              placeholder="Enter blog title"
            />
          </div>

          {/* Content Textarea */}
          <div>
            <label
              htmlFor="content"
              className="block text-gray-700 text-sm font-bold mb-2"
            >
              Content
            </label>
            {/* <textarea
              id="content"
              value={content}
              onChange={(e) => setContent(e.target.value)}
              required
              rows={6}
              className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
              placeholder="Write your blog content"
            /> */}
            <ReactQuill
              theme="snow"
              value={content}
              onChange={setContent}
              modules={modules}
            />
          </div>

          {/* Optional Action Section */}
          <div className="grid md:grid-cols-2 gap-4">
            <div>
              <label
                htmlFor="actionName"
                className="block text-gray-700 text-sm font-bold mb-2"
              >
                Action Name (Optional)
              </label>
              <input
                type="text"
                id="actionName"
                value={actionName}
                onChange={(e) => setActionName(e.target.value)}
                className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                placeholder="e.g. Read More"
              />
            </div>
            <div>
              <label
                htmlFor="actionURL"
                className="block text-gray-700 text-sm font-bold mb-2"
              >
                Action URL (Optional)
              </label>
              <input
                type="url"
                id="actionURL"
                value={actionURL}
                onChange={(e) => setActionURL(e.target.value)}
                className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                placeholder="https://example.com"
              />
            </div>
          </div>

          {/* Error Message */}
          {error && (
            <div
              className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative"
              role="alert"
            >
              <span className="block sm:inline">{error}</span>
            </div>
          )}

          {/* Submit Button */}
          <div className="flex justify-end space-x-4 mt-6">
            <motion.button
              type="button"
              whileTap={{ scale: 0.95 }}
              onClick={cancel}
              className="px-6 py-2 bg-gray-200 text-gray-800 rounded-md hover:bg-gray-300 transition"
            >
              Cancel
            </motion.button>
            <motion.button
              type="submit"
              whileTap={{ scale: 0.95 }}
              className="px-6 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 transition"
            >
              Create Blog
            </motion.button>
          </div>
        </form>
      </div>
    </motion.div>
  );
};

export default AddBlogForm;
