import React, { useState } from "react";
import { FaStar, FaArrowRight, FaTimes } from "react-icons/fa";
import { motion } from "framer-motion";
import GoogleReviews from "../components/GoogleReviews";
import Counsellor from "../components/Counsellors";
import Resourse from "../components/Counselling/Resourse";

const CounsellingPage = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedPackage, setSelectedPackage] = useState(null);

  const packages = [
    {
      name: "Basic Package",
      price: "$100",
      description: "Initial consultation and personalized plan.",
      color: "from-blue-400 to-blue-600",
    },
    {
      name: "Standard Package",
      price: "$200",
      description: "Includes application support and follow-up.",
      mostPopular: true,
      color: "from-purple-400 to-purple-600",
      details: [
        "Personalized plan",
        "Application support",
        "Follow-up sessions",
        "Additional resources and tips",
      ],
    },
    {
      name: "Premium Package",
      price: "$300",
      description: "All services included, plus interview prep.",
      color: "from-indigo-400 to-indigo-600",
    },
  ];

  const services = [
    {
      title: "Career Counselling",
      icon: "🎯",
      description:
        "Explore your career options and make informed decisions about your future.",
    },
    {
      title: "Education Counselling",
      icon: "🎓",
      description:
        "Get guidance on choosing the right educational path for your goals.",
    },
    {
      title: "Application Assistance",
      icon: "📝",
      description:
        "Expert help with your applications to increase chances of success.",
    },
    {
      title: "Visa Counselling",
      icon: "✈️",
      description:
        "Navigate the visa process with confidence and expert guidance.",
    },
    {
      title: "Financial Advice",
      icon: "💰",
      description:
        "Understanding funding options and financial planning for your education.",
    },
    {
      title: "Interview Preparation",
      icon: "🎤",
      description:
        "Comprehensive preparation for academic and visa interviews.",
    },
  ];

  const handleCardClick = (pkg) => {
    setSelectedPackage(pkg);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedPackage(null);
  };

  return (
    <div className="min-h-screen bg-gradient-to-b from-gray-50 to-gray-100">
      {/* Hero Section */}
      <section className="relative bg-gradient-to-br from-blue-100 to-indigo-100 text-white py-24 px-4 overflow-hidden">
        <div className="absolute inset-0 opacity-10"></div>
        <div className="max-w-4xl mx-auto relative z-10">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6 }}
            className="text-center"
          >
            <h1 className="text-5xl font-bold mb-6 text-black">
              Transform Your Future with Expert Guidance
            </h1>
            <p className="text-xl mb-8 text-black">
              We provide comprehensive counselling services to help you achieve
              your educational and career aspirations.
            </p>
            <button className="bg-white text-blue-600 px-8 py-3 rounded-full font-semibold hover:bg-blue-50 transition-colors duration-300 flex items-center mx-auto">
              Get Started <FaArrowRight className="ml-2" />
            </button>
          </motion.div>
        </div>
        <div className="relative w-full max-w-lg lg:max-w-2xl xl:max-w-3xl">
          <div className="absolute top-0 -left-4 w-72 h-72 bg-purple-300 rounded-full mix-blend-multiply filter blur-xl opacity-70 animate-blob"></div>
          <div className="absolute top-0 -right-4 w-72 h-72 bg-yellow-300 rounded-full mix-blend-multiply filter blur-xl opacity-70 animate-blob animation-delay-2000"></div>
          <div className="absolute -bottom-8 left-20 w-72 h-72 bg-pink-300 rounded-full mix-blend-multiply filter blur-xl opacity-70 animate-blob animation-delay-4000"></div>
          <div className="relative"></div>
        </div>
      </section>

      {/* Services Section */}
      <section className="py-20 px-4">
        <div className="max-w-6xl mx-auto">
          <motion.h2
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            viewport={{ once: true }}
            className="text-4xl font-bold mb-16 text-center"
          >
            Our Services
          </motion.h2>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
            {services.map((service, index) => (
              <motion.div
                key={index}
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
                transition={{ delay: index * 0.1 }}
                className="bg-white rounded-xl shadow-lg p-8 hover:shadow-xl transition-shadow duration-300 border border-gray-100"
              >
                <div className="text-4xl mb-4">{service.icon}</div>
                <h3 className="text-xl font-semibold mb-3">{service.title}</h3>
                <p className="text-gray-600">{service.description}</p>
              </motion.div>
            ))}
          </div>
        </div>
      </section>

      {/* Process Section */}
      <section className="bg-white py-20 px-4">
        <div className="max-w-4xl mx-auto">
          <motion.h2
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            viewport={{ once: true }}
            className="text-4xl font-bold mb-16 text-center"
          >
            Our Process
          </motion.h2>
          <div className="space-y-12">
            {[
              "Initial Consultation",
              "Personalized Plan",
              "Application Support",
              "Follow-Up and Support",
            ].map((step, index) => (
              <motion.div
                key={index}
                initial={{ opacity: 0, x: -20 }}
                whileInView={{ opacity: 1, x: 0 }}
                viewport={{ once: true }}
                transition={{ delay: index * 0.1 }}
                className="flex items-start group"
              >
                <div className="bg-gradient-to-r from-blue-500 to-blue-600 text-white rounded-xl w-12 h-12 flex items-center justify-center mr-6 flex-shrink-0 group-hover:scale-110 transition-transform duration-300">
                  {index + 1}
                </div>
                <div>
                  <h3 className="text-xl font-semibold mb-3">{step}</h3>
                  <p className="text-gray-600">
                    Comprehensive {step.toLowerCase()} designed to ensure your
                    success throughout the journey.
                  </p>
                </div>
              </motion.div>
            ))}
          </div>
        </div>
      </section>

      {/* Packages Section */}
      <section className="py-20 px-4 bg-gradient-to-b from-gray-50 to-gray-100">
        <div className="max-w-4xl mx-auto">
          <motion.h2
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            viewport={{ once: true }}
            className="text-4xl font-bold mb-16 text-center"
          >
            Choose Your Package
          </motion.h2>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            {packages.map((pkg, index) => (
              <motion.div
                key={index}
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
                transition={{ delay: index * 0.1 }}
                className="relative group cursor-pointer"
                onClick={() => handleCardClick(pkg)}
              >
                <div
                  className={`bg-gradient-to-br ${pkg.color} rounded-2xl p-8 text-white transform group-hover:-translate-y-2 transition-transform duration-300`}
                >
                  {pkg.mostPopular && (
                    <div className="absolute -top-4 -right-4 bg-yellow-400 text-black font-semibold py-2 px-4 rounded-full shadow-lg flex items-center">
                      <FaStar className="mr-1" /> Most Popular
                    </div>
                  )}
                  <h3 className="text-2xl font-bold mb-4">{pkg.name}</h3>
                  <p className="text-3xl font-bold mb-4">{pkg.price}</p>
                  <p className="text-white/90 mb-6">{pkg.description}</p>
                  <button className="w-full bg-white/20 hover:bg-white/30 py-2 rounded-lg transition-colors duration-300">
                    Learn More
                  </button>
                </div>
              </motion.div>
            ))}
          </div>
        </div>
      </section>

      {/* Reviews Section */}
      <section className="bg-white py-20 px-4">
        <div className="max-w-4xl mx-auto">
          <motion.h2
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            viewport={{ once: true }}
            className="text-4xl font-bold mb-16 text-center"
          >
            Success Stories
          </motion.h2>
          <GoogleReviews />
        </div>
      </section>

      {/* Counsellor Section */}
      <section className="py-20 px-4 bg-gradient-to-b from-gray-50 to-gray-100">
        <div className="max-w-4xl mx-auto">
          <Counsellor />
        </div>
      </section>

      {/* Resources Section */}
      <section className="bg-white py-20 px-4">
        <div className="max-w-4xl mx-auto">
          <Resourse />
        </div>
      </section>

      {/* Modal */}
      {isModalOpen && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          className="fixed inset-0 bg-black/50 backdrop-blur-sm flex items-center justify-center p-4 z-50"
          onClick={closeModal}
        >
          <motion.div
            initial={{ scale: 0.9, opacity: 0 }}
            animate={{ scale: 1, opacity: 1 }}
            exit={{ scale: 0.9, opacity: 0 }}
            className="bg-white rounded-2xl p-8 max-w-md w-full relative"
            onClick={(e) => e.stopPropagation()}
          >
            <button
              className="absolute top-4 right-4 text-gray-400 hover:text-gray-600"
              onClick={closeModal}
            >
              <FaTimes size={24} />
            </button>
            <h3 className="text-2xl font-bold mb-4">{selectedPackage.name}</h3>
            <p className="text-3xl font-bold text-blue-600 mb-4">
              {selectedPackage.price}
            </p>
            <p className="text-gray-600 mb-6">{selectedPackage.description}</p>
            {selectedPackage.details && (
              <ul className="space-y-3">
                {selectedPackage.details.map((detail, index) => (
                  <li key={index} className="flex items-center text-gray-600">
                    <div className="w-2 h-2 rounded-full bg-blue-600 mr-3"></div>
                    {detail}
                  </li>
                ))}
              </ul>
            )}
            <button className="w-full bg-gradient-to-r from-blue-500 to-blue-600 text-white py-3 rounded-xl mt-8 hover:opacity-90 transition-opacity duration-300">
              Get Started Now
            </button>
          </motion.div>
        </motion.div>
      )}
    </div>
  );
};

export default CounsellingPage;
