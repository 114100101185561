import React, { useState } from "react";
import {
  ChevronDown,
  MapPin,
  Calendar,
  GraduationCap,
  Globe,
} from "lucide-react";
import { constants } from "../constants";

const ContactUsForm = () => {
  const { serverURL } = constants;
  const [selectedYear, setSelectedYear] = useState("");
  const [intakeOptions, setIntakeOptions] = useState([]);

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    city: "",
    state: "",
    nearestOffice: "",
    studyDestination: "",
    studyYear: "",
    studyIntake: "",
    purpose: "",
    counsellingMode: "",
    agreeToTerms: false,
  });

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState(false);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError("");
    setSuccess(false);
    formData.studyYear = selectedYear;
    try {
      const response = await fetch(`${serverURL}/api/email/sendEmail`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });

      if (!response.ok) {
        throw new Error("Failed to submit form");
      }

      setSuccess(true);
      setFormData({
        name: "",
        email: "",
        phone: "",
        city: "",
        state: "",
        nearestOffice: "",
        studyDestination: "",
        studyYear: "",
        studyIntake: "",
        purpose: "",
        counsellingMode: "",
        agreeToTerms: false,
      });
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  const handleYearChange = (event) => {
    const year = event.target.value;
    setSelectedYear(year);

    switch (year) {
      case "2025":
        setIntakeOptions(["Fall 2025", "Spring 2025", "Autumn 2025"]);
        break;
      case "2026":
        setIntakeOptions(["Fall 2026", "Spring 2026", "Autumn 2026"]);
        break;
      case "2027":
        setIntakeOptions(["Fall 2027", "Spring 2027", "Autumn 2027"]);
        break;
      default:
        setIntakeOptions([]);
    }
  };

  return (
    <div className="w-full max-w-7xl mx-auto p-6 md:p-12 bg-" id="contact">
      <div className="flex flex-col lg:flex-row rounded-2xl shadow-xl overflow-hidden ">
        <div className="lg:w-2/5 p-8 md:p-12 bg-primary-brown  text-white flex flex-col justify-center">
          <h1 className="text-3xl md:text-4xl mb-6 leading-tight font-extrabold">
            Better futures, begin with Us!
          </h1>
          <div className="space-y-4">
            <p className="text-lg leading-relaxed">
              We're here to support your study abroad journey and help you build
              an extraordinary future.
            </p>
            <p className="text-lg leading-relaxed">
              Fill out the form to schedule a free consultation session, and our
              counselors will be in touch with you soon.
            </p>
          </div>
        </div>

        <div className="lg:w-3/5 p-8 md:p-12 bg-gray-50">
          <h2 className="text-2xl font-semibold text-gray-800 mb-8">
            Book your FREE consultation with Certified Counsellors
          </h2>

          <form className="space-y-6" onSubmit={handleSubmit}>
            <div className="grid md:grid-cols-2 gap-6">
              <div className="space-y-2">
                <label
                  htmlFor="name"
                  className="block text-sm font-medium text-gray-700"
                >
                  Name
                </label>
                <input
                  type="text"
                  id="name"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  required
                  placeholder="Your name"
                  className="w-full px-4 py-3 rounded-lg border border-gray-300 focus:ring-2 focus:ring-blue-500 focus:border-blue-500 transition duration-200"
                />
              </div>
              <div className="space-y-2">
                <label
                  htmlFor="email"
                  className="block text-sm font-medium text-gray-700"
                >
                  Email
                </label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  required
                  placeholder="Your email"
                  className="w-full px-4 py-3 rounded-lg border border-gray-300 focus:ring-2 focus:ring-blue-500 focus:border-blue-500 transition duration-200"
                />
              </div>
            </div>

            <div className="space-y-2">
              <label
                htmlFor="phone"
                className="block text-sm font-medium text-gray-700"
              >
                Mobile Number
              </label>
              <div className="flex gap-4">
                <select className="w-24 px-3 py-3 rounded-lg border border-gray-300 focus:ring-2 focus:ring-blue-500 focus:border-blue-500 transition duration-200">
                  <option value="+91">+91</option>
                </select>
                <input
                  type="tel"
                  id="phone"
                  name="phone"
                  value={formData.phone}
                  onChange={handleChange}
                  required
                  placeholder="Your mobile number"
                  className="w-full flex-1 px-4 py-3 rounded-lg border border-gray-300 focus:ring-2 focus:ring-blue-500 focus:border-blue-500 transition duration-200"
                />
              </div>
            </div>
            <div className="grid md:grid-cols-2 gap-6">
              <div className="space-y-2">
                <label
                  htmlFor="city"
                  className="block text-sm font-medium text-gray-700"
                >
                  Your City
                </label>
                <div className="relative">
                  <input
                    name="city"
                    value={formData.city}
                    onChange={handleChange}
                    required
                    placeholder="Your City"
                    type="text"
                    className="w-full px-4 py-3 rounded-lg border border-gray-300 focus:ring-2 focus:ring-blue-500 focus:border-blue-500 transition duration-200"
                  />
                </div>
              </div>
              <div className="space-y-2">
                <label
                  htmlFor="state"
                  className="block text-sm font-medium text-gray-700"
                >
                  Your State
                </label>
                <div className="relative">
                  <input
                    name="state"
                    value={formData.state}
                    onChange={handleChange}
                    required
                    placeholder="Your State"
                    type="text"
                    className="w-full px-4 py-3 rounded-lg border border-gray-300 focus:ring-2 focus:ring-blue-500 focus:border-blue-500 transition duration-200"
                  />
                </div>
              </div>
            </div>
            <div className="grid md:grid-cols-2 gap-6">
              <div className="space-y-2">
                <label
                  htmlFor="nearestOffice"
                  className="block text-sm font-medium text-gray-700"
                >
                  Nearest Office
                </label>
                <div className="relative">
                  <select
                    id="nearestOffice"
                    name="nearestOffice"
                    value={formData.nearestOffice}
                    onChange={handleChange}
                    required
                    className="w-full px-4 py-3 rounded-lg border border-gray-300 focus:ring-2 focus:ring-blue-500 focus:border-blue-500 transition duration-200 appearance-none"
                  >
                    <option value="">Select office</option>
                    <option>Anand (HO)</option>
                    <option>Ahmedabad- Navrangpura</option>
                    <option>Ahmedabad- Maninagar</option>
                    <option>Nadiad</option>
                    <option>Vadodara</option>
                    <option>Jamnagar</option>
                    <option>Borsad</option>
                    <option>Navsari</option>
                    <option>Surat</option>
                  </select>
                  <MapPin className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-400 w-5 h-5" />
                </div>
              </div>
              <div className="space-y-2">
                <label
                  htmlFor="studyDestination"
                  className="block text-sm font-medium text-gray-700"
                >
                  Preferred Study Destination
                </label>
                <div className="relative">
                  <select
                    id="studyDestination"
                    name="studyDestination"
                    value={formData.studyDestination}
                    onChange={handleChange}
                    required
                    className="w-full px-4 py-3 rounded-lg border border-gray-300 focus:ring-2 focus:ring-blue-500 focus:border-blue-500 transition duration-200 appearance-none"
                  >
                    <option value="">Select destination</option>
                    <option>Canada</option>
                    <option>UK</option>
                    <option>USA</option>
                    <option>Germany</option>
                    <option>Europe</option>
                    <option>Australia</option>
                    <option>New Zealand</option>
                    <option>Other</option>
                  </select>
                  <Globe className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-400 w-5 h-5" />
                </div>
              </div>
            </div>

            <div className="grid md:grid-cols-2 gap-6">
              <div className="space-y-2">
                <label
                  htmlFor="studyYear"
                  className="block text-sm font-medium text-gray-700"
                >
                  Preferred Study Year
                </label>
                <div className="relative">
                  <select
                    id="studyYear"
                    name="studyYear"
                    value={selectedYear}
                    onChange={handleYearChange}
                    required
                    className="w-full px-4 py-3 rounded-lg border border-gray-300 focus:ring-2 focus:ring-blue-500 focus:border-blue-500 transition duration-200 appearance-none"
                  >
                    <option value="">Select year</option>
                    <option selected>2025</option>
                    <option>2026</option>
                    <option>2026</option>
                  </select>
                  <Calendar className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-400 w-5 h-5" />
                </div>
              </div>
              <div className="space-y-2">
                <label
                  htmlFor="studyIntake"
                  className="block text-sm font-medium text-gray-700"
                >
                  Preferred Study Intake
                </label>
                <div className="relative">
                  <select
                    id="studyIntake"
                    name="studyIntake"
                    value={formData.studyIntake}
                    onChange={handleChange}
                    required
                    className="w-full px-4 py-3 rounded-lg border border-gray-300 focus:ring-2 focus:ring-blue-500 focus:border-blue-500 transition duration-200 appearance-none"
                  >
                    <option value="">Select intake</option>
                    {intakeOptions.map((option, index) => (
                      <option key={index} value={option}>
                        {option}
                      </option>
                    ))}
                  </select>
                  <GraduationCap className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-400 w-5 h-5" />
                </div>
              </div>
            </div>
            <div className="grid md:grid-cols-2 gap-6">
              <div className="space-y-2">
                <label
                  htmlFor="purpose"
                  className="block text-sm font-medium text-gray-700"
                >
                  Purpose
                </label>
                <div className="relative">
                  <select
                    id="purpose"
                    name="purpose"
                    value={formData.purpose}
                    onChange={handleChange}
                    required
                    className="w-full px-4 py-3 rounded-lg border border-gray-300 focus:ring-2 focus:ring-blue-500 focus:border-blue-500 transition duration-200 appearance-none"
                  >
                    <option value="Visitor Visa">Visitor Visa</option>
                    <option value="Student Visa">Student Visa</option>
                    <option value="Dependent Visa">Dependent Visa</option>
                    <option value="Study Abroad">Study Abroad</option>
                    <option value="Loan">Loan</option>
                    <option value="IELTS">IELTS</option>
                    <option value="PTE">PTE</option>
                    <option value="Spoken English">Spoken English</option>
                    <option value="Counselling">Counselling</option>
                    <option value="Grammar">Grammar</option>
                    <option value="TOEFL">TOEFL</option>
                    <option value="Duolingo">Duolingo</option>
                    <option value="Forex">Forex</option>
                    <option value="Other">Other</option>
                  </select>
                  <Calendar className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-400 w-5 h-5" />
                </div>
              </div>
              <div className="space-y-2">
                <label
                  htmlFor="counsellingMode"
                  className="block text-sm font-medium text-gray-700"
                >
                  Mode of Counselling
                </label>
                <div className="flex gap-10">
                  <div className="flex gap-2">
                    <input
                      type="radio"
                      id="online"
                      name="counsellingMode"
                      value="online"
                      checked={formData.counsellingMode === "online"}
                      onChange={handleChange}
                      required
                    />
                    <span>Online</span>
                  </div>
                  <div className="flex gap-2">
                    <input
                      type="radio"
                      id="offline"
                      name="counsellingMode"
                      value="offline"
                      checked={formData.counsellingMode === "offline"}
                      onChange={handleChange}
                    />
                    <span>Offline</span>
                  </div>
                </div>
              </div>
            </div>

            <div className="space-y-2">
              <label className="flex items-start gap-3 cursor-pointer group">
                <input
                  type="checkbox"
                  name="agreeToTerms"
                  checked={formData.agreeToTerms}
                  onChange={handleChange}
                  required
                  className="mt-1 w-4 h-4 text-blue-600 border-gray-300 rounded focus:ring-blue-500"
                />
                <span className="text-sm text-gray-600">
                  By clicking you agree to our{" "}
                  <a
                    href="#privacy-policy"
                    className="text-blue-600 hover:text-blue-700 underline"
                  >
                    Privacy Policy
                  </a>{" "}
                  and{" "}
                  <a
                    href="#terms-conditions"
                    className="text-blue-600 hover:text-blue-700 underline"
                  >
                    Terms & Conditions
                  </a>
                </span>
              </label>
            </div>

            {error && <div className="text-red-500 text-sm">{error}</div>}

            {success && (
              <div className="text-green-500 text-sm">
                Form submitted successfully!
              </div>
            )}

            <button
              type="submit"
              disabled={loading}
              className="w-full md:w-auto px-8 py-4 bg-primary-brown text-white rounded-xl font-semibold 
                       hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 
                       transform transition-all duration-200 hover:-translate-y-0.5 disabled:opacity-50 disabled:cursor-not-allowed"
            >
              {loading ? "Submitting..." : "Get Started for Free"}
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ContactUsForm;
