import React from "react";
import {
  FileText,
  Globe,
  UserCheck,
  Edit,
  CreditCard,
  CheckCircle,
} from "lucide-react";

const AdmissionRequirements = () => {
  const requirements = [
    {
      icon: FileText,
      color: "text-blue-500",
      bgColor: "bg-blue-100",
      text: "Official Transcripts: High school or previous education transcripts.",
    },
    {
      icon: Globe,
      color: "text-green-500",
      bgColor: "bg-green-100",
      text: "Language Proficiency: Proof of English or French proficiency (IELTS, TOEFL, etc.).",
    },
    {
      icon: UserCheck,
      color: "text-yellow-500",
      bgColor: "bg-yellow-100",
      text: "Letters of Recommendation: Typically from teachers, professors, or employers.",
    },
    {
      icon: Edit,
      color: "text-red-500",
      bgColor: "bg-red-100",
      text: "Statement of Purpose: An essay explaining your goals and motivation for studying.",
    },
    {
      icon: CreditCard,
      color: "text-purple-500",
      bgColor: "bg-purple-100",
      text: "Application Fee: Payment of the application processing fee.",
    },
  ];

  return (
    <div className="bg-primary-cream min-h-screen flex items-center justify-center p-6">
      <div className="bg-white/10 backdrop-blur-lg border border-white/20 rounded-2xl shadow-2xl max-w-4xl w-full p-10">
        <h2 className="text-5xl max-md:text-3xl font-extrabold text-black text-center mb-12 tracking-tight">
          Admission <span className="text-primary-brown">Requirements</span>
        </h2>
        <div className="grid md:grid-cols-3 gap-6">
          {requirements.map((requirement, index) => (
            <div
              key={index}
              className="relative group bg-white/10 border shadow-lg rounded-xl p-6 text-center transition-all duration-300 hover:scale-105 hover:shadow-xl hover:border-white/40"
            >
              <div
                className={`mx-auto w-20 h-20 mb-4 rounded-full flex items-center justify-center ${requirement.bgColor} ${requirement.color}`}
              >
                <requirement.icon className="w-10 h-10" />
              </div>
              <p className="text-primary-accent text-sm font-medium">
                {requirement.text}
              </p>
              <CheckCircle className="absolute top-4 right-4 w-6 h-6 text-green-400 opacity-0 group-hover:opacity-100 transition-opacity duration-300" />
            </div>
          ))}
        </div>
        <div className="text-center mt-10">
          <a
            href="#apply"
            className="inline-block bg-white text-blue-600 font-bold py-3 px-8 rounded-full shadow-lg hover:bg-blue-50 transition-colors duration-300 hover:scale-105"
          >
            Start Your Application
          </a>
        </div>
      </div>
    </div>
  );
};

export default AdmissionRequirements;
