import React from "react";

const PopularPrograms = ({ programs }) => {
  if (!programs) return;

  return (
    <div className="container mx-auto px-4 py-12">
      <h2 className="text-3xl font-bold text-center mb-8">Popular Programs</h2>
      <div className="grid md:grid-cols-3 gap-6">
        {programs.map((program) => (
          <div
            key={program.id}
            className="bg-white border rounded-lg p-6 text-center shadow-sm hover:shadow-md transition-shadow"
          >
            <div className="mx-auto w-16 h-16 mb-4 bg-blue-100 rounded-full flex items-center justify-center">
              <program.icon className="w-8 h-8 text-blue-600" />
            </div>
            <h3 className="font-semibold text-xl mb-2">{program.name}</h3>
            <p className="text-gray-600">{program.description}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default PopularPrograms;
