import React, { useState } from "react";
import "./VideoReels.css";
import { Link } from "react-router-dom";

const videos = [
  {
    src: "https://www.youtube.com/embed/D8vWYEMQSKE",
    alt: "Avanti Nagrale",
    description: "Avanti Nagrale",
  },
  {
    src: "https://www.youtube.com/embed/8euv-4cM8ZM",
    alt: "The Business School",
    description: "The Business School",
  },
  {
    src: "https://www.youtube.com/embed/GUehu2HqQdE",
    alt: "London",
    description: "London",
  },
  {
    src: "https://www.youtube.com/embed/aoxRHKjbB0s",
    alt: "UK Life",
    description: "UK Life",
  },
  {
    src: "https://www.youtube.com/embed/vBXUHNqD5yY",
    alt: "Montreal",
    description: "Montreal",
  },
];

const VideoReels = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const handleNext = () => {
    setCurrentIndex((currentIndex + 1) % videos.length);
  };

  const handlePrevious = () => {
    setCurrentIndex((currentIndex - 1 + videos.length) % videos.length);
  };

  return (
    <div className="video-reels">
      <h2 className="video-reels-title">
        They're living the dream, <a href="#">and you can too!</a>
      </h2>
      <div className="video-container">
        {/* <button className="nav-button left" onClick={handlePrevious}>
          &lt;
        </button> */}
        <div className="carousel">
          {videos.map((video, index) => (
            <div key={index} className="phone-frame">
              <iframe
                src={video.src}
                title={video.alt}
                className="video"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
              <div className="video-description">{video.description}</div>
            </div>
          ))}
        </div>
        {/* <button className="nav-button right" onClick={handleNext}>
          &gt;
        </button> */}
      </div>
      <div className="know-more">
        <Link to="/gallery">
          <a className="text-blue-500">Know More</a>
        </Link>
      </div>
    </div>
  );
};

export default VideoReels;
