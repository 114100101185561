import React from "react";
import { motion } from "framer-motion";
import { Home, FileText, Settings } from "lucide-react";

const Sidebar = ({ isOpen }) => {
  const sidebarVariants = {
    open: { x: 0 },
    closed: { x: "-100%" },
  };

  return (
    <motion.div
      initial="closed"
      animate={isOpen ? "open" : "closed"}
      variants={sidebarVariants}
      transition={{ duration: 0.3 }}
      className="w-64 bg-gray-800 text-white p-4 fixed h-full"
    >
      {/* <h2 className="text-2xl font-bold mb-6">Dashboard</h2> */}
      <nav className="mt-10">
        <ul className="space-y-2">
          <li>
            <a
              href="#"
              className="flex items-center gap-2 p-2 hover:bg-gray-700 rounded"
            >
              <Home size={20} />
              Home
            </a>
          </li>
          <li>
            <a
              href="#"
              className="flex items-center gap-2 p-2 hover:bg-gray-700 rounded"
            >
              <FileText size={20} />
              Blogs
            </a>
          </li>
          <li>
            <a
              href="#"
              className="flex items-center gap-2 p-2 hover:bg-gray-700 rounded"
            >
              <Settings size={20} />
              Settings
            </a>
          </li>
        </ul>
      </nav>
    </motion.div>
  );
};

export default Sidebar;
