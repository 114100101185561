import React, { useState, useEffect } from "react";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";

const FeaturedPartner = () => {
  const [isButtonVisible] = useState(true);
  const controls = useAnimation();
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  useEffect(() => {
    if (inView) {
      controls.start("visible");
    }
  }, [controls, inView]);

  const containerVariants = {
    hidden: { opacity: 0, y: 50 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.8,
        staggerChildren: 0.2,
      },
    },
  };

  const itemVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: {
      opacity: 1,
      y: 0,
      transition: { duration: 0.5 },
    },
  };

  const universities = [
    {
      name: "Technical University of Munich",
      logo: "https://cdn.freelogovectors.net/wp-content/uploads/2019/02/tum-logo.png",
    },
    {
      name: "Ludwig Maximilian University",
      logo: "https://students.uu.nl/sites/default/files/styles/image_385x257/public/images/600px-gruen-logo_lmu2.svg_.png",
    },
    {
      name: "Heidelberg University",
      logo: "https://th.bing.com/th/id/OIP.xa77f4V09Ay13uoMppSeiAHaHa?rs=1&pid=ImgDetMain",
    },
    {
      name: "Free University of Berlin",
      logo: "https://www.designtagebuch.de/wp-content/uploads/mediathek/2023/06/freie-uni-berlin-logo-bildmarke-1100x825.jpg",
    },
  ];

  return (
    <motion.div
      ref={ref}
      variants={containerVariants}
      initial="hidden"
      animate={controls}
      className="bg-gradient-to-br mt-0 bg-primary-cream text-white py-16 px-4 sm:px-6 lg:px-8 rounded-2xl shadow-2xl"
    >
      <div className="max-w-7xl mx-auto">
        <motion.div
          variants={itemVariants}
          className="max-w-3xl mx-auto text-center mb-12"
        >
          <div className="rounded-xl overflow-hidden mb-8 shadow-lg">
            <img
              src="https://roadtoabroad.co.in/wp-content/uploads/2023/03/university-logos-1EDITED-min.jpg"
              className="w-full h-auto object-cover"
              alt="Featured Partner"
            />
          </div>

          <h2 className="text-2xl sm:text-3xl lg:text-4xl font-bold mb-6 bg-clip-text bg-gradient-to-r text-primary-accent">
            Study in Germany with BEST SCHOLARSHIPS!*
          </h2>

          <p className="text-lg sm:text-xl text-primary-accent mb-8">
            Explore the popular courses, scholarships & more offered by the
            institutions in Germany
          </p>

          {isButtonVisible && (
            <HashLink to="/#contact" smooth>
            <motion.button
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              className="bg-primary-brown text-primary-cream px-8 py-4 rounded-full font-semibold 
                         text-lg shadow-lg hover:bg-primary-dark transform transition-all duration-200
                         hover:shadow-xl focus:outline-none focus:ring-2 focus:ring-primary-brown focus:ring-offset-2
                         focus:ring-offset-primary"
            >
              Register for FREE
            </motion.button>
            </HashLink>
          )}
        </motion.div>

        <motion.div
          variants={itemVariants}
          className="grid grid-cols-2 md:grid-cols-4 gap-6 md:gap-8 max-w-4xl mx-auto"
        >
          {universities.map((uni, index) => (
            <motion.div
              key={index}
              whileHover={{ scale: 1.05 }}
              className="bg-primary-gray p-4 rounded-xl shadow-lg transform transition-all duration-200
                         hover:shadow-xl hover:bg-primary-gray flex items-center justify-center"
            >
              <img
                src={uni.logo}
                alt={uni.name}
                className="w-16 h-16 md:w-20 md:h-20 object-contain"
              />
            </motion.div>
          ))}
        </motion.div>
      </div>
    </motion.div>
  );
};

export default FeaturedPartner;
