import React, { useEffect } from "react";

const GoogleReviews = () => {
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://static.elfsight.com/platform/platform.js";
    script.setAttribute("data-use-service-core", "defer");
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <div className="w-full max-w-4xl mx-auto bg-white rounded-lg shadow-md p-6">
      <div
        className="elfsight-app-9db0a6a5-05b5-4e4f-b566-13fba0fa1f26"
        data-elfsight-app-lazy
      ></div>
    </div>
  );
};

export default GoogleReviews;
