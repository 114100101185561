import React from "react";
import { motion } from "framer-motion";
import { FaPlay } from "react-icons/fa";

const YouTubeSection = () => {
  const videos = [
    { id: "40qgPcgJdO8", title: "Video 1" },
    { id: "-mNpIQs6ZxM", title: "Video 2" },
  ];

  return (
    <section className="p-8 bg-gray-100">
      <motion.h2
        className="text-4xl font-bold text-center text-gray-800 mb-8"
        initial={{ opacity: 0, y: -20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
      >
        Our YouTube Channel
      </motion.h2>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
        {videos.map((video) => (
          <div
            onClick={() =>
              window.open(
                `https://www.youtube.com/watch?v=${video.id}`,
                "_blank"
              )
            }
            key={video.id}
            className="relative aspect-video rounded-lg overflow-hidden shadow-lg transition-transform duration-300 hover:scale-105"
          >
            <motion.div
              className="absolute inset-0 bg-black bg-opacity-60 flex items-center justify-center cursor-pointer"
              initial={{ opacity: 0 }}
              whileHover={{ opacity: 1 }}
              transition={{ duration: 0.2 }}
            >
              <FaPlay className="text-white text-4xl" />
            </motion.div>

            <iframe
              src={`https://www.youtube.com/embed/${video.id}`}
              title={video.title}
              className="w-full h-full"
              frameBorder="0"
              allowFullScreen
              loading="lazy"
            />
          </div>
        ))}
      </div>
    </section>
  );
};

export default YouTubeSection;
