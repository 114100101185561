import React, { useState } from "react";
// import Image from "next/image";
import {
  FaBookOpen,
  FaGlobe,
  FaComments,
  FaRegCalendarAlt,
  FaLanguage,
  FaUsers,
  FaTimes,
} from "react-icons/fa";

export default function StudyAbroadPage() {
  const [activeTab, setActiveTab] = useState("Masters");
  const [activeFAQ, setActiveFAQ] = useState(null);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    contact: "",
    email: "",
    country: "",
  });

  const courses = {
    Masters: [
      {
        name: "Masters (MS) in Electrical Engineering Abroad",
        description:
          "Electrical engineering is one of the innovative fields that has been attracting students to explore due to its promising career options and vast learning area.",
        image:
          "https://i.pinimg.com/564x/b1/f4/7f/b1f47f17f367ea2d9a8afd300183e5bf.jpg",
      },
      {
        name: "Masters in Economics Abroad",
        description:
          "The production, distribution, and consumption of products and services are some of the key topics studied in economics.",
        image:
          "https://i.pinimg.com/564x/81/bd/7d/81bd7d8cf2d418e92c3c12cdb13bc724.jpg",
      },
    ],
    Bachelors: [
      {
        name: "Bachelors in Computer Science",
        description:
          "Explore the world of programming, algorithms, and systems design with a degree in Computer Science.",
        image:
          "https://i.pinimg.com/564x/39/9f/3c/399f3c689bb046424f3a963f67c7ac1a.jpg",
      },
      {
        name: "Bachelors in Business Administration",
        description:
          "A comprehensive program that covers key aspects of business, management, and finance.",
        image:
          "https://i.pinimg.com/564x/e3/be/cb/e3becbe14690710257533b92d61c4503.jpg",
      },
    ],
    Others: [
      {
        name: "Diploma in Graphic Design",
        description:
          "Learn the fundamentals of graphic design, including typography, color theory, and layout.",
        image:
          "https://i.pinimg.com/564x/f0/9d/fe/f09dfebe1a0ec2be16040a54197a65c9.jpg",
      },
    ],
  };

  const applicationSteps = [
    "Research and choose your course and country.",
    "Check the entry requirements.",
    "Prepare your application documents.",
    "Submit your application.",
    "Apply for your visa and accommodation.",
    "Prepare for departure and enjoy your journey!",
  ];

  const resources = [
    { title: "Visa Information", link: "/visa-information", icon: <FaGlobe /> },
    {
      title: "Scholarships and Financial Aid",
      link: "/scholarships",
      icon: <FaBookOpen />,
    },
    {
      title: "Accommodation Options",
      link: "/accommodation",
      icon: <FaComments />,
    },
    {
      title: "Cultural Integration Programs",
      link: "/cultural-integration",
      icon: <FaRegCalendarAlt />,
    },
  ];

  const faqs = [
    {
      question: "What are the eligibility criteria?",
      answer:
        "Eligibility criteria vary by country and course. Generally, you need to have completed high school and meet specific language proficiency requirements.",
    },
    {
      question: "How much does it cost to study abroad?",
      answer:
        "Costs vary significantly based on the country and institution. It's important to consider tuition fees, living expenses, and travel costs.",
    },
    {
      question: "What should I pack?",
      answer:
        "Pack according to the climate of your study destination. Essentials include clothes, toiletries, and any necessary study materials.",
    },
  ];

  const stats = [
    { value: "15k+", description: "Successful Admits" },
    { value: "$15 Million+", description: "Scholarships Achieved" },
    { value: "3500 Cr+", description: "Loans Sanctioned" },
    { value: "98%", description: "Visa Success Rate" },
  ];

  const handleFAQToggle = (index) => {
    setActiveFAQ(activeFAQ === index ? null : index);
  };

  const handleConnectClick = () => {
    setIsPopupOpen(true);
  };

  const handlePopupClose = () => {
    setIsPopupOpen(false);
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    alert(`You want to connect with experts in the field: ${formData.country}`);
    handlePopupClose();
  };

  return (
    <div className="min-h-screen bg-gray-100">
      <div
        className="relative bg-cover bg-center h-96"
        style={{
          backgroundImage:
            "url('https://i.pinimg.com/564x/b2/73/80/b27380a0441753ab454d55399fa7c69e.jpg')",
        }}
      >
        <div className="absolute inset-0 bg-black bg-opacity-50"></div>
        <div className="relative z-10 flex flex-col items-center justify-center h-full text-white">
          <h1 className="text-5xl font-bold mb-4 text-white">Study Abroad</h1>
          <p className="text-xl">
            Embark on an exciting journey to explore new cultures and gain a
            world-class education!
          </p>
        </div>
      </div>

      <div className="container mx-auto px-4 py-12">
        <div className="grid md:grid-cols-2 gap-8 mb-12">
          <div className="bg-white rounded-lg shadow-md p-6">
            <h2 className="text-2xl font-bold mb-4 text-blue-800">
              Why Study Abroad?
            </h2>
            <ul className="space-y-4">
              {[
                { icon: FaGlobe, text: "Experience diverse cultures" },
                { icon: FaLanguage, text: "Improve language skills" },
                { icon: FaBookOpen, text: "Enhance your resume" },
                { icon: FaUsers, text: "Make lifelong connections" },
                {
                  icon: FaComments,
                  text: "Develop personal growth and independence",
                },
              ].map((item, index) => (
                <li key={index} className="flex items-center">
                  <item.icon className="text-blue-600 mr-3 text-xl" />
                  <span>{item.text}</span>
                </li>
              ))}
            </ul>
          </div>
          <div className="bg-white rounded-lg shadow-md p-6">
            <h2 className="text-2xl font-bold mb-4 text-blue-800">
              Our Winning Record
            </h2>
            <p className="mb-4">
              No tall claims, we back the efficiency of our consultancy with
              stats. Check for yourselves!
            </p>
            <div className="grid grid-cols-2 gap-4">
              {stats.map((stat, index) => (
                <div
                  key={index}
                  className="bg-blue-100 rounded-lg p-4 text-center"
                >
                  <h3 className="text-2xl font-bold text-blue-800">
                    {stat.value}
                  </h3>
                  <p className="text-sm">{stat.description}</p>
                </div>
              ))}
            </div>
          </div>
        </div>

        <div className="mb-12">
          <h2 className="text-3xl font-bold mb-6 text-center text-blue-800">
            Available Courses
          </h2>
          <div className="flex justify-center mb-6">
            {Object.keys(courses).map((tab) => (
              <button
                key={tab}
                onClick={() => setActiveTab(tab)}
                className={`px-4 py-2 mx-2 rounded-full ${
                  activeTab === tab
                    ? "bg-blue-600 text-white"
                    : "bg-gray-200 text-gray-700"
                }`}
              >
                {tab}
              </button>
            ))}
          </div>
          <div className="grid md:grid-cols-2 gap-8">
            {courses[activeTab].map((course, index) => (
              <div
                key={index}
                className="bg-white rounded-lg shadow-md overflow-hidden"
              >
                <img
                  src={course.image}
                  alt={course.name}
                  width={400}
                  height={200}
                  className="w-full h-48 object-cover"
                />
                <div className="p-6">
                  <h3 className="text-xl font-bold mb-2">{course.name}</h3>
                  <p className="text-gray-600 mb-4">{course.description}</p>
                  <button
                    onClick={handleConnectClick}
                    className="bg-blue-600 text-white px-4 py-2 rounded hover:bg-blue-700 transition duration-300"
                  >
                    Connect with our Experts
                  </button>
                </div>
              </div>
            ))}
          </div>
        </div>

        <div className="mb-12">
          <h2 className="text-3xl font-bold mb-6 text-center text-blue-800">
            Application Process
          </h2>
          <div className="grid md:grid-cols-3 gap-6">
            {applicationSteps.map((step, index) => (
              <div key={index} className="bg-white rounded-lg shadow-md p-6">
                <div className="text-3xl font-bold text-blue-600 mb-2">
                  Step {index + 1}
                </div>
                <p>{step}</p>
              </div>
            ))}
          </div>
        </div>

        <div className="mb-12">
          <h2 className="text-3xl font-bold mb-6 text-center text-blue-800">
            Resources for Students
          </h2>
          <div className="grid md:grid-cols-4 gap-6">
            {resources.map((resource, index) => (
              <div
                key={index}
                className="bg-white rounded-lg shadow-md p-6 text-center"
              >
                <div className="text-4xl text-blue-600 mb-4">
                  {resource.icon}
                </div>
                <h3 className="text-xl font-bold mb-2">{resource.title}</h3>
                <a
                  href={resource.link}
                  className="text-blue-600 hover:underline"
                >
                  Learn More
                </a>
              </div>
            ))}
          </div>
        </div>

        <div className="mb-12">
          <h2 className="text-3xl font-bold mb-6 text-center text-blue-800">
            FAQs
          </h2>
          <div className="space-y-4">
            {faqs.map((faq, index) => (
              <div
                key={index}
                className="bg-white rounded-lg shadow-md overflow-hidden"
              >
                <button
                  className="w-full text-left p-4 font-semibold focus:outline-none"
                  onClick={() => handleFAQToggle(index)}
                >
                  {faq.question}
                </button>
                {activeFAQ === index && (
                  <div className="p-4 bg-gray-100">
                    <p>{faq.answer}</p>
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
      </div>

      {isPopupOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
          <div className="bg-white rounded-lg p-8 max-w-md w-full relative">
            <button
              onClick={handlePopupClose}
              className="absolute top-2 right-2 text-gray-500 hover:text-gray-700"
            >
              <FaTimes />
            </button>
            <h3 className="text-2xl font-bold mb-4">
              Connect with Our Experts
            </h3>
            <form onSubmit={handleSubmit} className="space-y-4">
              <div>
                <label htmlFor="name" className="block mb-1">
                  Name:
                </label>
                <input
                  type="text"
                  id="name"
                  name="name"
                  value={formData.name}
                  onChange={handleInputChange}
                  required
                  className="w-full p-2 border rounded"
                />
              </div>
              <div>
                <label htmlFor="contact" className="block mb-1">
                  Contact:
                </label>
                <input
                  type="text"
                  id="contact"
                  name="contact"
                  value={formData.contact}
                  onChange={handleInputChange}
                  required
                  className="w-full p-2 border rounded"
                />
              </div>
              <div>
                <label htmlFor="email" className="block mb-1">
                  Email:
                </label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  value={formData.email}
                  onChange={handleInputChange}
                  required
                  className="w-full p-2 border rounded"
                />
              </div>
              <div>
                <label htmlFor="country" className="block mb-1">
                  Country:
                </label>
                <select
                  id="country"
                  name="country"
                  value={formData.country}
                  onChange={handleInputChange}
                  required
                  className="w-full p-2 border rounded"
                >
                  <option value="">Select a country</option>
                  <option value="USA">USA</option>
                  <option value="Germany">Germany</option>
                  <option value="Canada">Canada</option>
                  <option value="Europe">Europe</option>
                  <option value="Australia">Australia</option>
                  <option value="New Zealand">New Zealand</option>
                  <option value="Others">Others</option>
                </select>
              </div>
              <button
                type="submit"
                className="w-full bg-blue-600 text-white py-2 px-4 rounded hover:bg-blue-700 transition duration-300"
              >
                Submit
              </button>
            </form>
          </div>
        </div>
      )}
    </div>
  );
}
