import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import { ArrowLeft, Edit, Trash2, ExternalLink } from "lucide-react";
import axios from "axios";
import { constants } from "../constants";
import { useParams } from "react-router-dom";

const Blog = () => {
  const [blog, setBlog] = useState();
  const [error, setError] = useState(null);
  const isAdmin = !!localStorage.getItem("adminToken");
  const { blogId } = useParams();

  useEffect(() => {
    getBlog();
  }, []);

  const getBlog = async () => {
    try {
      let response = await axios.get(
        `${constants.serverURL}/api/blogs/getBlogById/${blogId}`
      );
      setBlog(response.data.blog);
    } catch (error) {}
  };

  if (!blog) {
    return;
  }
  return (
    <motion.div
      initial={{ opacity: 0, x: 50 }}
      animate={{ opacity: 1, x: 0 }}
      exit={{ opacity: 0, x: -50 }}
      className="max-w-4xl my-20 mx-auto bg-white rounded-lg shadow-lg overflow-hidden relative"
    >
      {error && (
        <div className="absolute top-0 left-0 right-0 bg-red-500 text-white p-2 text-center z-10">
          {error}
          <button
            onClick={() => setError(null)}
            className="absolute right-2 top-1/2 transform -translate-y-1/2"
          >
            ×
          </button>
        </div>
      )}

      <div className="relative">
        <img
          src={`${constants.serverURL}${blog.imgUrl}`}
          alt={blog.title}
          className="w-full h-96 object-cover"
        />
        {/* <div className="absolute top-4 left-4 flex space-x-4">
          <motion.button
            whileTap={{ scale: 0.9 }}
            onClick={onBack}
            className="bg-white/80 p-2 rounded-full hover:bg-white/90 transition-colors"
            title="Back to list"
          >
            <ArrowLeft size={24} />
          </motion.button>
        </div> */}
      </div>

      <div className="p-8">
        <h1 className="text-4xl font-bold text-gray-800 mb-4">{blog.title}</h1>
        <div
          className="text-gray-600 text-lg leading-relaxed mb-6"
          dangerouslySetInnerHTML={{ __html: blog.content }}
        ></div>

        {blog.actionUrl && (
          <div className="mt-6">
            <a
              href={blog.actionUrl}
              target="_blank"
              rel="noopener noreferrer"
              className="inline-flex items-center px-6 py-3 bg-primary-brown text-white rounded-lg hover:bg-primary-accent transition-colors"
            >
              {blog.actionName || "Read More"}
              <ExternalLink size={20} className="ml-2" />
            </a>
          </div>
        )}

        <div className="mt-8 text-sm text-gray-500 flex justify-between items-center border-t pt-4">
          <span>
            Published on {new Date(blog.createdAt).toLocaleDateString()}
          </span>
        </div>
      </div>
    </motion.div>
  );
};

export default Blog;
