import React, { useState } from "react";
import { motion } from "framer-motion";
import { ArrowLeft, Edit, Trash2, ExternalLink } from "lucide-react";
import axios from "axios";
import { constants } from "../../constants";
import "./BlogPost.css";

const BlogPost = ({ blog, onBack, onEdit, onDelete }) => {
  const [isDeleting, setIsDeleting] = useState(false);
  const [error, setError] = useState(null);
  const isAdmin = !!localStorage.getItem("adminToken");

  const handleDelete = async () => {
    if (!isDeleting) {
      setIsDeleting(true);
      return;
    }

    try {
      const token = localStorage.getItem("adminToken");
      await axios.delete(
        `${constants.serverURL}/api/blogs/deleteBlog/${blog._id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      // Call the onDelete prop to update parent component
      onDelete();
    } catch (err) {
      console.error("Failed to delete blog:", err);
      setError(err.response?.data?.message || "Failed to delete blog");
      setIsDeleting(false);
    }
  };

  return (
    <motion.div
      initial={{ opacity: 0, x: 50 }}
      animate={{ opacity: 1, x: 0 }}
      exit={{ opacity: 0, x: -50 }}
      className="max-w-4xl mx-auto bg-white rounded-lg shadow-lg overflow-hidden relative"
    >
      {error && (
        <div className="absolute top-0 left-0 right-0 bg-red-500 text-white p-2 text-center z-10">
          {error}
          <button
            onClick={() => setError(null)}
            className="absolute right-2 top-1/2 transform -translate-y-1/2"
          >
            ×
          </button>
        </div>
      )}

      <div className="relative">
        <img
          src={`${constants.serverURL}${blog.imgUrl}`}
          alt={blog.title}
          className="w-full h-96 object-cover"
        />
        <div className="absolute top-4 left-4 flex space-x-4">
          <motion.button
            whileTap={{ scale: 0.9 }}
            onClick={onBack}
            className="bg-white/80 p-2 rounded-full hover:bg-white/90 transition-colors"
            title="Back to list"
          >
            <ArrowLeft size={24} />
          </motion.button>
        </div>
        {isAdmin && (
          <div className="absolute top-4 right-4 flex space-x-4">
            <motion.button
              whileTap={{ scale: 0.9 }}
              onClick={onEdit}
              className="bg-white/80 p-2 rounded-full hover:bg-white/90 transition-colors"
              title="Edit blog"
            >
              <Edit size={24} />
            </motion.button>
            <motion.button
              whileTap={{ scale: 0.9 }}
              onClick={handleDelete}
              className={`p-2 rounded-full transition-colors ${
                isDeleting
                  ? "bg-red-600 text-white"
                  : "bg-white/80 hover:bg-white/90"
              }`}
              title={isDeleting ? "Confirm Delete" : "Delete blog"}
            >
              <Trash2 size={24} />
            </motion.button>
          </div>
        )}
      </div>

      <div className="p-8">
        <h1 className="text-4xl font-bold text-gray-800 mb-4">{blog.title}</h1>
        <div
          className="text-gray-600 text-lg leading-relaxed mb-6"
          dangerouslySetInnerHTML={{ __html: blog.content }}
        ></div>

        {blog.actionUrl && (
          <div className="mt-6">
            <a
              href={blog.actionUrl}
              target="_blank"
              rel="noopener noreferrer"
              className="inline-flex items-center px-6 py-3 bg-primary-brown text-white rounded-lg hover:bg-primary-accent transition-colors"
            >
              {blog.actionName || "Read More"}
              <ExternalLink size={20} className="ml-2" />
            </a>
          </div>
        )}

        <div className="mt-8 text-sm text-gray-500 flex justify-between items-center border-t pt-4">
          <span>
            Published on {new Date(blog.createdAt).toLocaleDateString()}
          </span>
        </div>
      </div>

      {isDeleting && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white p-6 rounded-lg shadow-xl text-center">
            <h2 className="text-xl font-bold mb-4">Confirm Deletion</h2>
            <p className="mb-6">
              Are you sure you want to delete this blog post?
            </p>
            <div className="flex justify-center space-x-4">
              <button
                onClick={() => setIsDeleting(false)}
                className="px-4 py-2 bg-gray-200 text-gray-800 rounded-md hover:bg-gray-300"
              >
                Cancel
              </button>
              <button
                onClick={handleDelete}
                className="px-4 py-2 bg-red-600 text-white rounded-md hover:bg-red-700"
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      )}
    </motion.div>
  );
};

export default BlogPost;
