import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { constants } from "../../constants";

const UserActivate = () => {
  const { token } = useParams();
  const navigate = useNavigate();
  const { serverURL } = constants;
  const [loading, setLoading] = useState(true);
  const [message, setMessage] = useState("");

  useEffect(() => {
    const activateAccount = async () => {
      try {
        const response = await axios.post(
          `${serverURL}/api/auth/activateUser/${token}`
        );
        setMessage(response.data.message);
        toast.success(response.data.message);
        setTimeout(() => navigate("/login"), 3000);
      } catch (error) {
        setMessage(error.response?.data?.message || "Activation failed");
        toast.error(error.response?.data?.message || "Activation failed");
      } finally {
        setLoading(false);
      }
    };

    activateAccount();
  }, [token, navigate, serverURL]);

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-100">
      <ToastContainer />
      <div className="bg-white p-6 rounded-lg shadow-lg text-center">
        {loading ? (
          <p className="text-lg font-semibold">Activating your account...</p>
        ) : (
          <p className="text-lg font-semibold">{message}</p>
        )}
      </div>
    </div>
  );
};

export default UserActivate;
