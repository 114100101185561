import React, { useState } from "react";
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import logo from "../assets/mslogo1.png";

export default function Navbar() {
  const [isNavOpen, setIsNavOpen] = useState(false);
  const [openDropdown, setOpenDropdown] = useState(null);

  const navItems = [
    { name: "Home", dropdownItems: [], link: "/" },

    {
      name: "About",
      dropdownItems: [
        // { name: "Our Story", link: "/about/" },
        // { name: "Gallery", link: "/gallery" },
        // { name: "T&C", link: "/about/terms-and-conditions" },
      ],
      link: "/about",
    },
    {
      name: "Study Abroad",
      dropdownItems: [
        { name: "Canada", link: "/country-overview/canada" },
        { name: "USA", link: "/country-overview/usa" },
        { name: "Germany", link: "/country-overview/germany" },
        { name: "Europe", link: "/country-overview/europe" },
        { name: "Australia", link: "/country-overview/australia" },
      ],
      link: "/study-abroad",
    },
    {
      name: "Gallery",
      dropdownItems: [],
      link: "/gallery",
    },
    {
      name: "Services",
      dropdownItems: [
        // { name: "Consulting", link: "/services/consulting" },
        { name: "Study Abroad", link: "/study-abroad" },
        { name: "English Proficiency", link: "/english-proficiency" },
        { name: "Visa Process", link: "/visa" },
        { name: "Counselling", link: "/counselling" },
        // { name: "SOP's & Evaluations", link: "/services/sop-and-evaluations" },
        // {
        //   name: "University Recommendation",
        //   link: "/services/university-recommendation",
        // },
        // { name: "Accommodation", link: "/services/accommodation" },
      ],
      link: "",
    },
    {
      name: "Test Prep",
      dropdownItems: [
        { name: "IELTS", link: "/ielts" },
        // { name: "PTE", link: "/ielts" },
        // { name: "TOEFL", link: "/ielts" },
        // { name: "DULINGO", link: "/ielts" },
        // { name: "GRE/GMAT", link: "/test-prep/gre-gmat" },
        // { name: "SAT", link: "/test-prep/sat" },
      ],
      link: "/test-prep",
    },
    {
      name: "Events",
      dropdownItems: [
        // { name: "Career Counseling", link: "/counselling" },
        // { name: "Academic Counseling", link: "/counselling/academic" },
      ],
      link: "/events",
    },
    // {
    //   name: "Financial Services",
    //   dropdownItems: [
    //     { name: "Loans", link: "/financial-services/loans" },
    //     { name: "Scholarships", link: "/financial-services/scholarships" },
    //   ],
    //   link: "/financial-services",
    // },
    // {
    //   name: "Premium",
    //   dropdownItems: [
    //     { name: "Premium Services", link: "/premium/services" },
    //     { name: "Exclusive Offers", link: "/premium/offers" },
    //   ],
    //   link: "/premium",
    // },
    { name: "Contact Us", dropdownItems: [], link: "/contact" },
  ];
  // bg-[#F8F1E9]  old colour
  return (
    <header className="sticky top-0 z-50 w-full bg-[#fff] bg-opacity-60 shadow-md border-b border-gray-200 backdrop-blur-lg">
      <div className="px-4 sm:px-6 lg:px-8">
        <div className="flex justify-between items-center py-2">
          {/* Logo */}
          <div className="flex-shrink-0">
            <Link to="/">
              <img
                src={logo}
                alt="M.S EDU"
                className="h-14 sm:h-20 md:h-16 transition-transform duration-300 hover:scale-110"
              />
            </Link>
          </div>

          {/* Mobile menu button */}
          <div
            onClick={() => setIsNavOpen(!isNavOpen)}
            className="xl:hidden text-[#003366] hover:text-[#6A85B6] transition-colors"
          >
            <svg
              className="h-8 w-8"
              fill="none"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              {isNavOpen ? (
                <path d="M6 18L18 6M6 6l12 12" />
              ) : (
                <path d="M4 6h16M4 12h16M4 18h16" />
              )}
            </svg>
          </div>

          {/* Desktop Navigation */}
          <nav className="hidden xl:flex xl:items-center xl:flex-wrap gap-x-4 gap-y-2">
            {navItems.map((item, index) => (
              <div key={index} className="relative group min-w-max">
                {item.name === "Contact Us" ? (
                  <HashLink
                    smooth
                    to={item.link}
                    className="text-[#003366] hover:text-[#6A85B6] font-medium px-3 py-2 rounded-lg hover:bg-[#6A85B6] hover:bg-opacity-10 transition-all duration-300 hover:-translate-y-1"
                  >
                    {item.name}
                  </HashLink>
                ) : (
                  <Link
                    to={item.link}
                    className="text-[#003366] hover:text-[#6A85B6] font-medium px-3 py-2 rounded-lg hover:bg-[#6A85B6] hover:bg-opacity-10 transition-all duration-300 hover:-translate-y-1"
                  >
                    {item.name}
                  </Link>
                )}
                {item.dropdownItems.length > 0 && (
                  <div className="absolute left-0 mt-2 w-60 rounded-lg bg-white shadow-lg opacity-0 invisible group-hover:opacity-100 group-hover:visible transition-all duration-300 transform -translate-y-2 group-hover:translate-y-0">
                    <div className="py-2">
                      {item.dropdownItems.map((dropdownItem) => (
                        <Link
                          key={dropdownItem.name}
                          to={dropdownItem.link}
                          className="block px-4 py-3 text-sm text-[#4B2E2F] hover:bg-[#A67C52] hover:text-white transition-colors duration-300"
                        >
                          {dropdownItem.name}
                        </Link>
                      ))}
                    </div>
                  </div>
                )}
              </div>
            ))}
          </nav>

          {/* Right side buttons */}
          <div className="hidden xl:flex items-center space-x-6">
            <Link
              to="/contact-us"
              className="bg-[#A67C52] text-[#F8F1E9] px-3 py-2 rounded-lg font-medium hover:bg-[#4B2E2F] transition-all duration-300 hover:scale-105"
            >
              Login
            </Link>
            <Link
              to="/login"
              className="cursor-pointer text-3xl hover:scale-110 transition-transform duration-300"
            >
              👤
            </Link>
          </div>
        </div>

        {/* Mobile Navigation */}
        <div
          className={`xl:hidden transition-all duration-300 ease-in-out ${
            isNavOpen
              ? "max-h-[1000px] opacity-100 visible"
              : "max-h-0 opacity-0 invisible"
          }`}
        >
          <nav className="pt-2 pb-4 space-y-2">
            {navItems.map((item, index) => (
              <div key={index}>
                <div
                  className="flex justify-between items-center px-4 py-2 text-[#003366] hover:bg-[#6A85B6] hover:bg-opacity-10 rounded-lg transition-colors cursor-pointer"
                  onClick={() =>
                    setOpenDropdown(openDropdown === index ? null : index)
                  }
                >
                  {item.name === "Contact Us" ? (
                    <HashLink smooth to={item.link} className="flex-grow">
                      {item.name}
                    </HashLink>
                  ) : (
                    <Link to={item.link} className="flex-grow">
                      {item.name}
                    </Link>
                  )}
                  {item.dropdownItems.length > 0 && (
                    <svg
                      className={`h-5 w-5 transition-transform ${
                        openDropdown === index ? "rotate-180" : "rotate-0"
                      }`}
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      strokeWidth="2"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M19 9l-7 7-7-7"
                      />
                    </svg>
                  )}
                </div>
                {openDropdown === index && (
                  <div className="pl-6 mt-1 space-y-1">
                    {item.dropdownItems.map((dropdownItem) => (
                      <Link
                        key={dropdownItem.name}
                        to={dropdownItem.link}
                        className="block px-4 py-2 text-sm text-[#4B2E2F] hover:bg-[#A67C52] hover:text-white rounded-lg transition-colors"
                      >
                        {dropdownItem.name}
                      </Link>
                    ))}
                  </div>
                )}
              </div>
            ))}
          </nav>
          <div className="px-4 py-4 space-y-4">
            <Link
              to="/book-visit"
              className="block w-full text-center bg-[#A67C52] text-[#F8F1E9] px-6 py-3 rounded-lg font-medium hover:bg-[#4B2E2F] transition-colors"
            >
              Book a Visit
            </Link>
          </div>
        </div>
      </div>
    </header>
  );
}
