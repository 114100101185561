import React from "react";
import ContactUsForm from "../../components/ContactForm";

export default function PrivacyPolicy() {
  return (
    <div className="bg-primary-cream min-h-screen py-16 px-12">
      <div className="max-w-6xl mx-auto">
        <h2 className="text-5xl font-extrabold text-primary-brown mb-12 text-center">
          Privacy Policy
        </h2>
        <div className="text-gray-700 space-y-12">
          <section>
            <h3 className="text-3xl font-bold text-primary-brown mb-4">
              Who We Are
            </h3>
            <p className="text-lg leading-relaxed">
              MaheshSuchit Immigration Pvt Ltd, a Company registered under the
              Companies Act, 2013, having its registered office at M S House,
              Anand, Gujarat, 388001. We understand and respect the need to
              protect the privacy rights of our users. MSIPL is the controller
              of the personal data collected.
            </p>
          </section>

          <section>
            <h3 className="text-3xl font-bold text-primary-brown mb-4">
              Scope of Privacy Policy
            </h3>
            <p className="text-lg leading-relaxed">
              This Privacy Policy explains how the collected data is processed,
              protected, and shared. It applies to all users using the Company's
              website or platform.
            </p>
          </section>

          <section>
            <h3 className="text-3xl font-bold text-primary-brown mb-4">
              Personal Data Collected
            </h3>
            <ul className="list-disc list-inside text-lg leading-relaxed space-y-2">
              <li>Name, email, address, phone, gender, etc.</li>
              <li>Login credentials and passwords.</li>
              <li>Financial details for subscriptions.</li>
              <li>Social media data (if logged in via Facebook, etc.).</li>
              <li>Communication records and uploaded content.</li>
              <li>IP address, browser, and device information.</li>
            </ul>
          </section>

          <section>
            <h3 className="text-3xl font-bold text-primary-brown mb-4">
              How Your Data Is Used
            </h3>
            <ul className="list-disc list-inside text-lg leading-relaxed space-y-2">
              <li>Verification of identity during sign-in.</li>
              <li>Analyzing website activity and user statistics.</li>
              <li>Communicating with users for queries or support.</li>
              <li>
                Marketing and promotional communications (opt-out available).
              </li>
              <li>Secure transactions through partner payment gateways.</li>
            </ul>
          </section>

          <section>
            <h3 className="text-3xl font-bold text-primary-brown mb-4">
              Disclosure of Your Data
            </h3>
            <p className="text-lg leading-relaxed">
              Your data may be shared with affiliates, subsidiaries, legal
              authorities, and third-party service providers for secure
              transactions and website functionality. We do not share data for
              third-party marketing purposes.
            </p>
          </section>

          <section>
            <h3 className="text-3xl font-bold text-primary-brown mb-4">
              Cookies & Tracking
            </h3>
            <p className="text-lg leading-relaxed">
              We use cookies for analytics, website functionality, and
              advertising. Users can manage or opt-out of cookies via browser
              settings.
            </p>
          </section>

          <section>
            <h3 className="text-3xl font-bold text-primary-brown mb-4">
              Payment Information
            </h3>
            <p className="text-lg leading-relaxed">
              We use 256-bit SSL encryption for transactions. No direct banking
              information is stored on our website.
            </p>
          </section>

          <section>
            <h3 className="text-3xl font-bold text-primary-brown mb-4">
              User Rights
            </h3>
            <ul className="list-disc list-inside text-lg leading-relaxed space-y-2">
              <li>Access, review, correct, or delete data upon request.</li>
              <li>Withdraw consent (may limit access to services).</li>
            </ul>
          </section>

          <section>
            <h3 className="text-3xl font-bold text-primary-brown mb-4">
              Data Transfers & Retention
            </h3>
            <p className="text-lg leading-relaxed">
              Your data may be stored and processed in cloud servers outside
              your jurisdiction. We retain your data while your account is
              active; once terminated, data will no longer be maintained.
            </p>
          </section>

          <section>
            <h3 className="text-3xl font-bold text-primary-brown mb-4">
              Third-Party Service Providers
            </h3>
            <p className="text-lg leading-relaxed">
              We may use third-party service providers such as Google, Facebook,
              PayPal, Razorpay, and others for transactions, hosting, and
              analytics. They follow their own privacy policies regarding data
              usage.
            </p>
          </section>

          <section>
            <h3 className="text-3xl font-bold text-primary-brown mb-4">
              Legal Compliance & Updates
            </h3>
            <p className="text-lg leading-relaxed">
              We reserve the right to update this policy at any time. Any
              changes will be posted on this page. Your continued use of our
              services constitutes acceptance of the updated policy.
            </p>
          </section>
        </div>
      </div>

      <ContactUsForm />
    </div>
  );
}
