import React, { useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { Instagram, Facebook, Youtube, X } from "lucide-react";
import Updates from "../components/Home/Updates";

export default function ModernGalleryPage() {
  const [showPopup, setShowPopup] = useState(false);
  const [popupContent, setPopupContent] = useState("");

  const openPopup = (content) => {
    setPopupContent(content);
    setShowPopup(true);
  };

  const closePopup = () => {
    setShowPopup(false);
    setPopupContent("");
  };
  const importAll = (r) => r.keys().map(r);
  const images = importAll(require.context('../../public/gallery', false, /\.(png|jpe?g|svg)$/));
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
      className="min-h-screen bg-gradient-to-br from-gray-50 to-blue-100"
    >
      <motion.header
        initial={{ y: -50, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        transition={{ duration: 0.7 }}
        className="relative  bg-primary-brown text-white py-20 px-4 sm:px-6 lg:px-8"
      >
        <div className="max-w-7xl mx-auto">
          <motion.h1
            initial={{ x: -50, opacity: 0 }}
            animate={{ x: 0, opacity: 1 }}
            transition={{ delay: 0.2, duration: 0.5 }}
            className="text-5xl font-bold mb-4 text-white tracking-tight"
          >
            Our Journey in Pictures
          </motion.h1>
          <motion.p
            initial={{ x: -50, opacity: 0 }}
            animate={{ x: 0, opacity: 1 }}
            transition={{ delay: 0.3, duration: 0.5 }}
            className="text-xl mb-8 text-white/80"
          >
            A Pictorial Showcase of Our Story and Achievements.
          </motion.p>
         
        </div>
        <motion.svg
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          className="absolute bottom-0 left-0 w-full h-20 text-gray-50"
          viewBox="0 0 100 100"
          preserveAspectRatio="none"
        >
          <path d="M0,100 C40,0 60,0 100,100 Z" fill="currentColor" />
        </motion.svg>
      </motion.header>

    

      <motion.section
        initial={{ opacity: 0, y: 50 }}
        whileInView={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.6 }}
        viewport={{ once: true }}
        className="bg-gradient-to-r from-gray-100 to-blue-50 py-16 px-4 sm:px-6 lg:px-8"
      >
        <div className="max-w-7xl mx-auto">
          {/* <h2 className="text-4xl font-bold mb-12 text-center text-gray-800">
            Gallery
          </h2> */}
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
            {images.map((image,num) => (
              <motion.div
                key={num}
                initial={{ opacity: 0, scale: 0.9 }}
                whileInView={{ opacity: 1, scale: 1 }}
                whileHover={{ scale: 1.05 }}
                transition={{ duration: 0.3 }}
                viewport={{ once: true }}
                className="bg-white rounded-2xl shadow-lg overflow-hidden cursor-pointer"
                
              >
                <img
                key={num}
                 src={image}
                  alt={`Photo ${num}`}
                  className="w-full h-48 object-cover"
                />
                {/* <div className="p-4">
                  <p className="text-gray-600">Description of Photo {num}</p>
                </div> */}
              </motion.div>
            ))}
          </div>
        </div>
      </motion.section>

            <Updates/>
      {/* <motion.section
        initial={{ opacity: 0, y: 50 }}
        whileInView={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.6 }}
        viewport={{ once: true }}
        className="py-16 px-4 sm:px-6 lg:px-8"
      >
        <div className="max-w-7xl mx-auto">
          <h2 className="text-4xl font-bold mb-12 text-center text-gray-800">
            Latest Updates
          </h2>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            {["Blog Title 1", "Blog Title 2", "Blog Title 3"].map(
              (title, index) => (
                <motion.div
                  key={index}
                  initial={{ opacity: 0, scale: 0.9 }}
                  whileInView={{ opacity: 1, scale: 1 }}
                  whileHover={{ scale: 1.05 }}
                  transition={{ duration: 0.3 }}
                  viewport={{ once: true }}
                  className="bg-white rounded-2xl shadow-lg overflow-hidden cursor-pointer"
                  onClick={() => openPopup(`More content on ${title}`)}
                >
                  <div className="p-6">
                    <h3 className="text-xl font-semibold mb-2 text-gray-800">
                      {title}
                    </h3>
                    <p className="text-gray-600">
                      Short description of the blog...
                    </p>
                  </div>
                </motion.div>
              )
            )}
          </div>
        </div>
      </motion.section> */}
      <motion.section
        initial={{ opacity: 0, y: 50 }}
        whileInView={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.6 }}
        viewport={{ once: true }}
        className="py-16 px-4 sm:px-6 lg:px-8"
      >
        <div className="max-w-7xl mx-auto">
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            {[
              {
                name: "Instagram",
                icon: Instagram,
                color: "bg-gradient-to-r from-pink-500 to-orange-500",
                url: "https://www.instagram.com/m.s.education",
              },
              {
                name: "Facebook",
                icon: Facebook,
                color: "bg-gradient-to-r from-blue-600 to-blue-800",
                url: "https://www.facebook.com/mseducationconsultancy/",
              },
              {
                name: "YouTube",
                icon: Youtube,
                color: "bg-gradient-to-r from-red-600 to-red-800",
                url: "https://youtube.com/@m.s.educationconsultant?si=YtBaRy_CyLHe2VJG",
              },
            ].map((platform) => (
              <motion.div
                key={platform.name}
                initial={{ opacity: 0, scale: 0.9 }}
                whileInView={{ opacity: 1, scale: 1 }}
                whileHover={{ scale: 1.05 }}
                transition={{ duration: 0.3 }}
                viewport={{ once: true }}
                className="bg-white rounded-2xl shadow-lg overflow-hidden"
              >
                <div
                  className={`${platform.color} h-32 flex items-center justify-center`}
                >
                  <platform.icon className="w-16 h-16 text-white" />
                </div>
                <div className="p-6">
                  <h3 className="text-xl font-semibold mb-2">
                    {platform.name}
                  </h3>
                  <p className="text-gray-600 mb-4">
                    Follow us on {platform.name} for the latest updates and
                    content.
                  </p>
                  <motion.button
                    whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: 0.95 }}
                    className={`w-full py-3 px-4 rounded-full font-semibold text-white ${platform.color} hover:opacity-90 transition duration-300`}
                    onClick={() => window.open(`${platform.url.toLowerCase()}`, "_blank")}
                  >
                    Visit {platform.name}
                  </motion.button>
                </div>
              </motion.div>
            ))}
          </div>
        </div>
      </motion.section>

      <AnimatePresence>
        {showPopup && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50"
          >
            <motion.div
              initial={{ scale: 0.8, opacity: 0 }}
              animate={{ scale: 1, opacity: 1 }}
              exit={{ scale: 0.8, opacity: 0 }}
              className="bg-white rounded-2xl p-8 max-w-md w-full shadow-2xl"
            >
              <div className="flex justify-between items-center mb-4">
                <h3 className="text-xl font-semibold">Details</h3>
                <motion.button
                  whileHover={{ rotate: 90 }}
                  onClick={closePopup}
                  className="text-gray-500 hover:text-gray-700"
                >
                  <X className="w-6 h-6" />
                </motion.button>
              </div>
              <p>{popupContent}</p>
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>
    </motion.div>
  );
}
