import React from "react";
import { PartyPopper } from "lucide-react";
import GirlWithLaptop from "./assets/a.png";
import ConsultantImage from "./assets/Consulting-image-landing.jpeg";
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import whatsappIcon from "../../assets/icons8-whatsapp-48.png";
const countries = ["Canada", "Germany", "USA", "UK", "Australia", "Europe"];

export default function HeroSection() {
  return (
    <>
      <div className="bg-primary text-white py-2 px-4 flex items-center justify-center gap-2 w-full">
        <span className="font-medium">
          Experience personalized guidance and explore the opportunities that
          await you.
        </span>

        <PartyPopper size={16} className="text-white" />
        <HashLink to={"#contact"}>
          <button className="ml-2 min-w-[120px] px-4 py-1 border border-white rounded-md hover:bg-white hover:text-primary transition-colors duration-300">
            Book a Visit
          </button>
        </HashLink>
      </div>
      <section className="relative overflow-hidden bg-primary-cream py-20 sm:py-32">
        <div className="container mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex flex-col lg:flex-row items-center justify-between gap-12">
            <div className="max-w-2xl">
              <h1 className="text-4xl font-extrabold tracking-tight text-primary sm:text-5xl md:text-6xl">
                <span className="block">We are bringing</span>
                <span className="block text-black">World-Class Learning</span>
                <span className="block">within everyone's reach</span>
              </h1>
              <p className="mt-6 text-xl text-primary-dark">
                Discover global educational opportunities and embark on your
                journey to international academic success.
              </p>
              <div className="mt-10">
                <p className="text-lg font-semibold text-primary-brown mb-4">
                  Choose your destination:
                </p>
                <div className="flex flex-wrap gap-4">
                  {countries.map((country) => (
                    <Link
                      key={country}
                      to={`/country-overview/${country.toLowerCase()}`}
                    >
                      <button className="px-4 py-2 bg-white text-primary rounded-full shadow-md hover:bg-primary-sky hover:text-primary focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2 transition-all duration-300 ease-in-out">
                        {country}
                      </button>
                    </Link>
                  ))}
                </div>
              </div>
            </div>
            <div className="relative w-full max-w-lg lg:max-w-2xl xl:max-w-3xl">
              <div className="absolute top-0 -left-4 w-72 h-72 bg-primary-sky rounded-full mix-blend-multiply filter blur-xl opacity-70 animate-blob"></div>
              <div className="absolute top-0 -right-4 w-72 h-72 bg-primary-blue rounded-full mix-blend-multiply filter blur-xl opacity-70 animate-blob animation-delay-2000"></div>
              <div className="absolute -bottom-8 left-20 w-72 h-72 bg-primary-brown rounded-full mix-blend-multiply filter blur-xl opacity-70 animate-blob animation-delay-4000"></div>
              <div className="relative">
                <img
                  src={GirlWithLaptop}
                  alt="Girl with laptop"
                  width={600}
                  height={600}
                  className="rounded-lg"
                />
              </div>
              {/* <div className="lg:ml-40 relative w-[400px] h-[400px] overflow-hidden rounded-lg">
                <img
                  src={ConsultantImage}
                  alt="Girl with laptop"
                  className="w-full h-full object-cover"
                />
              </div> */}
            </div>
          </div>
        </div>
      </section>
      <WhatsAppCTA />
    </>
  );
}

function WhatsAppCTA() {
  return (
    <a
      href="https://wa.me/919825772794"
      target="_blank"
      rel="noopener noreferrer"
      className="fixed left-10 max-md:left-5 bottom-10 transform bg-green-500 text-white p-3 rounded-lg shadow-lg hover:bg-green-600 transition-all duration-300 ease-in-out group z-50"
      aria-label="Contact us on WhatsApp"
    >
      <div className="flex items-center">
        <img src={whatsappIcon} className="h-8 mr-2" alt="WhatsApp" />
        <span className="inline-block whitespace-nowrap overflow-hidden transition-all duration-300 ease-in-out">
          Chat with us
        </span>
      </div>
    </a>
  );
}
