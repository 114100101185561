import {
  BookOpen,
  Trophy,
  Globe,
  Clock,
  Calendar,
  Sun,
  Briefcase,
  Code,
  Heart,
  Leaf,
  Cog,
  LineChart,
  BarChart,
  Snowflake,
  Gavel,
  Palette,
  Coins,
} from "lucide-react";

export const CountryData = [
  {
    name: "canada",
    imageURL:
      "https://images.unsplash.com/photo-1530025809667-1f4bcff8e60f?q=80&w=2891&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    tagline:
      "Explore breathtaking landscapes, vibrant cities, and rich culture",
    infoSection: {
      title: "Why Choose Canada?",
      tiles: [
        {
          title: "High-Quality Education:",
          description:
            "Internationally recognized degrees with varied course options.",
        },
        {
          title: "Multicultural Environment:",
          description: "A welcoming and diverse community.",
        },
        {
          title: "Work Opportunities:",
          description:
            "Work while studying (20 hours/week) and post-study work permits.",
        },
        {
          title: "Pathway to PR:",
          description:
            "Opportunities to settle in Canada with programs like Express Entry and Provincial Nominee Programs.",
        },
        {
          title: "Safe and Friendly:",
          description: "Known for its high quality of life and safety.",
        },
      ],
    },
    programs: [
      {
        id: 1,
        name: "Environmental Science",
        icon: Globe,
        description: "Focus on sustainability and conservation.",
      },
      {
        id: 2,
        name: "Engineering",
        icon: BookOpen,
        description:
          "Top-notch programs in civil, mechanical, and software engineering.",
      },
      {
        id: 3,
        name: "Healthcare",
        icon: Trophy,
        description: "Cutting-edge research and clinical training.",
      },
    ],
    intakes: [
      {
        id: 1,
        season: "Summer",
        icon: Sun,
        description: "May start, limited programs, specialized courses",
        details: {
          applicationPeriod: "October - February",
          startDate: "May",
          availability: "Limited courses",
        },
      },
      {
        id: 2,
        season: "Winter",
        icon: Clock,
        description: "January intake, alternative enrollment option",
        details: {
          applicationPeriod: "June - September",
          startDate: "January",
          availability: "Moderate program selection",
        },
      },
      {
        id: 3,
        season: "Fall",
        icon: Calendar,
        description: "Primary intake with most program options",
        details: {
          applicationPeriod: "November - March",
          startDate: "September",
          availability: "Most comprehensive program range",
        },
      },
    ],
    visa: [
      {
        description: "SDS Visa Processing Time",
        details: {
          processingTime: "20 working days",
          fees: {
            visaApplication: "CA$ 150",
            biometrics: "CA$ 85",
          },
        },
      },
      {
        description: "Non-SDS Visa Processing Time",
        details: {
          processingTime: "60 working days",
          fees: {
            visaApplication: "CA$ 150",
            biometrics: "CA$ 85",
          },
        },
      },
    ],
  },
  {
    name: "USA",
    imageURL:
      "https://images.unsplash.com/photo-1493246507139-91e8fad9978e?q=80&w=2891&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    tagline: "Pursue world-class education in the land of opportunity",
    infoSection: {
      title: "Why Choose the USA?",
      tiles: [
        {
          title: "World-Class Universities:",
          description:
            "Home to globally top-ranked institutions and diverse academic programs.",
        },
        {
          title: "Research Opportunities:",
          description:
            "Cutting-edge innovation with extensive funding options.",
        },
        {
          title: "Cultural Diversity:",
          description:
            "A melting pot of cultures fostering global connections.",
        },
        {
          title: "Flexible Education System:",
          description:
            "Options to switch majors and tailor programs to career goals.",
        },
        {
          title: "Career Prospects:",
          description:
            "Thriving industries and internships for skill development.",
        },
      ],
    },
    programs: [
      {
        id: 1,
        name: "Computer Science",
        icon: Code,
        description: "Focus on AI, cybersecurity, and software development.",
      },
      {
        id: 2,
        name: "Business Administration",
        icon: Briefcase,
        description:
          "World-renowned MBA programs and entrepreneurial development.",
      },
      {
        id: 3,
        name: "Medicine",
        icon: Heart,
        description:
          "Exceptional medical schools offering clinical and research expertise.",
      },
    ],
    intakes: [
      {
        id: 1,
        season: "Summer",
        icon: Sun,
        description: "Specialized programs and internships",
        details: {
          applicationPeriod: "November - February",
          startDate: "May",
          availability: "Limited program selection",
        },
      },
      {
        id: 2,
        season: "Fall",
        icon: Calendar,
        description: "Main intake with comprehensive program offerings",
        details: {
          applicationPeriod: "August - December",
          startDate: "August/September",
          availability: "Wide range of courses available",
        },
      },
      {
        id: 3,
        season: "Spring",
        icon: Leaf,
        description: "Secondary intake for alternate program entry",
        details: {
          applicationPeriod: "June - October",
          startDate: "January",
          availability: "Good range of courses",
        },
      },
    ],
    visa: [
      {
        description: "SDS Visa Processing Time",
        details: {
          processingTime: "20 working days",
          fees: {
            visaApplication: "CA$ 150",
            biometrics: "CA$ 85",
          },
        },
      },
      {
        description: "Non-SDS Visa Processing Time",
        details: {
          processingTime: "60 working days",
          fees: {
            visaApplication: "CA$ 150",
            biometrics: "CA$ 85",
          },
        },
      },
    ],
  },
  {
    name: "GERMANY",
    imageURL:
      "https://images.unsplash.com/photo-1473615695634-d284ec918736?q=80&w=3028&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    tagline: "Experience world-class education in the heart of Europe",
    infoSection: {
      title: "Why Choose Germany?",
      tiles: [
        {
          title: "Tuition-Free Education:",
          description:
            "Public universities offer free or minimal tuition fees for most programs.",
        },
        {
          title: "World-Class Institutions:",
          description:
            "Renowned for engineering, technology, and research excellence.",
        },
        {
          title: "Cultural and Historical Richness:",
          description: "A vibrant culture and heritage spanning centuries.",
        },
        {
          title: "Affordable Living Costs:",
          description:
            "Reasonable living expenses compared to other European countries.",
        },
        {
          title: "Post-Graduation Opportunities:",
          description:
            "Access to thriving industries and a pathway to permanent residency.",
        },
      ],
    },
    programs: [
      {
        id: 1,
        name: "Engineering",
        icon: Cog,
        description:
          "Globally recognized for mechanical and automotive expertise.",
      },
      {
        id: 2,
        name: "Data Science",
        icon: LineChart,
        description:
          "Cutting-edge analytics, AI, and machine learning programs.",
      },
      {
        id: 3,
        name: "Business and Management",
        icon: BarChart,
        description:
          "Highly reputed MBA and management programs with global opportunities.",
      },
    ],
    intakes: [
      {
        id: 1,
        season: "Summer",
        icon: Sun,
        description:
          "Limited programs available, especially specialized courses",
        details: {
          applicationPeriod: "November - January",
          startDate: "April",
          availability: "Selective programs",
        },
      },
      {
        id: 2,
        season: "Winter",
        icon: Snowflake,
        description: "Main intake for most universities and courses",
        details: {
          applicationPeriod: "June - August",
          startDate: "October",
          availability: "Extensive program offerings",
        },
      },
    ],
    visa: [
      {
        description: "SDS Visa Processing Time",
        details: {
          processingTime: "20 working days",
          fees: {
            visaApplication: "CA$ 150",
            biometrics: "CA$ 85",
          },
        },
      },
      {
        description: "Non-SDS Visa Processing Time",
        details: {
          processingTime: "60 working days",
          fees: {
            visaApplication: "CA$ 150",
            biometrics: "CA$ 85",
          },
        },
      },
    ],
  },
  {
    name: "UK",
    imageURL:
      "https://images.unsplash.com/photo-1505740420928-5e560c06d30e?q=80&w=2891&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    tagline:
      "Achieve academic excellence in one of the world's oldest education hubs",
    infoSection: {
      title: "Why Choose the UK?",
      tiles: [
        {
          title: "Globally Recognized Degrees:",
          description:
            "Highly respected qualifications from top-ranked universities.",
        },
        {
          title: "Shorter Duration Courses:",
          description:
            "Fast-track programs (3 years for a bachelor's, 1 year for a master's).",
        },
        {
          title: "Diverse Cultural Experience:",
          description: "A vibrant mix of history, culture, and modernity.",
        },
        {
          title: "Scholarship Opportunities:",
          description: "Wide range of scholarships and financial aid options.",
        },
        {
          title: "Post-Study Work Visa:",
          description:
            "Graduate Route visa allowing work opportunities after studies.",
        },
      ],
    },
    programs: [
      {
        id: 1,
        name: "Law",
        icon: Gavel,
        description:
          "World-renowned programs focusing on international and corporate law.",
      },
      {
        id: 2,
        name: "Creative Arts",
        icon: Palette,
        description:
          "Top institutions for fashion, design, and performing arts.",
      },
      {
        id: 3,
        name: "Finance and Economics",
        icon: Coins,
        description:
          "Highly reputed programs in finance, accounting, and economics.",
      },
    ],
    intakes: [
      {
        id: 1,
        season: "Fall",
        icon: Calendar,
        description: "Main intake with the widest range of programs",
        details: {
          applicationPeriod: "September - January",
          startDate: "September",
          availability: "Most comprehensive program selection",
        },
      },
      {
        id: 2,
        season: "Spring",
        icon: Leaf,
        description: "Alternative intake for selected programs",
        details: {
          applicationPeriod: "June - October",
          startDate: "January",
          availability: "Selective program options",
        },
      },
    ],
    visa: [
      {
        description: "SDS Visa Processing Time",
        details: {
          processingTime: "20 working days",
          fees: {
            visaApplication: "CA$ 150",
            biometrics: "CA$ 85",
          },
        },
      },
      {
        description: "Non-SDS Visa Processing Time",
        details: {
          processingTime: "60 working days",
          fees: {
            visaApplication: "CA$ 150",
            biometrics: "CA$ 85",
          },
        },
      },
    ],
  },
  {
    name: "EUROPE",
    imageURL:
      "https://images.unsplash.com/photo-1507668077129-56e32842fceb?q=80&w=2891&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    tagline:
      "Experience diverse cultures and world-class education across Europe",
    infoSection: {
      title: "Why Choose European Countries?",
      tiles: [
        {
          title: "Affordable or Free Tuition:",
          description:
            "Countries like Germany, Norway, and Finland offer free or low-cost education at public universities, with affordable options in others.",
        },
        {
          title: "Globally Recognized Degrees:",
          description:
            "European universities offer high-ranking, internationally respected degrees in various fields.",
        },
        {
          title: "Rich Cultural Diversity:",
          description:
            "Explore unique histories, art, and traditions across Europe while studying.",
        },
        {
          title: "Wide Range of Programs in English:",
          description:
            "Study in English with specialized courses in business, technology, humanities, and more.",
        },
        {
          title: "Post-Study Work Opportunities:",
          description:
            "Many countries provide work permits for graduates, such as Germany's 18-month permit and France's 12-month option.",
        },
      ],
      visa: [
        {
          description: "SDS Visa Processing Time",
          details: {
            processingTime: "20 working days",
            fees: {
              visaApplication: "CA$ 150",
              biometrics: "CA$ 85",
            },
          },
        },
        {
          description: "Non-SDS Visa Processing Time",
          details: {
            processingTime: "60 working days",
            fees: {
              visaApplication: "CA$ 150",
              biometrics: "CA$ 85",
            },
          },
        },
      ],
    },
    programs: [
      {
        id: 1,
        name: "Business and Management",
        icon: Briefcase,
        description:
          "Leading programs in entrepreneurship and global business strategies.",
      },
      {
        id: 2,
        name: "Engineering and Technology",
        icon: Cog,
        description:
          "Pioneering research and applications in renewable energy, AI, and mechanical engineering.",
      },
      {
        id: 3,
        name: "Arts and Humanities",
        icon: BookOpen,
        description:
          "Creative and cultural studies focusing on history, languages, and the arts.",
      },
    ],
    intakes: [
      {
        id: 1,
        season: "Fall",
        icon: Calendar,
        description:
          "Primary intake with the widest range of program availability.",
        details: {
          applicationPeriod: "February - May (varies by country)",
          startDate: "September",
          availability: "Comprehensive program options.",
        },
      },
      {
        id: 2,
        season: "Spring",
        icon: Leaf,
        description:
          "Secondary intake available in select countries and universities.",
        details: {
          applicationPeriod: "September - November (varies by country)",
          startDate: "January",
          availability: "Limited program options.",
        },
      },
    ],
    visa: [
      {
        description: "SDS Visa Processing Time",
        details: {
          processingTime: "20 working days",
          fees: {
            visaApplication: "CA$ 150",
            biometrics: "CA$ 85",
          },
        },
      },
      {
        description: "Non-SDS Visa Processing Time",
        details: {
          processingTime: "60 working days",
          fees: {
            visaApplication: "CA$ 150",
            biometrics: "CA$ 85",
          },
        },
      },
    ],
  },
  {
    name: "AUSTRALIA",
    imageURL:
      "https://images.unsplash.com/photo-1523482580672-f109ba8cb9be?q=80&w=3030&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    tagline:
      "Discover top universities, vibrant cities, and a relaxed lifestyle.",
    infoSection: {
      title: "Why Choose Australia?",
      tiles: [
        {
          title: "World-Class Education:",
          description:
            "Home to top-ranked universities like ANU, University of Melbourne, and University of Sydney.",
        },
        {
          title: "Post-Study Work Opportunities:",
          description:
            "Generous post-study work visa options, including 2-4 years depending on your degree.",
        },
        {
          title: "Diverse and Multicultural:",
          description:
            "A welcoming environment with students from over 190 countries.",
        },
        {
          title: "Work While Studying:",
          description:
            "Earn while you learn, with permission to work 20 hours per week during semesters and unlimited hours during breaks.",
        },
        {
          title: "Outdoor Adventures and Lifestyle:",
          description:
            "Explore beaches, rainforests, and unique wildlife alongside vibrant city life.",
        },
      ],
    },
    programs: [
      {
        id: 1,
        name: "Business and Management",
        icon: Briefcase,
        description: "Study in globally recognized business schools.",
      },
      {
        id: 2,
        name: "Engineering and IT",
        icon: Cog,
        description:
          "Specialized programs in civil, mechanical, software, and IT engineering.",
      },
      {
        id: 3,
        name: "Health Sciences",
        icon: Heart,
        description:
          "Cutting-edge training in healthcare, medicine, and biotechnology.",
      },
    ],
    intakes: [
      {
        id: 1,
        season: "February",
        icon: Calendar,
        description: "Primary intake with the most program options.",
        details: {
          applicationPeriod: "July - November",
          startDate: "February",
          availability: "Most comprehensive range of programs.",
        },
      },
      {
        id: 2,
        season: "July",
        icon: Sun,
        description: "Secondary intake with a wide range of choices.",
        details: {
          applicationPeriod: "November - March",
          startDate: "July",
          availability: "Moderate program selection.",
        },
      },
      {
        id: 3,
        season: "November",
        icon: Leaf,
        description: "Limited intake, available for selected courses.",
        details: {
          applicationPeriod: "May - August",
          startDate: "November",
          availability: "Specialized programs only.",
        },
      },
    ],
    visa: [
      {
        description: "SDS Visa Processing Time",
        details: {
          processingTime: "20 working days",
          fees: {
            visaApplication: "CA$ 150",
            biometrics: "CA$ 85",
          },
        },
      },
      {
        description: "Non-SDS Visa Processing Time",
        details: {
          processingTime: "60 working days",
          fees: {
            visaApplication: "CA$ 150",
            biometrics: "CA$ 85",
          },
        },
      },
    ],
  },
];
