import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { EventData } from "./EventData.js"; // Import the event details from the file
import EventImage from "../../assets/event-image1.jpeg";

export default function EventsPage() {
  const [searchQuery, setSearchQuery] = useState("");
  const [events, setEvents] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    // Format events from the EventData array
    const formatEvents = () => {
      console.log(EventData, "events");
      const formattedEvents = EventData.map((item, index) => {
        const eventData = item.event;
        // Use the first location as default
        const firstLocation = eventData.locations[0];

        return {
          id: index + 1,
          title: eventData.name,
          image: EventImage, // Placeholder image since no image is provided
          name: eventData.presented_by,
          date: formatDateRange(eventData.locations), // Format date range from all locations
          location: formatLocations(eventData.locations), // Format all locations as a string
          description: eventData.about,
          eventDetails: eventData, // Store the full event details for later use
        };
      });

      setEvents(formattedEvents);
    };

    formatEvents();
  }, []);

  // Helper function to format dates from all locations into a range
  const formatDateRange = (locations) => {
    if (!locations || locations.length === 0) return "Date TBA";

    // Sort locations by date
    const sortedLocations = [...locations].sort(
      (a, b) => new Date(a.date) - new Date(b.date)
    );

    const firstDate = new Date(sortedLocations[0].date);
    const lastDate = new Date(sortedLocations[sortedLocations.length - 1].date);

    // Format dates
    const options = { month: "short", day: "numeric", year: "numeric" };

    if (firstDate.getTime() === lastDate.getTime()) {
      return firstDate.toLocaleDateString("en-US", options);
    }

    return `${firstDate.toLocaleDateString(
      "en-US",
      options
    )} - ${lastDate.toLocaleDateString("en-US", options)}`;
  };

  // Helper function to format locations as a string
  const formatLocations = (locations) => {
    if (!locations || locations.length === 0) return "Location TBA";

    if (locations.length === 1) {
      return locations[0].city;
    }

    const cityNames = locations.map((loc) => loc.city);
    return cityNames.join(", ");
  };

  const filteredEvents = events.filter(
    (event) =>
      // event.title.toLowerCase().includes(searchQuery.toLowerCase()) ||
      event.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
      event.description.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <div className="min-h-screen bg-gray-50">
      <div className="relative bg-gradient-to-r from-blue-800 to-indigo-900 py-16 md:py-24">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 relative z-10">
          <div className="text-center">
            <h1 className="text-4xl md:text-5xl font-extrabold text-white mb-6">
              Upcoming Immigration Events
            </h1>
            <p className="text-xl text-blue-100 max-w-3xl mx-auto">
              Join our informative sessions to learn about immigration pathways,
              study options, and career opportunities abroad.
            </p>

            {/* Search Box */}
            {/* <div className="mt-8 max-w-xl mx-auto">
              <div className="relative rounded-md shadow-sm">
                <input
                  type="text"
                  className="form-input block w-full pl-4 pr-12 py-3 rounded-lg text-gray-900"
                  placeholder="Search events by title, speaker, or keyword..."
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                />
                <div className="absolute inset-y-0 right-0 pr-3 flex items-center">
                  <svg
                    className="h-5 w-5 text-gray-400"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                  >
                    <path
                      fillRule="evenodd"
                      d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                      clipRule="evenodd"
                    />
                  </svg>
                </div>
              </div>
            </div> */}
          </div>
        </div>

        <div className="absolute top-0 left-0 w-full h-full overflow-hidden opacity-10">
          <div className="absolute top-0 right-0 transform translate-x-1/2 -translate-y-1/4 w-96 h-96 bg-white rounded-full"></div>
          <div className="absolute bottom-0 left-0 transform -translate-x-1/3 translate-y-1/4 w-80 h-80 bg-white rounded-full"></div>
        </div>
      </div>

      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12 md:py-16">
        {filteredEvents.length > 0 ? (
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
            {filteredEvents.map((event) => (
              <EventCard key={event.id} event={event} navigate={navigate} />
            ))}
          </div>
        ) : (
          <div className="text-center py-12">
            <h3 className="text-xl font-medium text-gray-900">
              No events found matching your search.
            </h3>
            <p className="mt-2 text-gray-600">
              Try adjusting your search terms or browse all our events.
            </p>
            <button
              className="mt-4 px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 transition"
              onClick={() => setSearchQuery("")}
            >
              View All Events
            </button>
          </div>
        )}
      </div>

      <div className="bg-blue-50 py-12 md:py-16">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <h2 className="text-3xl font-bold text-center text-gray-900 mb-12">
            About Our Events
          </h2>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            <div className="bg-white p-6 rounded-lg shadow-md">
              <div className="w-12 h-12 bg-blue-100 rounded-full flex items-center justify-center mb-4">
                <svg
                  className="w-6 h-6 text-blue-600"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M12 6v6m0 0v6m0-6h6m-6 0H6"
                  ></path>
                </svg>
              </div>
              <h3 className="text-xl font-semibold mb-2">
                Expert-Led Sessions
              </h3>
              <p className="text-gray-600">
                All our events are conducted by education specialists with years
                of experience in the field.
              </p>
            </div>

            <div className="bg-white p-6 rounded-lg shadow-md">
              <div className="w-12 h-12 bg-blue-100 rounded-full flex items-center justify-center mb-4">
                <svg
                  className="w-6 h-6 text-blue-600"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"
                  ></path>
                </svg>
              </div>
              <h3 className="text-xl font-semibold mb-2">Regular Updates</h3>
              <p className="text-gray-600">
                Stay informed about the latest education and immigration
                opportunities through our frequent events.
              </p>
            </div>

            <div className="bg-white p-6 rounded-lg shadow-md">
              <div className="w-12 h-12 bg-blue-100 rounded-full flex items-center justify-center mb-4">
                <svg
                  className="w-6 h-6 text-blue-600"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z"
                  ></path>
                </svg>
              </div>
              <h3 className="text-xl font-semibold mb-2">
                Personalized Guidance
              </h3>
              <p className="text-gray-600">
                Get your specific questions answered during dedicated counseling
                sessions at each event.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function EventCard({ event, navigate }) {
  return (
    <div
      className="bg-white rounded-xl shadow-md overflow-hidden transition-all duration-300 hover:shadow-xl transform hover:-translate-y-1 cursor-pointer"
      onClick={() => navigate(`/event-details/${event.id}`)}
    >
      <div className="relative h-48 bg-gray-200 overflow-hidden">
        <div className="absolute inset-0 bg-gradient-to-t from-black/60 to-transparent z-10"></div>
        <img
          src={event.image || "/api/placeholder/400/300"}
          alt={event.title}
          className="w-full h-full object-cover"
        />
        <div className="absolute bottom-0 left-0 right-0 p-4 z-20">
          <span className="inline-block bg-blue-600 px-3 py-1 text-sm font-semibold rounded-full text-white">
            {event.date}
          </span>
        </div>
      </div>

      <div className="p-6">
        <h3 className="text-xl font-bold text-gray-900 mb-2 line-clamp-2">
          {event.title}
        </h3>

        <div className="flex items-center mb-4">
          <div className="h-8 w-8 flex-shrink-0 rounded-full bg-gray-300 flex items-center justify-center mr-2">
            <svg
              className="h-4 w-4 text-gray-600"
              fill="currentColor"
              viewBox="0 0 20 20"
            >
              <path
                fillRule="evenodd"
                d="M10 9a3 3 0 100-6 3 3 0 000 6zm-7 9a7 7 0 1114 0H3z"
                clipRule="evenodd"
              />
            </svg>
          </div>
          <span className="text-gray-700 line-clamp-1">{event.name}</span>
        </div>

        <div className="flex items-center text-gray-600 mb-3">
          <svg
            className="h-5 w-5 mr-1"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"
            />
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M15 11a3 3 0 11-6 0 3 3 0 016 0z"
            />
          </svg>
          <span className="text-sm">{event.location}</span>
        </div>

        <p className="text-gray-600 mb-4 line-clamp-2">{event.description}</p>

        <button className="w-full py-2 px-4 border border-blue-600 rounded-md font-medium text-blue-600 hover:bg-blue-50 transition focus:outline-none">
          View Details
        </button>
      </div>
    </div>
  );
}
