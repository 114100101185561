export const EventData = [
  {
    event: {
      name: "Educational Expo 2K25",
      presented_by:
        "M.S Education Consultants (A division of MaheshSuchit Immigration Pvt. Ltd.)",
      locations: [
        { city: "Surat", date: "2025-04-10" },
        { city: "Anand", date: "2025-04-12" },
        { city: "Ahmedabad", date: "2025-04-13", venue: "To Be Announced" },
      ],
      timings: "10:00 AM – 6:00 PM",
      entry_fee: "FREE",
      about:
        "M.S Education Consultants proudly presents its 10th Educational Expo, where students can explore global education opportunities. With a strong record of 50k+ students sent abroad, this event connects aspiring students with top universities, scholarship opportunities, and expert guidance.",
      highlights: {
        counseling:
          "1:1 Counselling – Interact directly with university representatives",
        scholarships:
          "High Scholarships – Explore financial aid & merit-based scholarships",
        lucky_draw_benefits: [
          "Free IELTS, PTE, & Language CERT Vouchers",
          "Free TOEFL Study Material",
          "Assured Goodies for All Attendees",
          "50% Off on all services by M.S Education",
        ],
        vip_services:
          "Fast VVIP Services – Priority application processing for online registrants who submit required documents",
      },
      participating_universities: "To Be Announced Soon",
      registration: {
        link: "[Registration Page Link]",
        essential_fields: {
          personal_information: {
            full_name: "Required",
            email: "Required",
            mobile: "Required (WhatsApp preferred)",
            city_state: "Optional",
          },
          academic_details: {
            current_education_level: [
              "High School",
              "Undergraduate",
              "Graduate",
              "Other",
            ],
            interested_program: ["Bachelors", "Masters", "Diploma", "PhD"],
            preferred_study_destination: [
              "Canada",
              "UK",
              "USA",
              "Australia",
              "Germany",
            ],
            intended_intake: ["Fall 2025", "Spring 2026"],
          },
          event_preferences: {
            preferred_location: ["Surat", "Anand", "Ahmedabad"],
            one_on_one_counseling: "Yes/No",
            scholarship_consultation: "Yes/No",
          },
          document_upload: {
            academic_documents: "Optional (PDF/Images)",
            passport: "Optional (For priority processing)",
          },
          additional_information: {
            referral_source: [
              "Social Media",
              "Friend",
              "Website",
              "Advertisement",
              "Other",
            ],
            specific_questions: "Text Area",
          },
          terms_confirmation: {
            agreement:
              "I confirm that the details provided are accurate and agree to be contacted for updates",
            submit_button: "Register Now",
          },
        },
        enhancements: {
          auto_confirmation_email: "QR Code for Fast Check-in",
          whatsapp_reminder: "Automatic message one day before the event",
          google_calendar_invite: "Add event details to attendees’ calendars",
        },
      },
      contact_details: {
        email: "[Your Email]",
        phone: "[Your Phone Number]",
        website: "[Your Website Link]",
        social_media: {
          facebook: "[Facebook]",
          instagram: "[Instagram]",
          linkedin: "[LinkedIn]",
        },
      },
    },
  },
];
