import React, { useState, useEffect } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { Plus, Menu, X } from "lucide-react";
import { Navigate } from "react-router-dom";
import axios from "axios";
import { constants } from "../constants";
import Sidebar from "../components/Admin/Sidebar";
import BlogList from "../components/Admin/BlogList";
import AddBlogForm from "../components/Admin/AddBlogForm";
import BlogPost from "../components/Admin/BlogPost";
import EditBlogForm from "../components/Admin/EditBlogForm";

const Admin = () => {
  // Always call hooks first
  const [blogs, setBlogs] = useState([]);
  const [isAddingBlog, setIsAddingBlog] = useState(false);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [selectedBlog, setSelectedBlog] = useState(null);
  const [isEditingBlog, setIsEditingBlog] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  // Fetch blogs when component mounts
  useEffect(() => {
    const fetchBlogs = async () => {
      try {
        const token = localStorage.getItem("adminToken");
        if (!token) {
          return <Navigate to="/admin-login" replace />;
        }

        const response = await axios.get(
          `${constants.serverURL}/api/blogs/listBlogs`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        // Ensure response.data is an array
        setBlogs(Array.isArray(response.data.blogs) ? response.data.blogs : []);
        setIsLoading(false);
      } catch (err) {
        setError(err.response?.data?.message || "Failed to fetch blogs");
        setIsLoading(false);
        // Set blogs to an empty array in case of error
        setBlogs([]);
      }
    };

    fetchBlogs();
  }, [selectedBlog]);

  // Update blog function
  const updateBlog = async (updatedBlog) => {
    try {
      const token = localStorage.getItem("adminToken");
      const response = await axios.put(
        `${constants.serverURL}/api/blogs/updateBlog/${updatedBlog._id}`,
        updatedBlog,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );

      // Update blogs list with the updated blog
      setBlogs(
        blogs.map((blog) =>
          blog._id === updatedBlog._id ? response.data : blog
        )
      );

      setSelectedBlog(null);
      setIsEditingBlog(false);
    } catch (err) {
      console.error("Failed to update blog:", err);
      alert(err.response?.data?.message || "Failed to update blog");
    }
  };

  // Delete blog function
  const deleteBlog = async (blogId) => {
    console.log(blogId, "deleteblog");
    try {
      const token = localStorage.getItem("adminToken");

      const response = await axios.delete(
        `${constants.serverURL}/api/blogs/deleteBlog/${blogId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 200) {
        setBlogs((prevBlogs) =>
          prevBlogs.filter((blog) => blog._id !== blogId)
        );
        setSelectedBlog(null);
      }
    } catch (error) {
      console.error("Error deleting blog:", error);

      if (error.response && error.response.status === 404) {
        setBlogs((prevBlogs) =>
          prevBlogs.filter((blog) => blog._id !== blogId)
        );
        setSelectedBlog(null);
      } else {
        alert("Failed to delete blog. Please try again.");
      }
    }
  };

  // Handle blog click to view details
  const handleBlogClick = (blog) => {
    setSelectedBlog(blog);
    setIsEditingBlog(false);
  };

  // Handle back to blog list
  const handleBackToBlogList = () => {
    setSelectedBlog(null);
    setIsEditingBlog(false);
  };

  // If loading, show a loading state
  if (isLoading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-blue-500"></div>
      </div>
    );
  }

  // If there's an error, show error message
  if (error) {
    return (
      <div className="flex justify-center items-center h-screen">
        <div className="text-red-500 text-xl">{error}</div>
      </div>
    );
  }

  return (
    <div className="flex h-full bg-gray-100">
      <Sidebar isOpen={isSidebarOpen} onClose={() => setIsSidebarOpen(false)} />
      <div className="flex-1 flex flex-col overflow-hidden">
        <header className="flex justify-between items-center p-4 bg-white shadow-md">
          <motion.button
            onClick={() => setIsSidebarOpen(!isSidebarOpen)}
            className="p-2 rounded-md hover:bg-gray-200"
          >
            {isSidebarOpen ? <X size={24} /> : <Menu size={24} />}
          </motion.button>
          <h1 className="text-2xl font-bold text-gray-800">
            Updates Dashboard
          </h1>
          {!selectedBlog && !isEditingBlog && (
            <motion.button
              whileTap={{ scale: 0.95 }}
              onClick={() => setIsAddingBlog(true)}
              className="flex items-center gap-2 bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600 transition-colors duration-200"
            >
              <Plus size={20} />
              Add Updates
            </motion.button>
          )}
        </header>
        <main className="flex-1 h-auto bg-gray-100 p-6">
          <AnimatePresence mode="wait">
            {isAddingBlog && (
              <AddBlogForm
                key="add-blog-form"
                addBlog={(newBlog) => {
                  console.log("Adding blog:", newBlog);
                  setBlogs([newBlog, ...blogs]);
                  setIsAddingBlog(false);
                }}
                cancel={() => setIsAddingBlog(false)}
              />
            )}
            {!isAddingBlog && selectedBlog && !isEditingBlog && (
              <BlogPost
                key="blog-post"
                blog={selectedBlog}
                onBack={handleBackToBlogList}
                onEdit={() => setIsEditingBlog(true)}
                onDelete={() => deleteBlog(selectedBlog._id)}
              />
            )}
            {isEditingBlog && selectedBlog && (
              <EditBlogForm
                key="edit-blog-form"
                blog={selectedBlog}
                onUpdateBlog={updateBlog}
                onCancel={() => setIsEditingBlog(false)}
              />
            )}
            {!isAddingBlog && !selectedBlog && !isEditingBlog && (
              <BlogList blogs={blogs} onBlogClick={handleBlogClick} />
            )}
          </AnimatePresence>
        </main>
      </div>
    </div>
  );
};

export default Admin;
