import React, { useEffect, useState } from "react";
import Herosection from "../components/CountryOverview/HeroSection";
import InfoSection from "../components/CountryOverview/InfoSection";
import { CountryData } from "./StudyAbroad/CountryData";
import Stats from "../components/CountryOverview/Stats";
import AdmissionRequirements from "../components/CountryOverview/AdmissionRequirements";
import PopularPrograms from "../components/CountryOverview/PopularPrograms";
import IntakesSection from "../components/CountryOverview/IntakesSection";
import FaqSection from "../components/CountryOverview/FaqSection";
import YoutubeSection from "../components/YouTubeSection";
import BlogSection from "../components/BlogSection";
import ContactUsForm from "../components/ContactForm";
import Updates from "../components/Home/Updates";
import Visa from "../components/CountryOverview/Visa";

export default function CountryOverview({ country }) {
  const [selectedCountry, setSelectedCountry] = useState("");

  useEffect(() => {
    let selectedCountry = CountryData.find(
      (item) => item.name.toLowerCase() == country.toLowerCase()
    );
    setSelectedCountry(selectedCountry);
  }, [country]);
  return (
    <>
      <Herosection countryData={selectedCountry} />
      <InfoSection infoData={selectedCountry.infoSection} />
      <Stats />
      <AdmissionRequirements />
      <PopularPrograms programs={selectedCountry.programs} />
      <IntakesSection
        intakes={selectedCountry.intakes}
        countryname={selectedCountry.name}
      />
      {/* <Visa visa={selectedCountry.visa} countryname={selectedCountry.name} /> */}
      <YoutubeSection />
      {/* <BlogSection /> */}
      <Updates />
      <ContactUsForm />
      <FaqSection />
    </>
  );
}
