import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import hiringImage from "./assets/hiring.png";
import news2 from "./assets/news2.png";
import { constants } from "../../constants";
import { X, ChevronLeft, ChevronRight } from "lucide-react";
import { Link } from "react-router-dom";

export default function Updates() {
  const [showPopup, setShowPopup] = useState(false);
  const [popupContent, setPopupContent] = useState("");
  const [blogs, setBlogs] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const scrollContainerRef = useRef(null);

  useEffect(() => {
    const fetchBlogs = async () => {
      try {
        const response = await axios.get(
          `${constants.serverURL}/api/blogs/listBlogs`
        );
        setBlogs(response.data.blogs || []);
        setIsLoading(false);
      } catch (err) {
        console.error("Failed to fetch blogs:", err);
        setError(err.message);
        setIsLoading(false);
      }
    };

    fetchBlogs();
  }, []);

  const handleButtonClick = (content) => {
    setPopupContent(content);
    setShowPopup(true);
  };

  const closePopup = () => {
    setShowPopup(false);
    setPopupContent("");
  };

  const scrollLeft = () => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollBy({
        left: -400,
        behavior: "smooth",
      });
    }
  };

  const scrollRight = () => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollBy({
        left: 400,
        behavior: "smooth",
      });
    }
  };

  return (
    <section className="py-16 px-4 bg-primary-accent/10 relative">
      <div className="container mx-auto max-w-screen-xl">
        <h2 className="text-3xl md:text-4xl font-bold text-primary mb-10 text-center">
          Latest Updates
        </h2>

        <div className="relative group">
          {/* Scroll Left Button */}
          <button
            onClick={scrollLeft}
            className="absolute left-0 top-1/2 -translate-y-1/2 z-10 
              bg-black/50 rounded-full p-2 
              shadow-md opacity-0 group-hover:opacity-100 
              transition-all duration-300 ease-in-out"
          >
            <ChevronLeft className="text-white" size={24} />
          </button>

          {/* Scroll Right Button */}
          <button
            onClick={scrollRight}
            className="absolute right-0 top-1/2 -translate-y-1/2 z-10 
              bg-black/50 rounded-full p-2 
              shadow-md opacity-0 group-hover:opacity-100 
              transition-all duration-300 ease-in-out"
          >
            <ChevronRight className="text-white" size={24} />
          </button>

          {/* Scrollable Container */}
          <div
            ref={scrollContainerRef}
            className="flex overflow-x-scroll space-x-6 pb-4 
              scrollbar-hide scroll-smooth 
              no-scrollbar whitespace-nowrap 
              flex-nowrap"
          >
            {/* We're Hiring Card - Always First */}
            <div className="flex-shrink-0 w-96 min-h-[300px] max-h-[400px] flex flex-col inline-block">
              <div className="bg-white rounded-xl shadow-lg overflow-hidden transition-all duration-300 hover:-translate-y-2 hover:shadow-xl flex flex-col flex-grow">
                <div className="relative h-48 bg-primary-sky/20 object-cover overflow-hidden flex justify-center">
                  <img src={hiringImage} alt="Hiring" className="h-full" />
                </div>
                <div className="p-6 flex flex-col flex-grow">
                  <h3 className="text-2xl font-semibold mb-3 text-primary">
                    We're Hiring!
                  </h3>
                  <div className="w-full">
                    <p className="text-primary-dark mb-6 line-clamp-3 w-full break-words">
                      Join the fastest growing EdTech brand offering endless
                      learning and growth opportunities with us.
                    </p>
                  </div>
                  <button
                    onClick={() =>
                      window.open(
                        "https://forms.gle/2aJy2MgttfAzYhWaA",
                        "_blank"
                      )
                    }
                    className="w-full bg-primary-brown text-white px-6 py-3 rounded-lg font-medium hover:bg-primary-accent transition-colors focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2"
                  >
                    Apply Now
                  </button>
                </div>
              </div>
            </div>

            {/* Dynamically Render Blogs */}
            {isLoading ? (
              <div className="flex justify-center items-center">
                <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-blue-500"></div>
              </div>
            ) : error ? (
              <div></div>
            ) : (
              blogs.map((blog) => (
                <div
                  key={blog._id}
                  className="flex-shrink-0 w-96 min-h-[300px] max-h-[400px] flex flex-col"
                >
                  <div className="bg-white rounded-xl shadow-lg overflow-hidden transition-all duration-300 hover:-translate-y-2 hover:shadow-xl flex flex-col flex-grow">
                    {blog.imgUrl && (
                      <div className="relative h-48 bg-primary-sky/20 object-cover overflow-hidden flex justify-center">
                        <img
                          src={`${constants.serverURL}${blog.imgUrl}`}
                          alt={blog.title}
                          className="h-full w-full object-cover"
                        />
                      </div>
                    )}
                    {!blog.imgUrl && (
                      <div className="relative h-48 bg-primary-sky/20 object-cover overflow-hidden flex justify-center">
                        <img src={news2} alt="Hiring" className="h-full" />
                      </div>
                    )}
                    <div className="p-6 flex flex-col flex-grow">
                      <h3 className="text-2xl font-semibold mb-3 text-primary overflow-ellipsis overflow-clip">
                        {blog.title}
                      </h3>
                      <div className="w-full">
                        <p
                          className="text-primary-dark mb-6 line-clamp-3 w-full break-words"
                          dangerouslySetInnerHTML={{ __html: blog.content }}
                        >
                          {/* {blog.content} */}
                        </p>
                      </div>

                      {/* <button
                        onClick={(e) => {
                          e.stopPropagation();
                          window.open(blog.actionURL, "_blank");
                        }}
                        className="w-full bg-primary-brown text-white px-6 py-3 rounded-lg font-medium hover:bg-primary-accent transition-colors focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2"
                      >
                        Read More
                      </button> */}
                      <Link to={`/blog/${blog._id}`}>
                        <button className="w-full bg-primary-brown text-white px-6 py-3 rounded-lg font-medium hover:bg-primary-accent transition-colors focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2">
                          Read More
                        </button>
                      </Link>
                    </div>
                  </div>
                </div>
              ))
            )}
          </div>
        </div>

        {/* Popup Modal */}
        {showPopup && (
          <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
            <div className="bg-white p-8 rounded-xl max-w-md relative">
              <button
                onClick={closePopup}
                className="absolute top-4 right-4 text-gray-600 hover:text-gray-900"
              >
                <X size={24} />
              </button>
              <p className="text-primary-dark">{popupContent}</p>
            </div>
          </div>
        )}
      </div>
    </section>
  );
}
