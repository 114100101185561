import React, { useEffect } from "react";

const InfoSection = ({ infoData }) => {
  useEffect(() => {
    console.log(infoData);
  });
  if (!infoData) return;

  return (
    <section className="bg-primary-cream to-indigo-100 text-cream py-12 px-5 rounded-xl text-center font-poppins animate-fadeIn">
      <h2 className="text-4xl font-bold relative inline-block mb-8">
        {infoData.title}
        <span className="block w-20 h-1 bg-cream rounded mx-auto mt-4"></span>
      </h2>

      <div className="flex flex-wrap justify-center gap-6 max-w-5xl mx-auto">
        {infoData?.tiles.map((item, index) => (
          <div
            key={index}
            className="bg-primary-gray hover:bg-brown-light p-6 rounded-lg shadow-lg transform transition-transform duration-300 hover:-translate-y-2 flex items-start max-w-sm"
          >
            <p className="text-left">
              <span className="font-bold text-lg text-blue-900 bg-brown-light text-cream py-1 rounded text-shadow-md inline-block mb-2">
                {item.title}
              </span>{" "}
              {item.description}
            </p>
          </div>
        ))}
      </div>
      <div className="relative w-full max-w-lg lg:max-w-2xl xl:max-w-3xl">
      <div className="absolute top-0 -left-4 w-72 h-72 bg-primary-sky rounded-full mix-blend-multiply filter blur-xl opacity-70 animate-blob"></div>
              <div className="absolute top-0 -right-4 w-72 h-72 bg-primary-blue rounded-full mix-blend-multiply filter blur-xl opacity-70 animate-blob animation-delay-2000"></div>
              <div className="absolute -bottom-8 left-20 w-72 h-72 bg-primary-brown rounded-full mix-blend-multiply filter blur-xl opacity-70 animate-blob animation-delay-4000"></div>
      </div>
    </section>
  );
};

export default InfoSection;
