import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Lock, User, ShieldAlert } from 'lucide-react';
import {constants} from '../constants';


const AdminLogin = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const navigate = useNavigate();
  const { serverURL } = constants;

  const handleLogin = async (e) => {
    e.preventDefault();
    setError('');

    try {
      const response = await axios.post(`${serverURL}/api/auth/adminLogin`, {
        email,
        password
      });

      if (response.data.token) {
        localStorage.setItem('adminToken', response.data.token);
        navigate('/admin');
      }
    } catch (err) {
      setError(err.response?.data?.message || 'Login failed');
    }
  };

  return (
    <div className="min-h-screen bg-primary-cream flex items-center justify-center px-4 sm:px-6 lg:px-8">
      <div className="max-w-md w-full bg-white shadow-2xl rounded-2xl overflow-hidden">
        <div className="bg-primary-brown text-white p-6 text-center">
          <h2 className="text-3xl font-bold flex items-center justify-center gap-3">
            {/* <ShieldAlert className="w-10 h-10" /> */}
            Admin Portal
          </h2>
        </div>
        <form className="p-8 space-y-6" onSubmit={handleLogin}>
          <div className="space-y-4">
            <div className="relative">
              <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                <User className="h-5 w-5 text-primary-blue" />
              </div>
              <input
                id="email"
                name="email"
                type="text"
                required
                className="pl-10 block w-full px-4 py-3 border border-primary-sky rounded-lg focus:ring-2 focus:ring-primary-accent focus:outline-none"
                placeholder="Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <div className="relative">
              <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                <Lock className="h-5 w-5 text-primary-blue" />
              </div>
              <input
                id="password"
                name="password"
                type={isPasswordVisible ? "text" : "password"}
                required
                className="pl-10 block w-full px-4 py-3 border border-primary-sky rounded-lg focus:ring-2 focus:ring-primary-accent focus:outline-none"
                placeholder="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              <button
                type="button"
                onClick={() => setIsPasswordVisible(!isPasswordVisible)}
                className="absolute inset-y-0 right-0 pr-3 flex items-center text-primary-blue"
              >
                {isPasswordVisible ? "Hide" : "Show"}
              </button>
            </div>
          </div>

          {error && (
            <div className="bg-red-50 border border-red-300 text-red-800 px-4 py-3 rounded-lg text-center">
              <p className="flex items-center justify-center gap-2">
                <ShieldAlert className="h-5 w-5" />
                {error}
              </p>
            </div>
          )}

          <div>
            <button
              type="submit"
              className="w-full py-3 px-4 bg-primary-brown text-white rounded-lg hover:bg-primary font-semibold transition duration-300 ease-in-out transform hover:scale-[1.02] focus:outline-none focus:ring-2 focus:ring-primary-blue"
            >
              Sign In
            </button>
          </div>

          <div className="text-center text-sm text-primary-brown">
            <a href="#" className="hover:underline">Forgot Password?</a>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AdminLogin;