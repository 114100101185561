import React, { useRef, useState, useEffect } from 'react';
import BlogSection from '../components/BlogSection';

const VisaPage = () => {
  const carouselRef = useRef(null);
  const [hoveredVisa, setHoveredVisa] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(0);

  const visaInfo = {
    Dependent: { 
      title: "Dependent Visa", 
      img: "https://img.freepik.com/free-vector/happy-family-earth-planet-background_1308-119204.jpg?ga=GA1.1.1196561968.1741008529&semt=ais_hybrid", 
      description: "For family members of primary visa holders", 
      details: ["Spouse", "Children", "Parents"] 
    },
    Visitor: { 
      title: "Visitor Visa", 
      img: "https://img.freepik.com/premium-vector/beard-man-traveler-camera-cap-park-urban_18591-11426.jpg?ga=GA1.1.1196561968.1741008529&semt=ais_hybrid", 
      description: "For tourism and short-term visits", 
      details: ["Tourism", "Family Visit", "Business Meetings"] 
    },
    Student: { 
      title: "Student Visa", 
      img: "https://img.freepik.com/premium-vector/back-school-boy-gray-shirt-student-landscape-background_18591-10115.jpg?ga=GA1.1.1196561968.1741008529&semt=ais_hybrid", 
      description: "For international education", 
      details: ["University", "College", "Language Courses"] 
    }
  };

  const countryVisaDetails = [
    {
      name: "Australia",
      href: "./visa/australia",
      src: "https://th.bing.com/th/id/OIP.cVsPVJDNJ_9qBOQTvDIslwHaDt?w=310&h=180&c=7&r=0&o=5&pid=1.7",
      alt: "Australia",
      details: [
        { label: "Visa Types", value: "Student (Subclass 500), Visitor, Work, PR" },
        { label: "Processing Time", value: "4-12 weeks" },
        { label: "Financial Requirement", value: "Proof of AU$24,505/year" },
        { label: "Work Rights", value: "24 hrs/week for students" },
        { label: "Post-Study Options", value: "2-4 year post-study work visa" },
        { label: "Application Fee", value: "AU$710 for student visa" }
      ]
    },
    {
      name: "Canada",
      href: "./visa/canada",
      src: "https://th.bing.com/th/id/OIP.Y1TpMsKNjnZt1Jm4qWA7-QHaE6?rs=1&pid=ImgDetMain",
      alt: "Canada",
      details: [
        { label: "Visa Types", value: "Student, Visitor, Work, PR" },
        { label: "Processing Time", value: "4-6 weeks (varies)" },
        { label: "Financial Requirement", value: "Proof of funds required" },
        { label: "Work Rights", value: "Students can work 20 hrs/week" },
        { label: "Post-Study Options", value: "PGWP available" },
        { label: "Health Insurance", value: "Mandatory in most provinces" }
      ]
    },
    {
      name: "Dubai",
      href: "./visa/dubai",
      src: "https://th.bing.com/th/id/OIP.kRec---LlXfNnlr1C5SEewAAAA?rs=1&pid=ImgDetMain",
      alt: "Dubai",
      details: [
        { label: "Visa Types", value: "Student, Tourist, Work, Golden Visa" },
        { label: "Processing Time", value: "2-6 weeks" },
        { label: "Financial Requirement", value: "Varies by visa type" },
        { label: "Work Rights", value: "Limited for students" },
        { label: "Post-Study Options", value: "Job-seeker & work visa options" },
        { label: "Health Insurance", value: "Mandatory for long-term stay" }
      ]
    },
    {
      name: "Germany",
      href: "./visa/Germany",
      src: "https://th.bing.com/th/id/OIP.sLjA9RB7rZ6sxJh-eQGJRQHaE8?w=290&h=193&c=7&r=0&o=5&pid=1.7",
      alt: "Germany",
      details: [
        { label: "Visa Types", value: "Student, Job Seeker, Work, Visitor" },
        { label: "Processing Time", value: "6-12 weeks" },
        { label: "Financial Requirement", value: "€11,208 in a blocked account" },
        { label: "Work Rights", value: "120 full days or 240 half days per year" },
        { label: "Post-Study Options", value: "18-month job-seeking visa" },
        { label: "Health Insurance", value: "Mandatory for students" }
      ]
    },
    {
      name: "NewZealand",
      href: "./visa/NewZealand",
      src: "https://th.bing.com/th/id/OIP.JFQibq0p4gBGzcjGnIqyQAHaE6?w=263&h=180&c=7&r=0&o=5&pid=1.7",
      alt: "NewZealand",
      details: [
        { label: "Visa Types", value: "Student, Visitor, Work, PR" },
        { label: "Processing Time", value: "4-8 weeks" },
        { label: "Financial Requirement", value: "NZ$20,000/year for living" },
        { label: "Work Rights", value: "20 hrs/week during study" },
        { label: "Post-Study Options", value: "1-3 years post-study work visa" },
        { label: "Part-time Work Pay", value: "Avg. NZ$22/hour" }
      ]
    },
    {
      name: "UK",
      href: "./visa/uk",
      src: "https://th.bing.com/th/id/OIP.kzWXsIqTVYfbxQSLjIF20AHaE6?w=260&h=180&c=7&r=0&o=5&pid=1.7",
      alt: "UK",
      details: [
        { label: "Visa Types", value: "Student (Tier 4), Visitor, Skilled Worker" },
        { label: "Processing Time", value: "3-8 weeks" },
        { label: "Financial Requirement", value: "Proof of £1,334/month (London)" },
        { label: "Work Rights", value: "20 hrs/week for students" },
        { label: "Post-Study Options", value: "2-year Graduate Route Visa" },
        { label: "Application Fee", value: "£490 for a student visa" }
      ]
    },
    {
      name: "USA",
      href: "./visa/usa",
      src: "https://th.bing.com/th/id/OIP.7YXDeX4tdLuVfo3oSg8_1QHaFO?w=223&h=180&c=7&r=0&o=5&pid=1.7",
      alt: "USA",
      details: [
        { label: "Visa Types", value: "F1 (Student), B1/B2 (Visitor), H1B (Work)" },
        { label: "Processing Time", value: "3-5 weeks (varies)" },
        { label: "Financial Requirement", value: "Proof of tuition & living expenses" },
        { label: "Work Rights", value: "Limited on-campus jobs for students" },
        { label: "Post-Study Options", value: "OPT & H1B visa options" },
        { label: "Visa Validity", value: "Typically for study duration + 60 days grace" }
      ]
    }
  ];

  const handleCarouselPrev = () => {
    setCurrentIndex((prev) => (prev === 0 ? countryVisaDetails.length - 1 : prev - 1));
  };

  const handleCarouselNext = () => {
    setCurrentIndex((prev) => (prev === countryVisaDetails.length - 1 ? 0 : prev + 1));
  };

  useEffect(() => {
    const carouselInterval = setInterval(() => {
    //   handleCarouselNext();
    }, 5000);
    return () => clearInterval(carouselInterval);
  }, []);

  const CountryVisaCarousel = () => {
    const country = countryVisaDetails[currentIndex];

    return (
      <div className="carousel-container py-16 px-6 bg-gradient-to-r from-gray-50 to-gray-100">
        <div style={{ maxWidth: '1100px', margin: '0 auto', position: 'relative' }}>
          <button
            onClick={handleCarouselPrev}
            style={{
              position: 'absolute',
              left: '-20px',  // Reduced from -50px to bring closer to the image
              top: '50%',
              transform: 'translateY(-50%)',
              fontSize: '18px',  // Reduced from 28px for a smaller arrow
              background: 'linear-gradient(135deg, #003366, #0056b3)',
              color: '#fff',
              border: 'none',
              borderRadius: '50%',
              width: '30px',  // Reduced from 50px for a smaller button
              height: '30px',  // Reduced from 50px for a smaller button
              cursor: 'pointer',
              boxShadow: '0 4px 12px rgba(0, 0, 0, 0.2)',
              transition: 'all 0.3s ease',
              zIndex: 10
            }}
            onMouseOver={(e) => (e.currentTarget.style.background = 'linear-gradient(135deg, #002244, #003d80)')}
            onMouseOut={(e) => (e.currentTarget.style.background = 'linear-gradient(135deg, #003366, #0056b3)')}
          >
            ‹
          </button>

          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              transition: 'all 0.5s ease',
              perspective: '1000px'
            }}
          >
            <div
              className="carousel-card max-sm:flex-col"
              style={{
                width: '100%',
                maxWidth: '900px',
                background: '#fff',
                borderRadius: '20px',
                boxShadow: '0 8px 24px rgba(0, 0, 0, 0.15)',
                overflow: 'hidden',
                display: 'flex',
                animation: 'slideIn 0.5s ease-in-out',
                cursor: 'pointer'
              }}
            >
              <div style={{ flex: '0 0 40%', position: 'relative' }}>
                <img
                  src={country.src}
                  alt={`${country.name} Flag`}
                  style={{
                    width: '100%',
                    height: '100%',
                    objectFit: 'cover',
                    borderRadius: '20px 0 0 20px',
                    transition: 'transform 0.3s ease'
                  }}
                />
                <div
                  style={{
                    position: 'absolute',
                    bottom: '20px',
                    left: '20px',
                    background: 'rgba(0, 51, 102, 0.8)',
                    color: '#fff',
                    padding: '8px 16px',
                    borderRadius: '12px',
                    fontSize: '18px',
                    fontWeight: 'bold'
                  }}
                >
                  {country.name}
                </div>
              </div>

              <div
                style={{
                  flex: 1,
                  padding: '25px',
                  background: '#fafafa',
                  borderRadius: '0 20px 20px 0'
                }}
              >
                <h2
                  style={{
                    color: '#003366',
                    fontSize: '28px',
                    fontWeight: '700',
                    marginBottom: '15px',
                    animation: 'fadeIn 0.5s ease-in'
                  }}
                >
                  Visa Details
                </h2>
                <ul style={{ listStyle: 'none', padding: 0 }}>
                  {country.details.map((item, index) => (
                    <li
                      key={index}
                      style={{
                        padding: '10px 0',
                        borderBottom: index < country.details.length - 1 ? '1px solid #e0e0e0' : 'none',
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        transition: 'background 0.2s ease'
                      }}
                      onMouseOver={(e) => (e.currentTarget.style.background = '#f0f4f8')}
                      onMouseOut={(e) => (e.currentTarget.style.background = 'transparent')}
                    >
                      <strong style={{ color: '#A67C52', fontSize: '16px' }}>{item.label}:</strong>
                      <span style={{ color: '#333', fontSize: '15px', textAlign: 'right' }}>{item.value}</span>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>

          <button
            onClick={handleCarouselNext}
            style={{
              position: 'absolute',
              right: '-20px',  // Reduced from -50px to bring closer to the image
              top: '50%',
              transform: 'translateY(-50%)',
              fontSize: '18px',  // Reduced from 28px for a smaller arrow
              background: 'linear-gradient(135deg, #003366, #0056b3)',
              color: '#fff',
              border: 'none',
              borderRadius: '50%',
              width: '30px',  // Reduced from 50px for a smaller button
              height: '30px',  // Reduced from 50px for a smaller button
              cursor: 'pointer',
              boxShadow: '0 4px 12px rgba(0, 0, 0, 0.2)',
              transition: 'all 0.3s ease',
              zIndex: 10
            }}
            onMouseOver={(e) => (e.currentTarget.style.background = 'linear-gradient(135deg, #002244, #003d80)')}
            onMouseOut={(e) => (e.currentTarget.style.background = 'linear-gradient(135deg, #003366, #0056b3)')}
          >
            ›
          </button>
        </div>
      </div>
    );
  };

  return (
    <div className="min-h-screen bg-gray-100">
      <style>
        {`
          @keyframes slideIn {
            from {
              opacity: 0;
              transform: translateX(100px);
            }
            to {
              opacity: 1;
              transform: translateX(0);
            }
          }

          @keyframes rotateImage {
            from {
              transform: rotate(0deg);
            }
            to {
              transform: rotate(360deg);
            }
          }

          @keyframes fadeIn {
            from {
              opacity: 0;
            }
            to {
              opacity: 1;
            }
          }

          .visa-types img:hover {
            animation: rotateImage 1s linear infinite;
          }
        `}
      </style>

      <div
        className="relative bg-cover bg-center h-96"
        style={{
          backgroundImage:
            "url('https://media.istockphoto.com/id/972481804/photo/canadian-work-permit-paper-document-and-passport-top-view.jpg?s=612x612&w=0&k=20&c=qF6n5MddsrMUIjt-aDd1g00GO-gBIrtaxn-Jbom2FJ8=')",
        }}
      >
        <div className="absolute inset-0 bg-black bg-opacity-50"></div>
        <div className="relative z-10 flex flex-col items-center justify-center h-full text-white">
          <h1 className="text-5xl font-bold mb-4 text-white">Visa</h1>
          <p className="text-xl">
           <center> Embark on an exciting journey to explore new cultures and gain a world-class education!</center>
          </p>
        </div>
      </div>

      <section>
        <CountryVisaCarousel />
      </section>

      <section className="visa-types py-12 px-4">
        <div className="mb-12">
          <h2 className="text-3xl font-bold mb-6 text-center text-blue-800" style={{ animation: 'fadeIn 1s ease-in' }}>
            Types of Visa
          </h2>
        </div>
        <ul className="flex justify-center gap-12 flex-wrap relative">
          {Object.keys(visaInfo).map((visaType) => (
            <li
              key={visaType}
              className="relative flex flex-col items-center"
              onMouseEnter={() => setHoveredVisa(visaType)}
              onMouseLeave={() => setHoveredVisa(null)}
            >
              <img
                src={visaInfo[visaType].img}
                alt={visaInfo[visaType].title}
                className="w-24 h-24 rounded-full object-cover mb-2 cursor-pointer shadow-md transition-all duration-300"
              />
              <span className="text-lg font-semibold text-gray-700 cursor-pointer hover:text-blue-600">
                {visaType}
              </span>
              {hoveredVisa === visaType && (
                <div 
                  className="absolute z-10 -top-24 left-1/2 transform -translate-x-1/2 w-64 bg-white rounded-lg shadow-lg p-4"
                  style={{ animation: 'fadeIn 0.3s ease-in' }}
                >
                  <div className="absolute -bottom-2 left-1/2 transform -translate-x-1/2 w-0 h-0 border-l-8 border-r-8 border-t-8 border-l-transparent border-r-transparent border-t-white"></div>
                  <p className="text-sm text-gray-600 mb-2">{visaInfo[visaType].description}</p>
                </div>
              )}
            </li>
          ))}
        </ul>
      </section>

      {/* <FaqSection/> */}
      <BlogSection />
    </div>
  );
};

export default VisaPage;