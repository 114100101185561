"use client";

import React, { useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { IoMdClose } from "react-icons/io";

const blogs = [
  {
    id: 1,
    title: "The Future of AI in Web Development",
    image:
      "https://plus.unsplash.com/premium_photo-1683121710572-7723bd2e235d?q=80&w=2832&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    content:
      "Artificial Intelligence is revolutionizing the way we approach web development. From intelligent code completion to automated testing, AI is enhancing developer productivity and pushing the boundaries of what's possible on the web...",
  },
  {
    id: 2,
    title: "Mastering Responsive Design Techniques",
    image:
      "https://plus.unsplash.com/premium_photo-1681666713677-8bd559bef6bb?q=80&w=2940&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    content:
      "In today's multi-device world, responsive design is more crucial than ever. This blog explores advanced techniques for creating fluid layouts, optimizing images, and ensuring a seamless user experience across all screen sizes...",
  },
  {
    id: 3,
    title: "The Rise of JAMstack Architecture",
    image:
      "https://images.unsplash.com/photo-1607799279861-4dd421887fb3?q=80&w=2940&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    content:
      "JAMstack (JavaScript, APIs, and Markup) is gaining popularity for its ability to deliver fast, secure, and scalable websites. Learn how this modern web development architecture is changing the game for developers and businesses alike...",
  },
];

const BlogSection = () => {
  const [selectedBlog, setSelectedBlog] = useState(null);

  const openModal = (blog) => {
    setSelectedBlog(blog);
  };

  const closeModal = () => {
    setSelectedBlog(null);
  };

  return (
    <section className="py-12 bg-gray-100">
      <div className="container mx-auto px-4">
        <h2 className="text-3xl font-bold text-center text-gray-800 mb-8">
          Our Blogs
        </h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {blogs.map((blog) => (
            <motion.div
              key={blog.id}
              className="bg-white rounded-lg shadow-md overflow-hidden cursor-pointer transition-transform duration-300 hover:scale-105"
              onClick={() => openModal(blog)}
              whileHover={{ y: -5 }}
            >
              <img
                src={blog.image}
                alt={blog.title}
                className="w-full h-48 object-cover"
              />
              <div className="p-4">
                <h3 className="text-xl font-semibold text-gray-800 mb-2">
                  {blog.title}
                </h3>
                <p className="text-gray-600 line-clamp-3">{blog.content}</p>
              </div>
            </motion.div>
          ))}
        </div>

        <AnimatePresence>
          {selectedBlog && (
            <motion.div
              className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              onClick={closeModal}
            >
              <motion.div
                className="bg-white rounded-lg max-w-2xl w-full max-h-[90vh] overflow-y-auto"
                onClick={(e) => e.stopPropagation()}
                initial={{ y: 50, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                exit={{ y: 50, opacity: 0 }}
              >
                <div className="p-6">
                  <div className="flex justify-between items-center mb-4">
                    <h3 className="text-2xl font-bold text-gray-800">
                      {selectedBlog.title}
                    </h3>
                    <button
                      className="text-gray-500 hover:text-gray-700 transition-colors"
                      onClick={closeModal}
                      aria-label="Close modal"
                    >
                      <IoMdClose size={24} />
                    </button>
                  </div>
                  <img
                    src={selectedBlog.image}
                    alt={selectedBlog.title}
                    className="w-full h-64 object-cover rounded-lg mb-4"
                  />
                  <p className="text-gray-600">{selectedBlog.content}</p>
                </div>
              </motion.div>
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    </section>
  );
};

export default BlogSection;
