import React, { useState } from "react";

const branches = [
  {
    city: "Anand (H.O)",
    contactPerson: "Nishita Patel",
    phone: ["9510142218", "02692-247680"],
  },
  {
    city: "Ahmedabad (Navrangpura)",
    contactPerson: "Shivangi Bhatt",
    phone: ["9998763151"],
  },
  {
    city: "Ahmedabad (Maninagar)",
    contactPerson: "Kunjal Vyas",
    phone: ["9328280969"],
  },
  {
    city: "Surat",
    contactPerson: "Janki Joshi",
    phone: ["9723724499"],
  },
  {
    city: "Vadodara",
    contactPerson: "Dimpal Trivedi",
    phone: ["9023892825"],
  },
  {
    city: "Navsari",
    contactPerson: "Riya Mistry",
    phone: ["9227084714"],
  },
  {
    city: "Jamnagar",
    contactPerson: "Counsellor",
    phone: ["9825280598"],
  },
  // Add other branches here...
];

export default function ContactPage() {
  const [selectedOffice, setSelectedOffice] = useState("");

  const filteredBranches = selectedOffice
    ? branches.filter((branch) =>
        branch.city.toLowerCase().includes(selectedOffice.toLowerCase())
      )
    : branches;

  return (
    <div className="bg-light-gray">
      {/* Banner Section */}
      <header className="bg-primary text-white py-16 px-6">
        <div className="container mx-auto text-center">
          <h1 className="text-4xl font-bold">Contact Us</h1>
          <p className="mt-4 text-lg">
          Book your FREE consultation with Certified Counsellors
          </p>
        </div>
      </header>

      {/* Offices Section */}
      <section className="py-10">
        <div className="container mx-auto px-4">
          <div className="flex justify-between items-center mb-6">
            <h2 className="text-2xl font-semibold">Offices in India</h2>
            <select
              value={selectedOffice}
              onChange={(e) => setSelectedOffice(e.target.value)}
              className="border border-gray-300 rounded-md p-2"
            >
              <option value="">Select Office</option>
              {branches.map((branch, index) => (
                <option key={index} value={branch.city}>
                  {branch.city}
                </option>
              ))}
            </select>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
            {filteredBranches.map((branch, index) => (
              <div
                key={index}
                className="bg-white shadow-md p-4 rounded-lg border border-gray-200 hover:shadow-lg transition-shadow"
              >
                <h3 className="text-lg font-bold mb-2">{branch.city}</h3>
                <p className="text-sm font-medium text-gray-600 mb-1">
                  {branch.contactPerson}
                </p>
                {branch.phone.map((num, i) => (
                  <p key={i} className="text-sm text-gray-800">
                    📞 {num}
                  </p>
                ))}
                {/* <button className="mt-4 px-4 py-2 text-sm text-primary border border-primary rounded-full hover:bg-primary hover:text-white transition">
                  View Details →
                </button> */}
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* Footer Section */}
      <footer className="bg-gray-100 py-10">
        <div className="container mx-auto px-4 flex flex-col md:flex-row justify-between items-start">
          <div className="mb-6 md:mb-0">
            <h4 className="text-xl font-semibold mb-4">Head Office</h4>
            <p className="text-sm text-gray-800 mb-2">
              M.S House, Nr. Panchal Hall, A.V. Road,
              Anand, Gujarat, India.
            </p>
            <p className="text-sm text-gray-800 mb-2">9510142218 | 02692-247680</p>
            <p className="text-sm text-gray-800">
              Monday to Saturday: 9:30 AM to 6:30 PM, Sunday: Closed
            </p>
          </div>

          <div className="w-full md:w-1/2 h-64">
  <iframe
    title="M.S. Education Consultant Location"
    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3684.6756107980245!2d72.94428057512604!3d22.553821279505087!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x395e4e8087d820e1%3A0x13676ea149398be1!2sM.S.%20Education%20Consultant!5e0!3m2!1sen!2sin!4v1734085901401!5m2!1sen!2sin"
    className="w-full h-full border-0"
    allowFullScreen
    loading="lazy"
    referrerPolicy="no-referrer-when-downgrade"
  ></iframe>
</div>

        </div>
      </footer>
    </div>
  );
}