import React from "react";
import { FaFacebook, FaTwitter, FaLinkedin, FaInstagram } from "react-icons/fa";
import { Link } from "react-router-dom";

const Footer = () => {
  const branches = [
    {
      name: "Anand (H.O)",
      address: "M.S. House, Nr. Panchal Hall, A.V. Road, Anand, Gujarat 388001",
    },
    { name: "Borsad", address: "2nd Floor, Shiv Icon, Anand Chokdi, Borsad" },
    {
      name: "Vadodara",
      address:
        "1st Floor, Ramkrishna Chambers, Productivity Road, Alkapuri, Vadodara",
    },
    {
      name: "Ahmedabad- Navrangpura",
      address:
        "601/602, Northview Complex, Opp. L.D. College, Navrangpura, Ahmedabad.",
    },
    {
      name: "Ahmedabad- Maninagar",
      address: "302 Sigma Esquire, Opp. Kankaria Lake, Maninagar, Ahmedabad.",
    },
    {
      name: "Navsari",
      address:
        "2nd Floor, Bhagwandas, above IDFC Bank, Nr. Manavmandir Apartment, Lunsikui, Navsari.",
    },
    {
      name: "Surat",
      address:
        "905, International Finance Center, Nr. Vesu Fire Station, New VIP Road, Vesu, Surat.",
    },
    { name: "Nadiad", address: "308-309, Nexus-1, Uttarsanda Road, Nadiad" },
    {
      name: "Jamnagar",
      address: "D-18, S.P. Mehta Market, Nr. Grain Market, Jamnagar",
    },
  ];

  return (
    <footer className="bg-primary-accent text-white text-center py-10">
      <div className="container mx-auto px-6">
        {/* Contact Details */}
        <h2 className="text-3xl font-bold mb-4">Contact Us</h2>
        <p className="text-lg">
          <span className="font-bold">Email:</span>{" "}
          <a
            href="mailto:anand@mseconsultant.com"
            className="text-blue-300 hover:text-white"
          >
            anand@mseconsultant.com
          </a>
        </p>
        <p className="text-lg">
          <span className="font-bold">Call us:</span>{" "}
          <a href="tel:9510142218" className="text-blue-300 hover:text-white">
            9510142218
          </a>
        </p>

        {/* Head Office (Anand) */}
        <div className="bg-[#f1f1f1] text-primary-accent p-6 rounded-lg shadow-lg mt-8 mx-auto max-w-2xl">
          <h3 className="text-2xl font-bold">{branches[0].name}</h3>
          <p className="text-lg">{branches[0].address}</p>
          <p className="text-lg mt-2 font-semibold">Phone: 9510142218</p>
        </div>

        {/* Other Branches */}
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6 mt-8">
          {branches.slice(1).map((branch, index) => (
            <div
              key={index}
              className="bg-[#f1f1f1] text-primary-accent p-4 rounded-lg shadow-lg"
            >
              <h4 className="text-xl font-bold">{branch.name}</h4>
              <p className="text-sm">{branch.address}</p>
            </div>
          ))}
        </div>

        {/* Quick Links & Social Media */}
        <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mt-10 text-primary-accent">
          {/* Quick Links */}
          <div className="bg-[#f8f1e9] p-6 rounded-lg shadow-lg">
            <h4 className="text-lg font-bold mb-2">Quick Links</h4>
            <ul className="text-left space-y-1">
              <Link to="/" className="block hover:text-blue-600">
                Home
              </Link>
              <Link to="/visa" className="block hover:text-blue-600">
                Visa
              </Link>
              <Link to="/study-abroad" className="block hover:text-blue-600">
                Study Abroad
              </Link>
              <Link to="/about-us" className="block hover:text-blue-600">
                About Us
              </Link>
              <Link
                to="/english-proficiency"
                className="block hover:text-blue-600"
              >
                English Proficiency
              </Link>
            </ul>
          </div>

          {/* Legal Section */}
          <div className="bg-[#f8f1e9] p-6 rounded-lg shadow-lg">
            <h4 className="text-lg font-bold mb-2">Legal</h4>
            <ul className="text-left space-y-1">
              <Link to="/terms-conditions" className="block hover:text-blue-600">
                Terms & Conditions
              </Link>
              <Link to="/privacy-policy" className="block hover:text-blue-600">
                Privacy Policy
              </Link>
            </ul>
          </div>

          {/* Follow Us */}
          <div className="bg-[#f8f1e9] p-6 rounded-lg shadow-lg flex flex-col items-center">
            <h4 className="text-lg font-bold mb-2">Follow Us</h4>
            <div className="flex space-x-4 mt-2">
              <FaFacebook
                className="cursor-pointer hover:text-blue-600"
                size={24}
              />
              <FaTwitter
                className="cursor-pointer hover:text-blue-600"
                size={24}
              />
              <FaLinkedin
                className="cursor-pointer hover:text-blue-600"
                size={24}
              />
              <FaInstagram
                className="cursor-pointer hover:text-blue-600"
                size={24}
              />
            </div>
            <p className="mt-4 text-sm">
              Stay connected with us on social media.
            </p>
          </div>
        </div>

        {/* Copyright */}
        <div className="border-t border-cream-100 mt-8 pt-4">
          <p>©️ 2024 M.S. Education Consultant. All rights reserved.</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
