"use client";

import React from "react";
import { motion } from "framer-motion";

const HeroSection = ({ countryData }) => {
  const canadaImageUrl =
    "https://images.unsplash.com/photo-1530025809667-1f4bcff8e60f?q=80&w=2891&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D";
  if (!countryData) return;
  let imageUrl = countryData.imageURL;
  return (
    <section className="relative h-screen flex items-center justify-center overflow-hidden">
      <img
        src={imageUrl}
        alt="Beautiful Canadian landscape"
        className="absolute w-full h-full object-cover"
      />
      <div className="absolute inset-0 bg-black opacity-40"></div>
      <div className="relative z-10 text-center text-white max-w-4xl px-4">
        <motion.h1
          className="text-4xl md:text-6xl font-bold mb-6 leading-tight"
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
        >
          Discover the Beauty of {countryData.name}
        </motion.h1>
        <motion.p
          className="text-xl md:text-2xl mb-8 text-gray-200"
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8, delay: 0.2 }}
        >
          {countryData.tagline}
        </motion.p>
        <motion.button
          className="px-8 py-3 bg-red-600 text-white rounded-full text-lg font-semibold 
                     transition-colors duration-300 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-opacity-50"
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8, delay: 0.4 }}
        >
          Start Your Journey
        </motion.button>
      </div>
    </section>
  );
};

export default HeroSection;
