import { FaUniversity, FaPen, FaMoneyBill } from "react-icons/fa"; // Import icons

const ResourcesSection = () => {
  return (
    <section className="py-12 ">
      <div className="container mx-auto text-center">
        <h2 className="text-3xl font-bold text-gray-800 mb-8">Resources</h2>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
          <a
            href="https://drive.google.com/file/d/1bXzC08oo6fahGAcx7CxTn_5mp7j6HAn2/view"
            target="_blank"
            className="flex flex-col items-center bg-white p-6 rounded-lg shadow-md hover:shadow-lg hover:-translate-y-1 transform transition"
          >
            <FaUniversity className="text-4xl text-blue-500 mb-4" />
            <span className="text-lg font-medium text-gray-700">
              Guide to Choosing a University
            </span>
          </a>
          <a
            href="https://drive.google.com/file/d/1hgh6mngxFRh97pUTAKz9n3pgCOfzVnm6/view"
            target="_blank"
            className="flex flex-col items-center bg-white p-6 rounded-lg shadow-md hover:shadow-lg hover:-translate-y-1 transform transition"
          >
            <FaPen className="text-4xl text-green-500 mb-4" />
            <span className="text-lg font-medium text-gray-700">
              How to Write a Personal Statement
            </span>
          </a>
          <a
            href="https://drive.google.com/file/d/1rfVWG-0MBzk_9g6zVaUx9lkcRBFXbKme/view"
            target="_blank"
            className="flex flex-col items-center bg-white p-6 rounded-lg shadow-md hover:shadow-lg hover:-translate-y-1 transform transition"
          >
            <FaMoneyBill className="text-4xl text-yellow-500 mb-4" />
            <span className="text-lg font-medium text-gray-700">
              Scholarship Opportunities
            </span>
          </a>
        </div>
      </div>
    </section>
  );
};

export default ResourcesSection;
