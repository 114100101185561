import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { Lock, User, ShieldAlert } from "lucide-react";
import { constants } from "../../constants";
import { Link } from "react-router-dom";

const GoogleIcon = () => (
  <svg viewBox="0 0 24 24" className="w-5 h-5">
    <path
      fill="#4285F4"
      d="M22.56 12.25c0-.78-.07-1.53-.2-2.25H12v4.26h5.92c-.26 1.37-1.04 2.53-2.21 3.31v2.77h3.57c2.08-1.92 3.28-4.74 3.28-8.09z"
    />
    <path
      fill="#34A853"
      d="M12 23c2.97 0 5.46-.98 7.28-2.66l-3.57-2.77c-.98.66-2.23 1.06-3.71 1.06-2.86 0-5.29-1.93-6.16-4.53H2.18v2.84C3.99 20.53 7.7 23 12 23z"
    />
    <path
      fill="#FBBC05"
      d="M5.84 14.09c-.22-.66-.35-1.36-.35-2.09s.13-1.43.35-2.09V7.07H2.18C1.43 8.55 1 10.22 1 12s.43 3.45 1.18 4.93l2.85-2.22.81-.62z"
    />
    <path
      fill="#EA4335"
      d="M12 5.38c1.62 0 3.06.56 4.21 1.64l3.15-3.15C17.45 2.09 14.97 1 12 1 7.7 1 3.99 3.47 2.18 7.07l3.66 2.84c.87-2.6 3.3-4.53 6.16-4.53z"
    />
  </svg>
);

const ForgotPassword = () => {
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const { serverURL } = constants;

  const handleLogin = async (e) => {
    e.preventDefault();
    setError("");

    try {
      const response = await axios.post(
        `${serverURL}/api/auth/forgotPassword`,
        {
          email,
        }
      );

      if (response.data) {
        alert(response.data.message);
      }
    } catch (err) {
      setError(err.response?.data?.message || "Login failed");
    }
  };

  return (
    <div className="min-h-screen bg-primary-cream flex items-center justify-center px-4 sm:px-6 lg:px-8">
      <div className="max-w-md w-full bg-white shadow-2xl rounded-2xl overflow-hidden">
        <div className="bg-primary-brown text-white p-6 text-center">
          <h2 className="text-3xl font-bold flex items-center justify-center gap-3">
            {/* <ShieldAlert className="w-10 h-10" /> */}
            Forgot Password
          </h2>
        </div>
        <form className="p-8 space-y-6" onSubmit={handleLogin}>
          <div className="space-y-4">
            <div className="relative">
              <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                <User className="h-5 w-5 text-primary-blue" />
              </div>
              <input
                id="email"
                name="email"
                type="text"
                required
                className="pl-10 block w-full px-4 py-3 border border-primary-sky rounded-lg focus:ring-2 focus:ring-primary-accent focus:outline-none"
                placeholder="Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
          </div>

          {error && (
            <div className="bg-red-50 border border-red-300 text-red-800 px-4 py-3 rounded-lg text-center">
              <p className="flex items-center justify-center gap-2">
                <ShieldAlert className="h-5 w-5" />
                {error}
              </p>
            </div>
          )}

          <div>
            <button
              type="submit"
              className="w-full py-3 px-4 bg-primary-brown text-white rounded-lg hover:bg-primary font-semibold transition duration-300 ease-in-out transform hover:scale-[1.02] focus:outline-none focus:ring-2 focus:ring-primary-blue"
            >
              Submit
            </button>
          </div>

          {/* <div className="text-center text-sm text-primary-brown">
            <a href="#" className="hover:underline">
              Forgot Password?
            </a>
          </div> */}
          <div className="text-center text-sm text-primary-brown">
            <Link to={"/signup"}>Don't have an account? Sign up.</Link>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ForgotPassword;
