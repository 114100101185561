import React, { useState } from "react";
import {
  ChevronLeft,
  ChevronRight,
  Compass,
  FileCheck,
  Users,
} from "lucide-react";
import { motion, AnimatePresence } from "framer-motion";
import { HashLink } from "react-router-hash-link";

const JourneySteps = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const steps = [
    {
      icon: <Compass className="w-12 h-12 text-primary-blue" />,
      text: "Find scholarships",
      details:
        "Explore various scholarship opportunities to fund your studies.",
    },
    {
      icon: <FileCheck className="w-12 h-12 text-primary-brown" />,
      text: "Visa assistance",
      details: "Get expert help with visa applications and requirements.",
    },
    {
      icon: <Users className="w-12 h-12 text-primary" />,
      text: "Get Expert Guidance",
      details: "Receive personalized advice from our team of experts.",
    },
  ];

  const handlePrev = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? steps.length - 1 : prevIndex - 1
    );
  };

  const handleNext = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === steps.length - 1 ? 0 : prevIndex + 1
    );
  };

  return (
    <div className="w-full max-w-7xl mx-auto p-6 md:p-12 bg-blue-100/50 mt-10 rounded-2xl shadow-lg backdrop-blur-sm">
      <div className="flex flex-col lg:flex-row items-center gap-12">
        <div className="w-full lg:w-1/2 space-y-6">
          <h2 className="text-3xl md:text-4xl font-bold text-primary leading-tight">
            With you at every step of your study abroad journey
          </h2>
          <p className="text-lg md:text-xl text-primary-dark">
            Get personalised, friendly, honest guidance for free
          </p>
          <HashLink to="/#contact" smooth>
          <button className="group relative inline-flex items-center justify-center px-8 py-4 text-lg font-medium text-white bg-primary-brown rounded-xl overflow-hidden transition-all duration-300 ease-out hover:bg-primary-blue focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2">
            <span className="absolute inset-0 w-full h-full transition-all duration-300 ease-out transform translate-x-0 -skew-x-12 group-hover:translate-x-full"></span>
            <span className="relative">Book a free consultation</span>
            
          </button>
          </HashLink>
        </div>

        <div className="w-full lg:w-1/2">
          <div className="relative flex items-center justify-center">
            <button
              onClick={handlePrev}
              className="absolute left-0 z-10 p-2 text-primary-dark bg-white rounded-full shadow-lg hover:bg-primary-gray focus:outline-none focus:ring-2 focus:ring-primary"
              aria-label="Previous step"
            >
              <ChevronLeft className="w-6 h-6" />
            </button>

            <div className="w-full max-w-sm mx-12">
              <AnimatePresence mode="wait">
                <motion.div
                  key={currentIndex}
                  initial={{ opacity: 0, x: 100 }}
                  animate={{ opacity: 1, x: 0 }}
                  exit={{ opacity: 0, x: -100 }}
                  transition={{ duration: 0.3 }}
                  className="bg-white p-8 rounded-2xl shadow-xl"
                >
                  <div className="flex flex-col items-center space-y-6">
                    {steps[currentIndex].icon}
                    <h3 className="text-xl font-semibold text-primary">
                      {steps[currentIndex].text}
                    </h3>
                    <p className="text-primary-dark text-center">
                      {steps[currentIndex].details}
                    </p>
                  </div>
                </motion.div>
              </AnimatePresence>
            </div>

            <button
              onClick={handleNext}
              className="absolute right-0 z-10 p-2 text-primary-dark bg-white rounded-full shadow-lg hover:bg-primary-gray focus:outline-none focus:ring-2 focus:ring-primary"
              aria-label="Next step"
            >
              <ChevronRight className="w-6 h-6" />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default JourneySteps;