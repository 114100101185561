import React from "react";
import { Clock, Calendar, Sun } from "lucide-react";

const IntakesSection = ({ intakes, countryname }) => {
  if (!intakes) return;
  return (
    <div className="bg-primary-cream py-16">
      <div className="container mx-auto px-4">
        <h2 className="text-4xl font-bold text-primary-accent text-center mb-10">
          Intakes in{" "}
          {countryname.charAt(0).toUpperCase() + countryname.slice(1)}
        </h2>
        <div className="grid md:grid-cols-3 gap-8">
          {intakes.map((intake) => (
            <div
              key={intake.id}
              className="bg-white rounded-xl p-8 text-center shadow-lg hover:shadow-2xl transform hover:-translate-y-2 transition-all duration-300"
            >
              <div className="mx-auto w-20 h-20 mb-6 bg-blue-100 rounded-full flex items-center justify-center">
                <intake.icon className="w-10 h-10 text-blue-600" />
              </div>
              <h3 className="text-2xl font-semibold text-gray-800 mb-4">
                {intake.season}
              </h3>
              <p className="text-gray-600 mb-6">{intake.description}</p>
              <div className="bg-gray-100 rounded-lg p-4 text-left">
                <p className="text-sm text-gray-500">
                  <span className="font-bold text-gray-700">Start:</span>{" "}
                  {intake.details.startDate}
                </p>
                <p className="text-sm text-gray-500">
                  <span className="font-bold text-gray-700">Apply:</span>{" "}
                  {intake.details.applicationPeriod}
                </p>
                <p className="text-sm text-gray-500">
                  <span className="font-bold text-gray-700">Programs:</span>{" "}
                  {intake.details.availability}
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default IntakesSection;
