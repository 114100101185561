import React from "react";
import HeroSection from "../components/Home/HeroSection";
import JourneySteps from "../components/Home/JourneySteps";
import ContactForm from "../components/ContactForm";
import FeaturedPartner from "../components/Home/FeaturedPartner";
import Footer from "../components/footer";
import Updates from "../components/Home/Updates";
import VideoReels from "../components/Home/VideoReels";
import { ToastContainer } from "react-toastify";

export default function Home() {
  return (
    <div className="bg-primary-cream min-h-screen">
      <ToastContainer
        position="top-right"
        autoClose={7000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick={false}
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
      <HeroSection />
      <div className="space-y-16">
        <JourneySteps />
        <VideoReels />
        <Updates />
        <FeaturedPartner />
        <ContactForm />
      </div>
    </div>
  );
}
