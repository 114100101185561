import React, { useState } from "react";
import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom"; // Import for redirects
import BlogPosts from "../../components/BlogSection";
import ContactUsForm from "../ContactForm";

const EnglishProficiency = () => {
  const [showDemoPopup, setShowDemoPopup] = useState(false);
  const [selectedExam, setSelectedExam] = useState(null);
  const navigate = useNavigate(); // Initialize navigate for redirects

  // Exam information with updated redirect paths
  const examInfo = {
    IELTS: {
      fullName: "International English Language Testing System",
      description:
        "A standardized test that measures the English language proficiency of non-native English speakers. It's accepted by most academic institutions in the UK, Australia, Canada, and New Zealand.",
      keyFeatures: [
        "Four skills tested: Listening, Reading, Writing, and Speaking",
        "Available in Academic and General Training formats",
        "Results within 13 calendar days",
        "Valid for 2 years",
      ],
      averageScore:
        "Band score of 6.0-7.0 typically required for university admission",
      page: "../english-proficiency/ielts", // Updated redirect path
    },
    TOEFL: {
      fullName: "Test of English as a Foreign Language",
      description:
        "An American English proficiency test accepted by more than 11,000 universities in over 150 countries, particularly popular for US admissions.",
      keyFeatures: [
        "Four skills tested with integrated tasks",
        "Internet-based test (iBT) is most common format",
        "Results within 10 days",
        "Valid for 2 years",
      ],
      averageScore:
        "Score of 80-100 (out of 120) typically required for university admission",
      page: "../english-proficiency/tofel", // Updated redirect path (keeping TOFEL as in original)
    },
    PTE: {
      fullName: "Pearson Test of English Academic",
      description:
        "A computer-based English test that measures reading, writing, listening and speaking skills. Known for fast results and objective scoring.",
      keyFeatures: [
        "Fully computer-based test including speaking section",
        "Results typically available within 48 hours",
        "Accepted worldwide at thousands of institutions",
        "Valid for 2 years",
      ],
      averageScore:
        "Score of 58-65 (out of 90) typically required for university admission",
      page: "../english-proficiency/pte", // Updated redirect path
    },
    Duolingo: {
      fullName: "Duolingo English Test",
      description:
        "A modern, accessible English proficiency test that can be taken online in about 1 hour. Gaining acceptance at many institutions worldwide.",
      keyFeatures: [
        "Online test with adaptive format",
        "Results within 48 hours",
        "Costs significantly less than other tests",
        "Valid for 2 years",
      ],
      averageScore:
        "Score of 110-120 (out of 160) typically required for university admission",
      page: "../english-proficiency/duolingo", // Updated redirect path
    },
  };

  // Handler for exam selection and redirect
  const handleExamClick = (exam) => {
    if (selectedExam === exam) {
      // If already selected, navigate to the exam page
      navigate(examInfo[exam].page);
    } else {
      // Otherwise, just select it to show details
      setSelectedExam(exam);
    }
  };

  // Rest of the component remains unchanged
  // Animation variants
  const fadeInUp = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0, transition: { duration: 0.6 } },
  };

  const pulse = {
    initial: { scale: 1 },
    animate: {
      scale: [1, 1.05, 1],
      transition: { duration: 1.5, repeat: Infinity },
    },
  };

  const rotateIn = {
    hidden: { opacity: 0, rotateY: 90 },
    visible: { opacity: 1, rotateY: 0, transition: { duration: 0.8 } },
  };

  return (
    <div className="min-h-screen bg-primary-cream p-4 md:p-8">
      {/* Enhanced Header */}
      <header className="relative text-center mb-12 h-80 overflow-hidden rounded-xl shadow-lg">
        <div className="absolute inset-0 bg-gradient-to-r from-primary-DEFAULT to-primary-blue opacity-70 z-10"></div>
        <img
          src="https://static.vecteezy.com/system/resources/previews/036/597/046/non_2x/ai-generated-english-class-advertisment-background-with-copy-space-free-photo.jpg"
          alt="English Proficiency"
          className="w-full h-full object-cover"
        />
        <motion.div
          className="absolute inset-0 z-20 flex flex-col items-center justify-center text-white"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.8 }}
        >
          <motion.h1
            className="text-6xl font-bold drop-shadow-lg mb-4 text-white"
            initial={{ y: -50 }}
            animate={{ y: 0 }}
            transition={{ duration: 0.5, delay: 0.2 }}
          >
            English Proficiency
          </motion.h1>
          <motion.p
            className="text-2xl max-w-xl mx-auto leading-relaxed text-white"
            initial={{ y: 50 }}
            animate={{ y: 0 }}
            transition={{ duration: 0.5, delay: 0.4 }}
          >
            Unlock global opportunities with internationally recognized English
            certification
          </motion.p>
        </motion.div>
      </header>

      {/* Enhanced Interactive Exam Section with Redirects */}
      <motion.section
        className="mb-16"
        variants={fadeInUp}
        initial="hidden"
        animate="visible"
        viewport={{ once: true }}
      >
        <h2 className="text-3xl font-bold text-center mb-8 text-primary-DEFAULT">
          Select Your Exam
        </h2>

        <div className="container mx-auto px-4 py-6">
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4 md:gap-6">
            {Object.keys(examInfo).map((exam) => (
              <motion.div
                key={exam}
                className={`rounded-xl overflow-hidden shadow-lg h-full flex flex-col ${
                  selectedExam === exam
                    ? "ring-2 ring-primary-blue scale-102"
                    : "hover:shadow-xl"
                }`}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.3 }}
                onClick={() => handleExamClick(exam)}
              >
                <div
                  className={`p-4 md:p-6 h-full flex flex-col ${
                    selectedExam === exam
                      ? "bg-primary-blue text-white"
                      : "bg-white"
                  }`}
                >
                  <h3 className="font-bold text-xl md:text-2xl mb-2">{exam}</h3>
                  <p className="text-xs md:text-sm mb-3 opacity-90">
                    {examInfo[exam].fullName}
                  </p>

                  {selectedExam !== exam ? (
                    <div className="mt-auto">
                      <p className="text-center text-primary-blue font-medium text-sm md:text-base py-2">
                        Click to view details
                      </p>
                    </div>
                  ) : (
                    <motion.div
                      className="mt-2 flex flex-col flex-grow"
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      transition={{ duration: 0.3 }}
                    >
                      <p className="mb-3 text-xs md:text-sm">
                        {examInfo[exam].description}
                      </p>
                      <div className="mb-3">
                        <p className="font-medium mb-1 text-sm md:text-base">
                          Key Features:
                        </p>
                        <ul className="text-xs md:text-sm list-disc pl-4 space-y-1">
                          {examInfo[exam].keyFeatures.map((feature, idx) => (
                            <li key={idx}>{feature}</li>
                          ))}
                        </ul>
                      </div>
                      <p className="text-xs md:text-sm font-medium mb-4">
                        {examInfo[exam].averageScore}
                      </p>
                      {/* <div className="mt-auto">
                        <motion.button
                          className="w-full bg-white text-primary-blue py-2 rounded-lg font-medium text-sm md:text-base"
                          whileHover={{ scale: 1.03 }}
                          whileTap={{ scale: 0.97 }}
                          onClick={(e) => {
                            e.stopPropagation();
                            // navigate(examInfo[exam].page);
                          }}
                        >
                          Go to {exam} Page
                        </motion.button>
                      </div> */}
                    </motion.div>
                  )}
                </div>
              </motion.div>
            ))}
          </div>
        </div>
      </motion.section>

      {/* The rest of the component remains unchanged */}
      {/* Enhanced Book Online Demo Button */}
      <motion.section
        className="text-center mb-16"
        variants={rotateIn}
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true }}
      >
        <motion.button
          className="bg-primary-blue text-white px-10 py-4 rounded-full text-xl font-bold shadow-lg hover:bg-primary-DEFAULT transition-all duration-300"
          whileHover={{ scale: 1.05, boxShadow: "0 0 20px rgba(0,51,102,0.6)" }}
          whileTap={{ scale: 0.98 }}
          variants={pulse}
          animate="animate"
          onClick={() => setShowDemoPopup(true)}
        >
          Book Your Free Online Demo
        </motion.button>
      </motion.section>

      {/* Demo Popup */}
      {showDemoPopup && (
        <motion.div
          className="fixed inset-0 bg-black bg-opacity-70 z-50 flex items-center justify-center p-4"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.3 }}
          onClick={(e) => {
            if (e.target === e.currentTarget) setShowDemoPopup(false);
          }}
        >
          <motion.div
            className="bg-white rounded-xl p-8 max-w-lg w-full relative"
            initial={{ scale: 0.8, opacity: 0, rotateY: 90 }}
            animate={{ scale: 1, opacity: 1, rotateY: 0 }}
            transition={{ duration: 0.5 }}
            onClick={(e) => e.stopPropagation()}
          >
            <button
              className="absolute top-4 right-4 text-gray-500 hover:text-gray-800 text-2xl"
              onClick={() => setShowDemoPopup(false)}
            >
              &times;
            </button>
            <h2 className="text-3xl font-bold text-primary-DEFAULT mb-6 text-center">
              Coming Soon!
            </h2>
            <div className="flex justify-center mb-6">
              <motion.div
                className="w-24 h-24 border-4 border-primary-blue rounded-full border-t-transparent"
                animate={{ rotate: 360 }}
                transition={{ duration: 1.5, repeat: Infinity, ease: "linear" }}
              />
            </div>
            <p className="text-center text-lg mb-8">
              Our online demo booking system is under development. Please check
              back later or contact us directly to schedule your free demo
              session.
            </p>
            <div className="text-center">
              <button
                className="bg-primary-blue text-white px-8 py-3 rounded-lg hover:bg-primary-DEFAULT text-lg"
                onClick={() => setShowDemoPopup(false)}
              >
                Close
              </button>
            </div>
          </motion.div>
        </motion.div>
      )}

      {/* Enhanced Pricing Section with Hover Effects */}
      <motion.section
        className="mb-16"
        variants={fadeInUp}
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true }}
      >
        <h2 className="text-3xl font-bold text-center mb-8 text-primary-DEFAULT">
          Choose Your Plan
        </h2>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          {[
            {
              title: "Personalised Coaching",
              features: [
                "1 Student : 1 Teacher (Exclusive Focus)",
                "3 Months Duration",
                "Daily 2 Hours Lecture",
                "50 Mock Tests",
                "Soft Copy Study Material",
                "Complete Study Abroad Process Guidance",
                "Free Visa Process Assistance",
                "₹2,000 Voucher for Flight Ticket Booking",
              ],
              price: "₹20,000",
              color: "bg-primary-blue",
            },
            {
              title: "Common Class",
              features: [
                "Daily 1.5 Hours Lecture",
                "2 Problem-Solving Lectures Every Week",
                "20 Mock Tests",
                "3 Months Course Validity",
              ],
              price: "₹12,000",
              highlight: true,
              color: "bg-primary-blue",
            },
            {
              title: "Duolingo Test Preparation",
              features: [
                "Comprehensive Study Material",
                "Test Strategies and Tips",
                "Mock Test Practice",
                "Quick Preparation Plan",
              ],
              price: "₹5000",
              color: "bg-primary-blue",
            },
          ].map((plan, index) => (
            <motion.div
              key={plan.title}
              className="bg-white rounded-xl overflow-hidden shadow-lg"
              whileHover={{
                y: -15,
                boxShadow: "0 15px 30px -5px rgba(0, 0, 0, 0.15)",
                transition: { duration: 0.3 },
              }}
              variants={{
                hidden: { opacity: 0, y: 50 },
                visible: {
                  opacity: 1,
                  y: 0,
                  transition: { duration: 0.6, delay: index * 0.2 },
                },
              }}
            >
              <div className={`${plan.color} text-white p-6 text-center`}>
                <h3 className="font-bold text-2xl mb-2">{plan.title}</h3>
                <p className="text-4xl font-bold">{plan.price}</p>
                {/* <p className="opacity-80 mt-1">per month</p> */}
                {/* {plan.highlight && (
                  <div className="mt-2 bg-white text-primary-blue text-sm font-bold py-1 px-3 rounded-full inline-block">
                    Most Popular
                  </div>
                )} */}
              </div>
              <div className="p-6">
                <ul className="space-y-3 mb-8">
                  {plan.features.map((feature, i) => (
                    <li key={i} className="flex items-start">
                      <span className="text-primary-blue mr-2 font-bold">
                        ✓
                      </span>
                      {feature}
                    </li>
                  ))}
                </ul>
                <motion.button
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.98 }}
                  className={`w-full ${plan.color} text-white py-3 rounded-lg text-lg font-medium transition-colors duration-300`}
                >
                  Select Plan
                </motion.button>
              </div>
            </motion.div>
          ))}
        </div>
      </motion.section>

      {/* Enhanced Feedback/Review Section with 3D Card Flip */}
      <motion.section
        className="mb-16"
        variants={fadeInUp}
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true }}
      >
        <h2 className="text-3xl font-bold text-center mb-8 text-primary-DEFAULT">
          Student Feedback & Reviews
        </h2>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          {[
            {
              name: "Sarah J.",
              country: "Australia",
              exam: "IELTS",
              score: "Band 8.0",
              rating: 5,
              text: "The IELTS preparation course was incredibly helpful. I achieved my target score on the first try!",
            },
            {
              name: "Michael T.",
              country: "Canada",
              exam: "TOEFL",
              score: "110/120",
              rating: 4,
              text: "Great instructors and materials. The mock tests really prepared me for the real exam.",
            },
            {
              name: "Aisha K.",
              country: "UAE",
              exam: "PTE",
              score: "82/90",
              rating: 5,
              text: "The personalized feedback on my writing tasks made all the difference. Highly recommend!",
            },
          ].map((review, index) => (
            <motion.div
              key={review.name}
              className="bg-white p-6 rounded-xl shadow-lg h-64 perspective cursor-pointer"
              // whileHover={{ rotateY: 180 }}
              transition={{ duration: 0.6 }}
              variants={{
                hidden: { opacity: 0, rotateY: -90 },
                visible: {
                  opacity: 1,
                  rotateY: 0,
                  transition: { duration: 0.6, delay: index * 0.2 },
                },
              }}
            >
              <div className="relative w-full h-full transform-style-3d">
                <div className="absolute inset-0 backface-hidden">
                  <div className="flex flex-col h-full justify-between">
                    <div>
                      <div className="flex items-center mb-3">
                        {[...Array(5)].map((_, i) => (
                          <span
                            key={i}
                            className={
                              i < review.rating
                                ? "text-yellow-500"
                                : "text-gray-300"
                            }
                          >
                            ★
                          </span>
                        ))}
                      </div>
                      <p className="italic mb-4">"{review.text}"</p>
                    </div>
                    <p className="font-medium text-right text-primary-DEFAULT">
                      {review.name}
                    </p>
                  </div>
                  <div className="absolute bottom-0 right-0 bg-primary-blue text-white text-xs py-1 px-2 rounded-tl-md">
                    Hover to see details
                  </div>
                </div>
                <div className="absolute inset-0 backface-hidden rotateY-180 bg-primary-cream p-4 rounded-xl">
                  <div className="flex flex-col h-full justify-center items-center text-center">
                    <h3 className="font-bold text-xl mb-2">{review.name}</h3>
                    <p className="text-primary-DEFAULT mb-1">
                      From: {review.country}
                    </p>
                    <p className="mb-1">Exam: {review.exam}</p>
                    <p className="mb-3">Score Achieved: {review.score}</p>
                    <p className="text-sm italic">
                      "Their teaching methodology helped me overcome my weak
                      areas in just 6 weeks."
                    </p>
                  </div>
                </div>
              </div>
            </motion.div>
          ))}
        </div>
      </motion.section>

      {/* Blogs Section */}
      <motion.section
        className="mb-16"
        variants={fadeInUp}
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true }}
      >
        {/* <h2 className="text-3xl font-bold text-center mb-8 text-primary-DEFAULT">
          Latest Blog Posts
        </h2> */}
        <BlogPosts />
      </motion.section>

      {/* Enhanced FAQ Section with Accordion */}
      <motion.section
        className="mb-16"
        variants={fadeInUp}
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true }}
      >
        <h2 className="text-3xl font-bold text-center mb-8 text-primary-DEFAULT">
          Frequently Asked Questions
        </h2>
        <FAQAccordion
          faqs={[
            {
              question: "How long does it take to prepare for IELTS?",
              answer:
                "Preparation time varies based on your current English level and target score. Typically, 2-3 months of consistent study (10-15 hours per week) is recommended for optimal results. Some students might need more time, especially if they're targeting a high band score of 7.0 or above.",
            },
            {
              question: "What's the difference between IELTS and TOEFL?",
              answer:
                "IELTS uses British English and includes a face-to-face speaking component with an examiner, while TOEFL is American English-focused and has computer-based speaking tasks. IELTS is more widely accepted in the UK, Australia, and New Zealand, while TOEFL is traditionally preferred by US institutions. IELTS has two versions (Academic and General Training), whereas TOEFL iBT is primarily for academic purposes.",
            },
            {
              question:
                "Which test should I take: IELTS, TOEFL, PTE, or Duolingo?",
              answer:
                "It depends on your target institution's requirements and your own strengths. IELTS is better if you prefer human interaction for speaking tests. TOEFL is ideal if you're comfortable with computerized tests and American English. PTE offers fast results with fully computerized testing. Duolingo is the most affordable and convenient option, though not as widely accepted as the others. Always verify your institution's specific requirements before choosing a test.",
            },
            {
              question: "Do you offer money-back guarantees?",
              answer:
                "Yes, we offer a partial refund if you don't see improvement in your practice test scores after completing our program. You must have completed at least 80% of the course materials and taken all the recommended practice tests to be eligible. Specific terms vary by package, so please check the details of your chosen plan.",
            },
            {
              question:
                "How are your success rates compared to other providers?",
              answer:
                "Our students achieve their target scores at a rate 37% higher than the industry average. For IELTS, 89% of our students reach or exceed their target band score. For TOEFL, the success rate is 92%, and for PTE it's 85%. These results are due to our personalized approach, expert instructors, and comprehensive preparation materials.",
            },
          ]}
        />
      </motion.section>

      {/* Modified Contact Form Section - Removed container styling */}
      <motion.section
        className="mb-16"
        variants={fadeInUp}
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true }}
      >
        <h2 className="text-3xl font-bold text-center mb-8 text-primary-DEFAULT">
          Contact Us
        </h2>
        <ContactUsForm />
      </motion.section>
    </div>
  );
};

// FAQ Accordion Component
const FAQAccordion = ({ faqs }) => {
  const [openIndex, setOpenIndex] = useState(null);

  return (
    <div className="space-y-4 max-w-4xl mx-auto">
      {faqs.map((faq, index) => (
        <motion.div
          key={index}
          className="border border-gray-200 rounded-lg overflow-hidden"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: index * 0.1 }}
        >
          <motion.button
            className="w-full text-left p-5 flex justify-between items-center bg-primary-cream hover:bg-primary-sky transition-colors duration-300"
            onClick={() => setOpenIndex(openIndex === index ? null : index)}
            whileHover={{ scale: 1.01 }}
          >
            <span className="font-medium text-lg">{faq.question}</span>
            <motion.span
              animate={{ rotate: openIndex === index ? 180 : 0 }}
              transition={{ duration: 0.3 }}
            >
              ↓
            </motion.span>
          </motion.button>
          {openIndex === index && (
            <motion.div
              initial={{ height: 0, opacity: 0 }}
              animate={{ height: "auto", opacity: 1 }}
              exit={{ height: 0, opacity: 0 }}
              transition={{ duration: 0.3 }}
              className="p-5 bg-white"
            >
              <p className="text-gray-700">{faq.answer}</p>
            </motion.div>
          )}
        </motion.div>
      ))}
    </div>
  );
};

export default EnglishProficiency;
