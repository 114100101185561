import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { Lock, User, ShieldAlert } from "lucide-react";
import { constants } from "../../constants";
import { Link } from "react-router-dom";
import { auth, provider, signInWithPopup } from "../../config/firebaseconfig";
import { displayName } from "react-quill";
import { ToastContainer, toast } from "react-toastify";

const GoogleIcon = () => (
  <svg viewBox="0 0 24 24" className="w-5 h-5">
    <path
      fill="#4285F4"
      d="M22.56 12.25c0-.78-.07-1.53-.2-2.25H12v4.26h5.92c-.26 1.37-1.04 2.53-2.21 3.31v2.77h3.57c2.08-1.92 3.28-4.74 3.28-8.09z"
    />
    <path
      fill="#34A853"
      d="M12 23c2.97 0 5.46-.98 7.28-2.66l-3.57-2.77c-.98.66-2.23 1.06-3.71 1.06-2.86 0-5.29-1.93-6.16-4.53H2.18v2.84C3.99 20.53 7.7 23 12 23z"
    />
    <path
      fill="#FBBC05"
      d="M5.84 14.09c-.22-.66-.35-1.36-.35-2.09s.13-1.43.35-2.09V7.07H2.18C1.43 8.55 1 10.22 1 12s.43 3.45 1.18 4.93l2.85-2.22.81-.62z"
    />
    <path
      fill="#EA4335"
      d="M12 5.38c1.62 0 3.06.56 4.21 1.64l3.15-3.15C17.45 2.09 14.97 1 12 1 7.7 1 3.99 3.47 2.18 7.07l3.66 2.84c.87-2.6 3.3-4.53 6.16-4.53z"
    />
  </svg>
);

const SignUp = () => {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    confirmPassword: "",
  });
  const [error, setError] = useState("");
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [isConfirmPassword, setisConfirmPassword] = useState(false);
  const [signUpReqLoading, setSignUpReqLoding] = useState(false);

  const navigate = useNavigate();
  const { serverURL } = constants;

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSignUp = async (e) => {
    e.preventDefault();
    setError("");
    setSignUpReqLoding(true);

    // Validation
    if (formData.password !== formData.confirmPassword) {
      setError("Passwords do not match");
      return;
    }

    try {
      const response = await axios.post(`${serverURL}/api/auth/register`, {
        displayName: formData.firstName + " " + formData.lastName,
        email: formData.email,
        password: formData.password,
      });
      console.log(response);
      toast(response.data.message);
      setSignUpReqLoding(false);
      if (response.data.token) {
        alert(response.data.message);
        localStorage.setItem("adminToken", response.data.token);
        navigate("/admin");
      }
    } catch (err) {
      toast(err);
      setError(err.response?.data?.message || "Signup failed");
      setSignUpReqLoding(false);
    }
  };

  const handleGoogleLogin = () => {
    console.log("Attempting to sign in with Google...");
    signInWithPopup(auth, provider)
      .then((result) => {
        const user = result.user;
        // fetch("https://server.feathery.in/api/auth/google", {
        // fetch("http://localhost:3000/api/auth/google", {
        fetch(`${serverURL}/api/auth/google`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            uid: user.uid,
            displayName: user.displayName,
            email: user.email,
            photoURL: user.photoURL,
          }),
        })
          .then((response) => {
            if (!response.ok) {
              throw new Error("Failed to authenticate user");
            }
            return response.json();
          })
          .then((data) => {
            localStorage.setItem("token", data.token);

            // onClose();
            window.location.reload();
          })
          .catch((error) => {
            console.error("Error during fetch:", error);
          });
      })
      .catch((error) => {
        console.error("Error during sign-in:", error);
      });
  };

  return (
    <div className="min-h-screen bg-primary-cream flex items-center justify-center px-4 sm:px-6 lg:px-8">
      <ToastContainer
        position="top-right"
        autoClose={7000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick={false}
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
      <div className="max-w-md w-full bg-white shadow-2xl rounded-2xl overflow-hidden">
        <div className="bg-primary-brown text-white p-6 text-center">
          <h2 className="text-3xl font-bold flex items-center justify-center gap-3">
            Sign Up
          </h2>
        </div>
        <form className="p-8 space-y-6" onSubmit={handleSignUp}>
          <div className="space-y-4">
            <div className="flex gap-2">
              <input
                id="firstName"
                name="firstName"
                type="text"
                required
                className="block w-full px-4 py-3 border border-primary-sky rounded-lg focus:ring-2 focus:ring-primary-accent focus:outline-none"
                placeholder="First Name"
                value={formData.firstName}
                onChange={handleChange}
              />
              <input
                id="lastName"
                name="lastName"
                type="text"
                required
                className="block w-full px-4 py-3 border border-primary-sky rounded-lg focus:ring-2 focus:ring-primary-accent focus:outline-none"
                placeholder="Last Name"
                value={formData.lastName}
                onChange={handleChange}
              />
            </div>
            <div className="relative">
              <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                <User className="h-5 w-5 text-primary-blue" />
              </div>
              <input
                id="email"
                name="email"
                type="email"
                required
                className="pl-10 block w-full px-4 py-3 border border-primary-sky rounded-lg focus:ring-2 focus:ring-primary-accent focus:outline-none"
                placeholder="Email"
                value={formData.email}
                onChange={handleChange}
              />
            </div>
            <div className="relative">
              <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                <Lock className="h-5 w-5 text-primary-blue" />
              </div>
              <input
                id="password"
                name="password"
                type={isPasswordVisible ? "text" : "password"}
                required
                className="pl-10 block w-full px-4 py-3 border border-primary-sky rounded-lg focus:ring-2 focus:ring-primary-accent focus:outline-none"
                placeholder="Password"
                value={formData.password}
                onChange={handleChange}
              />
              <button
                type="button"
                onClick={() => setIsPasswordVisible(!isPasswordVisible)}
                className="absolute inset-y-0 right-0 pr-3 flex items-center text-primary-blue"
              >
                {isPasswordVisible ? "Hide" : "Show"}
              </button>
            </div>
            <div className="relative">
              <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                <Lock className="h-5 w-5 text-primary-blue" />
              </div>
              <input
                id="confirmPassword"
                name="confirmPassword"
                type={isConfirmPassword ? "text" : "password"}
                required
                className="pl-10 block w-full px-4 py-3 border border-primary-sky rounded-lg focus:ring-2 focus:ring-primary-accent focus:outline-none"
                placeholder="Confirm Password"
                value={formData.confirmPassword}
                onChange={handleChange}
              />
              <button
                type="button"
                onClick={() => setisConfirmPassword(!isConfirmPassword)}
                className="absolute inset-y-0 right-0 pr-3 flex items-center text-primary-blue"
              >
                {isConfirmPassword ? "Hide" : "Show"}
              </button>
            </div>
          </div>

          {error && (
            <div className="bg-red-50 border border-red-300 text-red-800 px-4 py-3 rounded-lg text-center">
              <p className="flex items-center justify-center gap-2">
                <ShieldAlert className="h-5 w-5" />
                {error}
              </p>
            </div>
          )}

          <div>
            <button
              type="submit"
              className="w-full flex justify-center py-3 px-4 bg-primary-brown text-white rounded-lg hover:bg-primary font-semibold transition duration-300 ease-in-out transform hover:scale-[1.02] focus:outline-none focus:ring-2 focus:ring-primary-blue"
            >
              {signUpReqLoading && (
                <div role="status">
                  <svg
                    aria-hidden="true"
                    class="w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-white"
                    viewBox="0 0 100 101"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                      fill="currentColor"
                    />
                    <path
                      d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                      fill="currentFill"
                    />
                  </svg>
                  <span class="sr-only">Loading...</span>
                </div>
              )}
              {!signUpReqLoading && "Sign up"}
            </button>
          </div>
          <button
            onClick={handleGoogleLogin}
            type="button"
            className="flex items-center justify-center w-full p-2 sm:p-3 rounded-lg sm:rounded-xl border border-light-DEFAULT dark:border-dark-DEFAULT bg-light-DEFAULT dark:bg-dark-tertiary hover:bg-light-secondary dark:hover:bg-dark-DEFAULT dark:border-dark transition-colors group"
          >
            <span className="mr-2 sm:mr-3">
              <GoogleIcon />
            </span>
            <span className="text-sm sm:text-base text-light-primary dark:text-dark-primary font-medium">
              Continue with Google
            </span>
          </button>
          <div className="text-center text-sm text-primary-brown">
            <Link to={"/forgot-password"}>Forgot Password?</Link>
          </div>
          <div className="text-center text-sm text-primary-brown">
            <Link to={"/login"}>Already have an account? Login.</Link>
          </div>
        </form>
      </div>
    </div>
  );
};

export default SignUp;
