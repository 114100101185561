"use client";

import React from "react";
import { motion } from "framer-motion";

const Stats = () => {
  const statsData = [
    {
      value: "10,000+",
      label: "Students Placed Abroad",
    },
    {
      value: "500+",
      label: "Partner Universities",
    },
    {
      value: "50+",
      label: "Countries Represented",
    },
    {
      value: "95%",
      label: "Student Satisfaction Rate",
    },
  ];
  // if (!statsData || statsData.length === 0) return null;

  return (
    <section className="py-20 bg-primary-cream">
      <div className="container mx-auto px-4">
        <h2 className="text-4xl font-extrabold text-center text-gray-900 mb-12">
          Our Impact in Numbers
        </h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
          {statsData.map((stat, index) => (
            <motion.div
              key={index}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: index * 0.1 }}
              className="bg-white rounded-xl shadow-lg overflow-hidden transform hover:scale-105 transition-transform duration-300"
            >
              <div className="p-8">
                <motion.div
                  className="text-4xl font-bold text-indigo-600 text-center mb-2"
                  initial={{ scale: 1 }}
                  animate={{ scale: [1, 1.1, 1] }}
                  transition={{ duration: 0.5, delay: index * 0.1 + 0.3 }}
                >
                  {stat.value}
                </motion.div>
                <div className="text-gray-600 text-center text-lg">
                  {stat.label}
                </div>
              </div>
            </motion.div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Stats;
