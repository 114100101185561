import React, { useState } from "react";
import Modal from "./Modal";
import Dimpal from "../assets/Counsellors/Dimple.jpeg"
import Shivangi from "../assets/Counsellors/Shivangi-bhatt.jpeg"
import Nishita from "../assets/Counsellors/Nishita-patel.jpeg"

const CounsellingPage = () => {
  const [selectedCounsellor, setSelectedCounsellor] = useState(null);

  const counsellors = [
    {
      name: "Nishita Patel",
      image: Nishita,
      bio: "Nishita Patel is an experienced counselor with over 10 years of experience in career guidance and educational consulting.",
      expertise: "Career Counseling, Educational Consulting, Visa Guidance",
      location: "Anand, Gujarat",
      mail: "@nishita_pat",
      experience: "7 Years",
      cases: "350",
      success_ratio: "15k+",
    },
    {
      name: "Shivangi Bhatt",
      image: Shivangi,
      bio: "Shivangi Bhatt specializes in helping students find the right educational programs and preparing for university applications.",
      expertise:
        "Application Assistance, Interview Preparation, Financial Advice",
      location: "Navrangpura, Ahmedabad",
      mail: "@shivangi_bhatt",
      experience: "3 Years",
      cases: "150",
      success_ratio: "12k+",
    },
    {
      name: "Dimpal Trivedi",
      image: Dimpal,
      bio: "Dimpal provides expert guidance in visa applications and financial planning for studying abroad.",
      expertise: "Visa Counseling, Financial Planning, Scholarship Guidance",
      location: "Vadodara, Gujarat",
      mail: "@dimple_counsels",
      experience: "8 Years",
      cases: "400",
      success_ratio: "20k+",
    },
  ];

  const handleCounsellorClick = (counsellor) => {
    setSelectedCounsellor(counsellor);
  };

  const closeModal = () => {
    setSelectedCounsellor(null);
  };

  return (
    <div>
      <section className="py-16 px-4">
        <div className="max-w-6xl mx-auto">
          <h2 className="text-3xl font-bold mb-8 text-center">
            Meet Our Senior Counsellors
          </h2>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
            {counsellors.map((counsellor) => (
              <div
                key={counsellor.name}
                className="bg-white rounded-lg shadow-md overflow-hidden cursor-pointer transition-transform duration-300 hover:scale-105"
                onClick={() => handleCounsellorClick(counsellor)}
              >
                <div
                  className="h-48 bg-contain bg-no-repeat bg-center"
                  style={{ backgroundImage: `url(${counsellor.image})` }}
                />
                <div className="p-4">
                  <h3 className="text-xl font-semibold mb-2">
                    {counsellor.name}
                  </h3>
                  <p className="text-gray-600">{counsellor.expertise}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>

      {selectedCounsellor && (
        <Modal onClose={closeModal}>
          <div className="bg-white rounded-lg overflow-hidden max-w-2xl w-full">
            <div className="p-6">
              <div
                className="h-64 bg-cover bg-center rounded-lg mb-4"
                style={{ backgroundImage: `url(${selectedCounsellor.image})` }}
              ></div>
              <h2 className="text-2xl font-bold mb-2">
                {selectedCounsellor.name}
              </h2>
              <p className="text-blue-600 mb-1">{selectedCounsellor.mail}</p>
              <p className="text-gray-600 mb-2">
                {selectedCounsellor.location}
              </p>
              {/* <p className="text-gray-700 mb-4">{selectedCounsellor.bio}</p> */}
              <p className="text-gray-700 mb-4 font-semibold">
                {selectedCounsellor.expertise}
              </p>
              <div className="grid grid-cols-3 gap-4 mb-6">
                <div className="text-center">
                  <strong className="block text-2xl">
                    {selectedCounsellor.experience}
                  </strong>
                  <span className="text-gray-600">experience</span>
                </div>
                <div className="text-center">
                  <strong className="block text-2xl">
                    {selectedCounsellor.cases}
                  </strong>
                  <span className="text-gray-600">cases</span>
                </div>
                <div className="text-center">
                  <strong className="block text-2xl">
                    {selectedCounsellor.success_ratio}
                  </strong>
                  <span className="text-gray-600">success ratio</span>
                </div>
              </div>
              <button
                className="w-full bg-blue-600 text-white py-2 px-4 rounded-lg hover:bg-blue-700 transition-colors duration-300"
                onClick={() =>
                  alert("Booking a session with " + selectedCounsellor.name)
                }
              >
                Book a Counselling Session
              </button>
            </div>
          </div>
        </Modal>
      )}
    </div>
  );
};

export default CounsellingPage;
